import { Tooltip } from "@mui/material";

export default function tooltipWrapper(component, hasTooltip, tooltipText) {
  return hasTooltip ? (
    <Tooltip title={tooltipText}>
      <div>{component}</div>
    </Tooltip>
  ) : (
    component
  );
}
