import {
  ADD_CUSTOMER_SUBSCRIPTION,
  ADD_PACKAGE,
  ADD_PACKAGE_DETAILS,
  ADD_PAYMENT_METHOD,
} from "./../types";

const initialState = {
  paymentMethods: null,
  packages: null,
  subscriptions: null,
  packageDetails: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PAYMENT_METHOD:
      return { ...state, paymentMethods: action.payload };
    case ADD_PACKAGE:
      return { ...state, packages: action.payload };
    case ADD_CUSTOMER_SUBSCRIPTION:
      return { ...state, subscriptions: action.payload };
    case ADD_PACKAGE_DETAILS:
      return { ...state, packageDetails: action.payload };
    default:
      return state;
  }
};
