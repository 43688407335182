import { ADD_PARTNER_FINCHES } from "../types";

const initialState = {
  partnerFinches: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PARTNER_FINCHES:
      console.log(action.payload, "req Data for Finches");
      return { ...state, partnerFinches: action.payload };
    default:
      return state;
  }
};
