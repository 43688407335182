import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SimpleInput from "../Inputs/SimpleInput";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";
import MyDropzone from "../ImageUploader/MyDropzone";
import DatePickerInput from "../Inputs/DatePickerInput";
import SimpleSelect from "../Inputs/SimpleSelect";
import { AiFillExclamationCircle } from "react-icons/ai";

import DateTimePickerComp from "../Inputs/DateTimePicker";
import { useDispatch, useSelector } from "react-redux";

import DayPicker from "../Inputs/DayPicker";
import moment from "moment";
import useFinch from "../../ApiHooks/useFinch";
import { format } from "date-fns";
import createDateWithTime from "../../Utils/createDateWithTime";
import * as dayjs from "dayjs";

const CreateFinch = ({ type, onClose, open, id }) => {
  const {
    errors,
    handleGoldFinchCreation,
    loading,
    handleFinchCreation,
    handleDraftCreation,
    handleDraftStatus,
    createStatus,
  } = useFinch();
  const partnerFranchises = useSelector(
    (state) => state.partnerFranchisesReducer.partnerFranchises
  );

  const finchData = useSelector((state) =>
    state.partnerFinchesReducer.partnerFinches?.find((finch) => finch.id === id)
  );
  const categoriesData = useSelector(
    (state) => state.categoryReducer.categories
  );
  const partner = useSelector((state) => state.userReducer.partner);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [name, setName] = useState("");
  const [goldFinchQuan, setGoldFinchQuan] = useState("");
  const [disPercent, setDisPercent] = useState("");
  const [description, setDescription] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [categories, setCategories] = useState([]);
  const [franchises, setFranchises] = useState([]);
  const [activationTime, setActivationTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [daysToSkip, setDaysToSkip] = useState([]);
  const [endTimeDay, setEndTimeDay] = useState(
    createDateWithTime(finchData?.endTime)
  );
  const [startTimeDay, setStartTimeDay] = useState(
    createDateWithTime(finchData?.startTime)
  );

  const handleDateTime = (newDate, onChange) => {
    const inputDate = new Date(newDate);

    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based, so add 1 and format to two digits
    const day = inputDate.getDate().toString().padStart(2, "0");
    const hours = inputDate.getHours().toString().padStart(2, "0");
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");

    const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}`;

    console.log(formattedDate);
    onChange(inputDate);
  };

  useEffect(() => {
    setSelectedFiles([]);
    setName("");
    setGoldFinchQuan("");
    setDisPercent("");
    setDescription("");
    setNotificationMessage("");
    setCategories([]);
    setFranchises([]);
    setActivationTime("");
    setEndTime("");
    setDaysToSkip([]);
    setEndTimeDay(null);
    setStartTimeDay(null);
  }, [open]);

  useEffect(() => {
    if (finchData) {
      setName(finchData?.title);
      setGoldFinchQuan(finchData?.quantity);
      setDisPercent(finchData?.discountPercentage);
      setCategories(finchData?.categoriesID);
      setFranchises(finchData?.franchises?.map((v) => v.franchiseId));
      setNotificationMessage(finchData?.notificationMessage);

      setDescription(finchData?.drenchDescription);
      setSelectedFiles(finchData?.images);

      if (type !== "Duplicate") {
        setEndTime(moment(new Date(finchData?.drenchTimeEnd?._seconds * 1000)));
        setActivationTime(
          moment(new Date(finchData?.drenchTimeStart?._seconds * 1000))
        );
      }
      setEndTimeDay(createDateWithTime(finchData?.endTime));
      setStartTimeDay(createDateWithTime(finchData?.startTime));
      setDaysToSkip(finchData?.skipDays || []);
    }
  }, [id]);

  const handleSubmit = () => {
    if (finchData?.currentStatus === "Draft") {
      changeStatusDraft();
      return;
    }
    if (type === "GoldFinch" || finchData?.type === "drenches")
      createGoldFinch();
    if (type === "Finch" || finchData?.type === "drizzles") createFinch();
  };
  const createFinch = () => {
    let reqBody = {
      finchId: id,
      title: name,
      quantity: parseInt(goldFinchQuan) || "UNLIMITED",
      discountPercentage: disPercent,
      drenchTimeStart: activationTime ? new Date(activationTime) : null,
      drenchTimeEnd: endTime ? new Date(endTime) : null,
      drenchDescription: description,
      categoriesID: categories,
      franchises: franchises?.map((id) => {
        const fran = partnerFranchises.find((v) => v.franchiseId === id);
        return {
          franchiseId: fran?.franchiseId,
          geofenceId: fran?.geofenceId,
        };
      }),
      images: selectedFiles,
      lat: partner?.latitude && partner?.latitude,
      lng: partner?.longitude && partner?.longitude,
      dateCreated: new Date().toUTCString(),
      createdDate: +new Date(),
      type: `${type === "Finch" ? "drizzles" : "drenches"}`,
      skipDays: daysToSkip,
      startTime: startTimeDay ? format(new Date(startTimeDay), "HH:MM") : null,
      endTime: endTimeDay ? format(new Date(endTimeDay), "HH:MM") : null,
    };
    if (id) {
      reqBody.active = finchData.active;
      reqBody.currentStatus = finchData.currentStatus;
    }
    handleFinchCreation(reqBody, onClose);
  };
  const createGoldFinch = () => {
    let reqBody = {
      finchId: id,
      title: name,
      quantity: parseInt(goldFinchQuan) || "UNLIMITED",
      discountPercentage: disPercent,
      drenchTimeStart: activationTime ? new Date(activationTime) : null,
      drenchTimeEnd: endTime ? new Date(endTime) : null,
      drenchDescription: description,
      categoriesID: categories,
      franchises: franchises?.map((id) => {
        const fran = partnerFranchises.find((v) => v.franchiseId === id);
        return {
          franchiseId: fran?.franchiseId,
          geofenceId: fran?.geofenceId,
        };
      }),
      images: selectedFiles,
      lat: partner?.latitude && partner?.latitude,
      lng: partner?.longitude && partner?.longitude,
      dateCreated: new Date().toUTCString(),
      createdDate: +new Date(),
      type: `${type === "Finch" ? "drizzles" : "drenches"}`,
      startTime: startTimeDay ? format(new Date(startTimeDay), "HH:MM") : null,
      endTime: endTimeDay ? format(new Date(endTimeDay), "HH:MM") : null,
      notificationMessage,
      skipDays: daysToSkip,
    };
    if (type === "Duplicate") {
      delete reqBody.finchId;
    } else {
      if (id) {
        reqBody.notificationId = finchData.notificationId;
        reqBody.active = finchData.active;
        reqBody.campaignId = finchData.campaignId;
        reqBody.currentStatus = finchData.currentStatus;
      }
    }

    handleGoldFinchCreation(reqBody, onClose);
    console.log(reqBody, "reqBody");
  };

  const getStartEndDateDiff = (startDate, endDate) => {
    console.log(startDate, endDate, "endDate");
    if (!(endDate && startDate)) return "";
    let momentEndDate = moment(new Date(endDate));
    let momentStartDate = moment(new Date(startDate));

    const diff = moment.duration(
      momentEndDate.diff(momentStartDate, "seconds"),
      "seconds"
    );

    const years = Math.floor(diff.asYears());
    diff.subtract(moment.duration(years, "years"));

    const months = Math.floor(diff.asMonths());
    diff.subtract(moment.duration(months, "months"));

    const weeks = Math.floor(diff.asWeeks());
    diff.subtract(moment.duration(weeks, "weeks"));

    const days = Math.floor(diff.asDays());
    diff.subtract(moment.duration(days, "days"));

    const hours = Math.floor(diff.asHours());
    diff.subtract(moment.duration(hours, "hours"));

    const minutes = Math.floor(diff.asMinutes());

    const components = [];

    if (years) components.push(`${years} years`);
    if (months) components.push(`${months} months`);
    if (weeks) components.push(`${weeks} weeks`);
    if (days) components.push(`${days} days`);
    if (hours) components.push(`${hours} hours`);
    if (minutes) components.push(`${minutes} minutes`);

    return components.join(", ");
  };

  const createDraft = () => {
    let reqBody = {
      finchId: id,
      title: name,
      quantity: parseInt(goldFinchQuan) || "UNLIMITED",
      discountPercentage: disPercent,
      drenchTimeStart: activationTime ? new Date(activationTime) : null,
      drenchTimeEnd: endTime ? new Date(endTime) : null,
      drenchDescription: description,
      categoriesID: categories,
      franchises: franchises?.map((id) => {
        const fran = partnerFranchises.find((v) => v.franchiseId === id);
        return {
          franchiseId: fran?.franchiseId,
          geofenceId: fran?.geofenceId,
        };
      }),
      images: selectedFiles,
      lat: partner?.latitude && partner?.latitude,
      lng: partner?.longitude && partner?.longitude,
      dateCreated: new Date().toUTCString(),
      createdDate: +new Date(),
      type: `${type === "Finch" ? "drizzles" : "drenches"}`,
      startTime: startTimeDay ? format(new Date(startTimeDay), "HH:MM") : null,
      endTime: endTimeDay ? format(new Date(endTimeDay), "HH:MM") : null,
      notificationMessage,
      skipDays: daysToSkip,
    };

    if (id) {
      reqBody.notificationId = finchData.notificationId;
      reqBody.active = finchData.active;
      reqBody.campaignId = finchData.campaignId;
      reqBody.currentStatus = finchData.currentStatus;
    }
    handleDraftCreation(reqBody, onClose);
    console.log(reqBody, "reqBody");
  };

  const changeStatusDraft = () => {
    let reqBody = {
      finchId: id,
      title: name,
      quantity: parseInt(goldFinchQuan) || "UNLIMITED",
      discountPercentage: disPercent,
      drenchTimeStart: activationTime ? new Date(activationTime) : null,
      drenchTimeEnd: endTime ? new Date(endTime) : null,
      drenchDescription: description,
      categoriesID: categories,
      franchises: franchises?.map((id) => {
        const fran = partnerFranchises.find((v) => v.franchiseId === id);
        return {
          franchiseId: fran?.franchiseId,
          geofenceId: fran?.geofenceId,
        };
      }),
      images: selectedFiles,
      lat: partner?.latitude && partner?.latitude,
      lng: partner?.longitude && partner?.longitude,
      dateCreated: new Date().toUTCString(),
      createdDate: +new Date(),
      type: finchData.type,
      startTime: startTimeDay ? format(new Date(startTimeDay), "HH:MM") : null,
      endTime: endTimeDay ? format(new Date(endTimeDay), "HH:MM") : null,
      notificationMessage,
      skipDays: daysToSkip,
    };

    handleDraftStatus(reqBody, onClose);
    console.log(reqBody, "reqBody");
  };

  const currentStatus = createStatus(finchData);
  console.log("TYPE:", currentStatus, finchData);

  return (
    <Box sx={{ p: 3, pl: 5, pr: 5, pt: 2 }}>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Campaign Title"
            tooltipTitle="Write a catchy title to grab your customers' attention or simply use your product or service name here. This will be the title of your listing."
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={"For Ex: Buy one Get one Free"}
            error={errors.title}
            helperText={errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Limit Offer Availability"
            tooltipTitle='Control the exclusivity of your offer by setting a maximum number of claims. For instance, if you wish to make your promotion available to only the first 10 customers, simply specify this number here, and the offer will automatically deactivate after reaching this limit. Alternatively, selecting the "Unlimited" option allows your offer to remain active until the specified end date and time, with no cap on the number of claims. This feature ensures you can tailor the reach and impact of your promotions to match your strategic goals.'
            fullWidth
            placeholder={"Unlimited"}
            value={goldFinchQuan}
            onChange={(e) => setGoldFinchQuan(e.target.value)}
            error={errors.quantity}
            helperText={errors.quantity}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Discount Percentage (%)"
            fullWidth
            tooltipTitle="Enter the percentage of the discount you wish to offer. For example, if you want to offer a 10% discount, simply type '10' in this field."
            // required
            type="number"
            placeholder={"eg: 10"}
            value={disPercent}
            onChange={(e) => setDisPercent(e.target.value)}
            error={errors.discountPercentage}
            helperText={errors.discountPercentage}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
          >
            Activation Date & Time
            <Tooltip title="Specify the exact date and time you want your offer to launch. Keep in mind, once you schedule your Finch card, you won't be able to edit the Start Date and Time.">
              <IconButton>
                <AiFillExclamationCircle size={15} color="#0088FF" />
              </IconButton>
            </Tooltip>
          </InputLabel>
          <DateTimePickerComp
            label="Select date and time"
            value={activationTime}
            onChange={(e) => handleDateTime(e, setActivationTime)}
            error={errors.drenchTimeStart}
            helperText={errors.drenchTimeStart}
            disabled={currentStatus !== "Awaiting" && type !== "Duplicate"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={12} md={6}>
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
          >
            End Date & Time
            <Tooltip title="Choose the campaign's end date and time to determine when it should stop. The campaign will automatically deactivate at the end date and time you set. Please note, the end date must be within 1 week from the start date.">
              <IconButton>
                <AiFillExclamationCircle size={15} color="#0088FF" />
              </IconButton>
            </Tooltip>
          </InputLabel>
          <DateTimePickerComp
            label="Select date and time"
            value={endTime}
            onChange={(e) => handleDateTime(e, setEndTime)}
            error={errors.drenchTimeEnd}
            helperText={errors.drenchTimeEnd}
            disabled={currentStatus !== "Awaiting" && type !== "Duplicate"}
          />
          {/* <DatePickerInput
            tooltipTitle="Select the date and time when you want to exactly stop this campaign. According to the end date & time, your campaign will be deactivated automatically"
            label="End Date & Time"
            value="Select date and time"
          /> */}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Campaign Duration"
            tooltipTitle="The duration of your campaign depends on your chosen start and end dates & times. It will run within this timeframe, helping you effectively reach your audience."
            fullWidth
            disabled
            value={getStartEndDateDiff(activationTime, endTime)}
            error={errors.campDuration}
            helperText={errors.campDuration}
          />
        </Grid>
      </Grid>
      {
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={12} md={6}>
            <DatePickerInput
              label="Opening time"
              value={startTimeDay}
              onChange={setStartTimeDay}
              defaultValue={startTimeDay}
              tooltipTitle={`Set the campaign's active hours by selecting the opening and closing times. For example, if you set an opening time of "7:00 AM" and a closing time of "11:00 PM," the campaign will be visible to users only during these hours. Additionally, users will receive notifications exclusively within this timeframe.`}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <DatePickerInput
              label="Closing time"
              value={endTimeDay}
              defaultValue={endTimeDay}
              onChange={setEndTimeDay}
              tooltipTitle={`Set the campaign's active hours by selecting the opening and closing times. For example, if you set an opening time of "7:00 AM" and a closing time of "11:00 PM," the campaign will be visible to users only during these hours. Additionally, users will receive notifications exclusively within this timeframe.`}
            />
          </Grid>
        </Grid>
      }
      <Grid container>
        <Grid item xs={12}>
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
          >
            {"Days to Skip"}{" "}
            <Tooltip
              title={`"Days to Skip" allows you to choose specific days when your campaign won't be active. For example, if your shop is closed on Sundays, you can simply select Sunday as a skip day. This way, your campaign will only run on the days you want, ensuring you reach your customers when it matters most.`}
            >
              <IconButton>
                <AiFillExclamationCircle size={15} color="#0088FF" />
              </IconButton>
            </Tooltip>
          </InputLabel>
        </Grid>
        <Grid item xs={12}>
          <DayPicker value={daysToSkip} onChange={setDaysToSkip} />
        </Grid>
      </Grid>
      {(type === "GoldFinch" || finchData?.type === "drenches") && (
        <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
          <Grid item xs={12}>
            <SimpleInput
              label="Notification Message"
              fullWidth
              value={notificationMessage}
              onChange={(e) => setNotificationMessage(e.target.value)}
              // required
              placeholder={"Enter Notification Message"}
              // defaultValue=
              error={errors.notificationMessage}
              helperText={errors.notificationMessage}
              tooltipTitle={
                "Make your notification message interesting and clear to get people to take action. This helps you talk directly to users, making them more likely to respond."
              }
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={12} md={12}>
          <SimpleInput
            label="Description"
            tooltipTitle="Enter a brief description to provide additional details about your campaign."
            fullWidth
            multiline
            minRows={4}
            placeholder={"Short Description for your offer"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={errors.drenchDescription}
            helperText={errors.drenchDescription}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={12} md={6}>
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
          >
            Select Categories
            <Tooltip title="Select relevant categories that best represent your campaign or promotion. This helps users find your deals more easily and ensures your offers reach the right audience. You can choose multiple categories as well.">
              <IconButton>
                <AiFillExclamationCircle size={15} color="#0088FF" />
              </IconButton>
            </Tooltip>
          </InputLabel>
          <SimpleSelect
            size="large"
            placeholder={"Select Categories"}
            fullWidth
            value={categories}
            multiple
            onChange={setCategories}
            options={categoriesData?.map((v) => {
              return {
                label: v.title,
                value: v.id,
              };
            })}
            style={{
              // px: 10,
              backgroundColor: Theme.bg.bg,
              border: "1px solid #E7EAEE",
            }}
            error={errors.categoriesID}
            helperText={errors.categoriesID}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {/* <SimpleInput
            error
            id="outlined-select-categories"
            select
            label="Select Franchises"
            // defaultValue="Select..."
            helperText="franchises field must have at least 1 items"
          ></SimpleInput> */}
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
          >
            Select Franchises
            <Tooltip title="Choose the franchises where you want to run this campaign or promotion. By selecting specific franchises, you can tailor your offers to a more specific audience, enhancing the effectiveness of your marketing efforts.">
              <IconButton>
                <AiFillExclamationCircle size={15} color="#0088FF" />
              </IconButton>
            </Tooltip>
          </InputLabel>
          <SimpleSelect
            size="large"
            // options={options}
            //   fullWidth
            placeholder={"Select Franchises"}
            fullWidth
            value={franchises}
            multiple
            onChange={setFranchises}
            options={partnerFranchises?.map((v) => {
              return {
                label: v.name,
                value: v.franchiseId,
              };
            })}
            style={{
              // px: 10,
              backgroundColor: Theme.bg.bg,
              border: "1px solid #E7EAEE",
            }}
            error={errors.franchises}
            helperText={errors.franchises}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <MyDropzone
            tooltipTitle={
              "Upload an image to showcase your campaign or promotion. The minimum is 1, and the maximum is 3"
            }
            label="Upload Image"
            value={selectedFiles}
            onChange={setSelectedFiles}
            error={errors.images}
            helperText={errors.images}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        {(!id || finchData?.currentStatus === "Draft") && (
          <Grid item xs={6}>
            <PrimaryButton
              text={"SAVE AS DRAFT"}
              elevation={3}
              size="large"
              fullWidth
              loading={loading}
              onClick={createDraft}
            />
          </Grid>
        )}
        <Grid
          item
          xs={
            !(id || finchData?.currentStatus !== "Draft") ||
            type === "Duplicate"
              ? 12
              : id && (type === "GoldFinch" || type === "Finch")
              ? 12
              : 6
          }
        >
          <PrimaryButton
            text={"SCHEDULE"}
            elevation={3}
            size="large"
            fullWidth
            onClick={handleSubmit}
            loading={loading}
            sx={{
              fontWeight: "bold",
              color: Theme.color.basic,
              backgroundColor: Theme.bg.yellow,
              boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              "&.MuiButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.yellow,
                  borderColor: Theme.bg.yellow,
                  boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateFinch;
