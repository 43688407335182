import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";
import "../../App.css";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import usePayment from "../../ApiHooks/usePayment";
import BuyPackages from "../../Screens/BuyPackages";

const CampaignPackForm = ({ onClose, data }) => {
  const valueName = "paymentMethodId";
  const stripe = useStripe();
  const elements = useElements();
  const quantity = data?.quantity;
  const packageData = data?.data?.details;
  const totalPrice = quantity * packageData?.price;
  const { addNewCard, paymentMethod, setPaymentMethod, buyPackage, loading } =
    usePayment(packageData, onClose);
  const paymentMethods = useSelector(
    (state) => state.paymentReducer.paymentMethods
  );

  const user = useSelector((state) => state.userReducer.user);
  const [value, setValue] = useState("");
  const [cards, setCards] = useState({
    defaultCard: null,
    cards: [],
  });

  useEffect(() => {
    setPaymentMethod(value);
  }, [value]);

  useEffect(() => {
    if (paymentMethods?.length) {
      let cards = paymentMethods.filter(
        (card) => card.id !== user?.defaultPaymentMethod
      );
      if (user?.defaultPaymentMethod) {
        let defaultCard = paymentMethods.find(
          (card) => card.id === user.defaultPaymentMethod
        );
        setCards({ defaultCard, cards });
        setValue(defaultCard?.id);
        return;
      }
      setCards({ defaultCard: null, cards: [...paymentMethods] });
    }
  }, [paymentMethods]);

  const handlePackages = async (event) => {
    try {
      event.preventDefault();
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      console.log(error, paymentMethod, "error, paymentMethod");
      if (error) return;
      addNewCard(paymentMethod);
    } catch (err) {
      console.log(err, "ERROR - handlePackages");
    }
  };
  console.log(":SIDE_DATA", data);

  return (
    <Box sx={{ p: 3, pl: 5, pr: 5, pt: 2 }}>
      <Box>
        <Typography sx={{ my: 2, fontFamily: "Urbanist-Bold" }}>
          Enter Your Card Details
        </Typography>
        <Box
          sx={{
            border: "1px solid #FFD831",
            boxShadow: "-3px 0px 35px #00000033",
            borderRadius: "10px",
            px: 2,
            py: 2,
            mb: 2,
            "& > :not(:last-child)": {
              borderBottom: `1px solid rgba(0, 0, 0, 0.15)`,
            },
          }}
        >
          <InputLabel sx={{ fontSize: "14px", mb: 1 }}>
            Enter the 16-digit card number on the card
          </InputLabel>
          <form onSubmit={handlePackages} id="card-form">
            <CardElement id="card-element" />
            <input
              type="submit"
              id="card-form-submit"
              style={{ display: "none" }}
            />
          </form>
        </Box>
        <Box
          sx={{
            border: "1px solid #FFD831",
            boxShadow: "-3px 0px 35px #00000033",
            borderRadius: "10px",
            px: 2,
            py: 2,
            "& > :not(:last-child)": {
              borderBottom: `1px solid rgba(0, 0, 0, 0.15)`,
            },
          }}
        >
          <Select
            style={{ width: "100%", color: "black" }}
            variant="outlined"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            sx={{ backgroundColor: Theme.bg.bg }}
          >
            {cards.defaultCard && (
              <MenuItem value={cards.defaultCard.id}>
                **** {cards.defaultCard?.cardLast4}
              </MenuItem>
            )}
            {cards.cards?.map((item) => (
              <MenuItem value={item.id}>**** {item?.cardLast4}</MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box sx={{ mt: 10, mb: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <PrimaryButton
              text={"CANCEL"}
              fullWidth
              //   onClick={handleSignUp}
              sx={{
                color: Theme.color.white,
                backgroundColor: "#636363",
                borderRadius: "8px",
                fontSize: "16px",
                "&.MuiButton-root": {
                  "&:hover": {
                    backgroundColor: "#636363",
                    borderColor: "#636363",
                    // boxShadow: "10px 20px 30px #636363",
                  },
                },
                fontFamily: "Urbanist-Bold",
                // boxShadow: "10px 20px 30px #636363",
              }}
              onClick={onClose}
            />
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton
              text={"BUY PACKAGES"}
              onClick={buyPackage}
              fullWidth
              sx={{
                color: Theme.color.basic,
                backgroundColor: Theme.bg.yellow,
                borderRadius: "8px",
                fontSize: "16px",
                "&.MuiButton-root": {
                  "&:hover": {
                    backgroundColor: Theme.bg.yellow,
                    borderColor: Theme.bg.yellow,
                    boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                  },
                },
                fontFamily: "Urbanist-Bold",
                boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              }}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CampaignPackForm;
