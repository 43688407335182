import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuList,
  Typography,
} from "@mui/material";
import SimpleSelect from "../Inputs/SimpleSelect";
import { Theme } from "../../Theme";
import "../../App.css";

export default function TableMenu({
  handleClose,
  handleOptionClick = () => {},
  anchorEl,
  selectedId,
  options,
}) {
  return (
    <Box sx={{  }}>
      <MenuList
        // autoFocusItem={open}
        id="composition-menu"
        aria-labelledby="composition-button"
        // onKeyDown={handleListKeyDown}
        sx={{px:2}}
      >
        {options.map((v, i) => {
          return <MenuItem onClick={()=>{handleOptionClick("",v,selectedId)}} >{v}</MenuItem>;
        })}
      </MenuList>
    </Box>
  );
}
