import React, { useState } from "react";
import DashLogo from "../../Assets/SVG/Dashlogo.svg";
import CreateCompain from "../../Assets/SVG/CreateCampaign.svg";
import SavedGoldFinches from "../../Assets/SVG/SavedGoldFinches.svg";
import AddFranchise from "../../Assets/SVG/AddFranchise.svg";
import Analytics from "../../Assets/SVG/Analytics.svg";
import BuyPackages from "../../Assets/SVG/BuyPackages.svg";
import Logout from "../../Assets/SVG/Logout.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { RiDeleteBinLine } from "react-icons/ri";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Box,
  Stack,
  IconButton as IconBtn,
  Typography,
  Grid,
  Button,
  IconButton,
  Checkbox,
  Paper,
  useMediaQuery,
} from "@mui/material";
import IconFeather_IMG from "../../Assets/SVG/Iconfeather.svg";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StickyHeadTable from "../../Components/Table/index.jsx";
import { styled } from "@mui/material/styles";
import { VscEdit } from "react-icons/vsc";
import { BiAddToQueue } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { RiArrowDropUpFill } from "react-icons/ri";
import { RiArrowDropDownFill } from "react-icons/ri";
import { Theme } from "../../Theme";
import GradientBox from "../../Components/GradientBox";
import SideBar from "../../Components/Sidebar";
import CreateFinch from "../../Components/Forms/CreateFinch";
import CreateGoldFinch from "../../Components/Forms/CreateGoldFinch";
import { BsSearch } from "react-icons/bs";
import BasicModal from "../../Components/Modal";
import DeleteComp from "../../Components/Modal/DeleteComp";
import DateChangeComp from "../../Components/Modal/DateChangeComp";
import CancelSubsComp from "../../Components/Modal/CancelSubsComp";
import "../../App.css";
import ViewFeedback from "../../Components/Modal/ViewFeedback";
import ExpandableSearchBar from "../../Components/Inputs/ExpandableSearchBar";

const UsersFeedback = () => {
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignSidebar, setCampaignSidebar] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [CreateFinchForm, setCreteFinchForm] = useState(false);
  const [CreateGoldFinchForm, setCreteGoldFinchForm] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // const [count, setCount] = useState(user?.count || 0);
  // const [nextPage, setNextPage] = useState(userData?.next);
  // const [previousPage, setPreviousPage] = useState(userData?.previous);

  const titleLiner = (title) => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
        {title}
      </Typography>
      {/* <Stack sx={{ m: 0, p: 0 }}>
        <RiArrowDropUpFill style={{ marginBottom: -5 }} />
        <RiArrowDropDownFill style={{ marginTop: -5 }} />
      </Stack> */}
    </Box>
  );

  const textLiner = (color, fontSize, text, bgColor = null) => {
    return (
      <Typography
        sx={{
          color: color,
          fontSize: fontSize,
          backgroundColor: bgColor,
          borderRadius: "4px",
          //   px: 1,
          fontFamily: "Urbanist-Semibold",
        }}
      >
        {text}
      </Typography>
    );
  };

  const columns = [
    {
      id: "id",
      label: titleLiner("ID"),
      minWidth: 10,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "date",
      label: titleLiner("Date"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "name",
      label: titleLiner("Name"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "email",
      label: titleLiner("Email"),
      minWidth: 10,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "partnerName",
      label: titleLiner("Partner Name"),
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "gender",
      label: titleLiner("Gender"),
      minWidth: 10,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "actions",
      label: titleLiner("Actions"),
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
  ];

  const handleClick = (event, id) => {
    setSelectedId(id);
    if (event === "view") {
      handleModal(id);
    }
  };

  const handleModal = (id) => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //   const handleCheckboxChange = (index) => {
  //     // Create a copy of the userData array
  //     const updatedUserData = [...userData];

  //     // Toggle the checked property of the specific object
  //     updatedUserData[index].checked = !updatedUserData[index].checked;

  //     // Update the state with the new userData
  //     setUserData(updatedUserData);
  //   };

  let franchises = ["Calgary", "Line Chest", "Acura", "My Home"];

  let userData = [
    {
      id: textLiner(Theme.color.text, "18px", "0C0jatCKaVRdUrt6LrmBRiepkdD2"),
      date: textLiner(Theme.color.text, "18px", "Apr-20-2023"),
      name: textLiner(Theme.color.text, "18px", "Alex Barker"),
      email: textLiner(Theme.color.text, "18px", "google@gmail.com"),
      partnerName: textLiner(Theme.color.text, "18px", "Karachi Foods"),
      gender: textLiner(Theme.color.text, "18px", "Male"),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("view")}
            sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <AiOutlineEye color={Theme.color.white} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
        </Stack>
      ),
    },
    {
      id: textLiner(Theme.color.text, "18px", "0C0jatCKaVRdUrt6LrmBRiepkdD2"),
      date: textLiner(Theme.color.text, "18px", "Apr-20-2023"),
      name: textLiner(Theme.color.text, "18px", "Alex Barker"),
      email: textLiner(Theme.color.text, "18px", "google@gmail.com"),
      partnerName: textLiner(Theme.color.text, "18px", "Karachi Foods"),
      gender: textLiner(Theme.color.text, "18px", "Male"),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <AiOutlineEye color={Theme.color.white} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
        </Stack>
      ),
    },
    {
      id: textLiner(Theme.color.text, "18px", "0C0jatCKaVRdUrt6LrmBRiepkdD2"),
      date: textLiner(Theme.color.text, "18px", "Apr-20-2023"),
      name: textLiner(Theme.color.text, "18px", "Alex Barker"),
      email: textLiner(Theme.color.text, "18px", "google@gmail.com"),
      partnerName: textLiner(Theme.color.text, "18px", "Karachi Foods"),
      gender: textLiner(Theme.color.text, "18px", "Male"),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <AiOutlineEye color={Theme.color.white} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
        </Stack>
      ),
    },
    {
      id: textLiner(Theme.color.text, "18px", "0C0jatCKaVRdUrt6LrmBRiepkdD2"),
      date: textLiner(Theme.color.text, "18px", "Apr-20-2023"),
      name: textLiner(Theme.color.text, "18px", "Alex Barker"),
      email: textLiner(Theme.color.text, "18px", "google@gmail.com"),
      partnerName: textLiner(Theme.color.text, "18px", "Karachi Foods"),
      gender: textLiner(Theme.color.text, "18px", "Male"),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <AiOutlineEye color={Theme.color.white} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
        </Stack>
      ),
    },
  ];

  //static pagination
  let paginatedData = {
    count: 20,
    next: 2,
    previous: null,
    results: userData,
  };
  const [users, setUsers] = useState(paginatedData.results);
  const [currentPage, setCurrentPage] = useState(1);

  const getUsers = (page) => {
    if (page === 1) {
      setUsers(paginatedData.results);
      setCurrentPage(1);
    } else if (page === 2) {
      setUsers(paginatedData.results);
      setCurrentPage(2);
    } else {
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      {!isSmallScreen ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <Typography
              sx={{
                fontFamily: "Urbanist-Bold",
                fontSize: { xs: "24px", sm: "24px", md: "32px" },
              }}
            >
              App Users
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            {/* <Button
              variant="outlined"
              style={{
                color: "#88949E",
                borderColor: "#E7EAEE",
                borderRadius: 5,
                padding: 10,
              }}
            >
              <BsSearch style={{ fontSize: 20 }} />
            </Button> */}
            <ExpandableSearchBar />
          </Box>
        </Box>
      ) : (
        <Paper sx={{ px: 1, py: 2, my: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
              <Typography
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "32px",
                  // fontSize: { xs: "24px", sm: "24px", md: "34px" },
                }}
              >
                App Users
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
              {/* <Button
                variant="outlined"
                style={{
                  color: "#88949E",
                  borderColor: "#E7EAEE",
                  borderRadius: 5,
                  padding: 10,
                }}
              >
                <BsSearch style={{ fontSize: 20 }} />
              </Button> */}
            </Box>
          </Box>
        </Paper>
      )}
      <Box sx={{ my: 2 }}>
        <StickyHeadTable
          columns={columns}
          rows={paginatedData.results}
          count={paginatedData?.count || 0}
          next={
            paginatedData?.next
              ? (callback) => getUsers(users?.next, callback)
              : null
          }
          previous={
            paginatedData?.previous
              ? (callback) => getUsers(users?.previous, callback)
              : null
          }
          loading={loading}
        />
      </Box>
      <SideBar
        open={CreateFinchForm}
        onClose={() => setCreteFinchForm(false)}
        title={selectedId ? "Edit Client" : "CreateFinch"}
        form={
          <CreateFinch
            id={selectedId}
            // onEdit={getAllClients}
            onClose={() => setCreteFinchForm(false)}
          />
        }
      />
      <SideBar
        open={CreateGoldFinchForm}
        onClose={() => setCreteGoldFinchForm(false)}
        title={selectedId ? "Edit Client" : "CreateGoldFinch"}
        form={
          <CreateGoldFinch
            id={selectedId}
            // onEdit={getAllClients}
            onClose={() => setCreteGoldFinchForm(false)}
          />
        }
      />
      {/* Delete Modal */}
      <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<ViewFeedback />}
      />
      {/* Delete Modal */}
      {/* <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<DeleteComp />}
      /> */}

      {/* Date Change Modal */}
      {/* <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<DateChangeComp />}
      /> */}

      {/* Cancel Subcription Modal */}
      {/* <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<CancelSubsComp />}
      /> */}
    </Box>
  );
};

export default UsersFeedback;
