import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { Theme } from "../../Theme";
import "../../App.css";
import { FiCheckCircle } from "react-icons/fi";
import Tick_IMG from "../../Assets/Image/Tick.png";
import Discount_IMG from "../../Assets/Image/Discount.png";
import PrimaryButton from "../Buttons/PrimaryButton";
import { capitalize } from "lodash";
const FranchiseCard = ({ data, onClose }) => {
  const [quantity, setQuantity] = useState(1);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Grid
      item
      sx={{
        borderRadius: isSmallScreen ? "8px" : "30px",
        backgroundColor: Theme.color.basic,
        py: 3,
        px: { xs: 6, sm: 6, md: 4, lg: 4, xl: 6 },
      }}
      xs={12}
      sm={12}
      md={3}
    >
      {data.discount ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mr: { xs: -10, sm: -8, md: -8, lg: -8, xl: -12 },
            mb: -9,
          }}
        >
          {/* <img src={Discount_IMG} alt="" style={{}} /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="73.784"
            height="73.247"
            viewBox="0 0 73.784 73.247"
          >
            <g
              id="Group_35603"
              data-name="Group 35603"
              transform="translate(-1223 -309)"
            >
              <g
                id="Ellipse_654"
                data-name="Ellipse 654"
                transform="translate(1223 316)"
                fill="#fff"
                stroke="#707070"
                stroke-width="1"
              >
                <circle cx="8.5" cy="8.5" r="8.5" stroke="none" />
                <circle cx="8.5" cy="8.5" r="8" fill="none" />
              </g>
              <g
                id="Group_35600"
                data-name="Group 35600"
                transform="translate(1230 309)"
              >
                <path
                  id="Path_42372"
                  data-name="Path 42372"
                  d="M31.1,32.64,22.928,18.264a2.6,2.6,0,0,1,0-2.572L31.1,1.317A2.6,2.6,0,0,1,33.359,0H74.974a2.6,2.6,0,0,1,2.6,2.6V31.354a2.6,2.6,0,0,1-2.6,2.6H33.359A2.6,2.6,0,0,1,31.1,32.639Zm6.062-15.661a3.8,3.8,0,1,0-3.8,3.8,3.8,3.8,0,0,0,3.8-3.8Z"
                  transform="translate(-10.793)"
                  fill="#ffd831"
                />
                <path
                  id="Path_42373"
                  data-name="Path 42373"
                  d="M357.58,106.942a1.96,1.96,0,0,0-1.96-1.96H338.2a1.96,1.96,0,0,0,0,3.919h17.419A1.96,1.96,0,0,0,357.58,106.942Z"
                  transform="translate(-335.668 -89.963)"
                  fill="#2c3940"
                />
                <path
                  id="Path_42374"
                  data-name="Path 42374"
                  d="M70.47,210.92V212.1a2.6,2.6,0,0,1-2.6,2.6H26.252a2.6,2.6,0,0,1-2.263-1.317l-1.4-2.465H70.47Z"
                  transform="translate(-3.686 -180.746)"
                  fill="#e9c52a"
                />
                <path
                  id="Path_42375"
                  data-name="Path 42375"
                  d="M81.854,150.052l4.39-15.94a2.6,2.6,0,0,1,1.818-1.818L104,127.9a2.6,2.6,0,0,1,2.531.669L135.96,158a2.6,2.6,0,0,1,0,3.681l-20.33,20.33a2.6,2.6,0,0,1-3.681,0L82.523,152.584A2.6,2.6,0,0,1,81.854,150.052Zm15.361-6.787a3.8,3.8,0,1,0-5.373,0A3.8,3.8,0,0,0,97.215,143.265Z"
                  transform="translate(-78.404 -109.526)"
                  fill="#ef5350"
                />
                <path
                  id="Path_42376"
                  data-name="Path 42376"
                  d="M391.579,120.01a1.96,1.96,0,0,0,0-2.771l-12.317-12.317a1.96,1.96,0,1,0-2.771,2.771l12.317,12.317A1.96,1.96,0,0,0,391.579,120.01Z"
                  transform="translate(-375.916 -89.42)"
                  fill="#36474f"
                />
                <path
                  id="Path_42377"
                  data-name="Path 42377"
                  d="M222.582,265.727l.807-2.931,33.929,33.929-.96.96a2.6,2.6,0,0,1-3.681,0l-29.426-29.426A2.6,2.6,0,0,1,222.582,265.727Z"
                  transform="translate(-219.132 -225.2)"
                  fill="#d44a47"
                />
              </g>
              <text
                id="_10_"
                data-name="10%"
                transform="translate(1277 331)"
                fill="#292929"
                font-size="16"
                font-family="Urbanist-Bold, Urbanist"
                font-weight="700"
              >
                <tspan x="-15" y="0">
                  {data.discount}%
                </tspan>
              </text>
              <text
                id="_10_2"
                data-name="10%"
                transform="matrix(0.719, 0.695, -0.695, 0.719, 1257.832, 360.316)"
                fill="#fff"
                font-size="16"
                font-family="Urbanist-Bold, Urbanist"
                font-weight="700"
              >
                <tspan x="-15" y="0">
                  {data.discount}%
                </tspan>
              </text>
            </g>
          </svg>
        </Box>
      ) : null}
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "22px",
          color: Theme.color.white,
          fontFamily: "Urbanist-Semibold",
          //   mt:!data.discount? 5:null
        }}
      >
        {capitalize(data?.isTest ? "Test Per Day" : data?.type)}
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "44px",
          fontFamily: "Urbanist-Bold",
          color: Theme.color.yellow,
          my: 2,
        }}
      >
        ${data.price}
      </Typography>
      <Divider sx={{ bgcolor: "#3B3B3B", width: "100px", margin: "0 auto" }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, my: 3 }}>
        {[
          {
            icon: "",
            name: "Live Portal Demo",
          },
          {
            icon: "",
            name: "Account Setup",
          },
          {
            icon: "",
            name: "Account Setup",
          },
        ].map((v, i) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 4,
                borderLeft: "3px solid #FFD831",
                borderRight: "3px solid #FFD831",
                borderRadius: "3px",
                px: 2,
              }}
              key={i}
            >
              {/* <FiCheckCircle color="#fff" /> */}
              <img src={Tick_IMG} alt="" />
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#fff",
                  fontFamily: "Urbanist-Medium",
                }}
              >
                {v.name}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box>
        <Typography sx={{ textAlign: "center", color: Theme.bg.white }}>
          Franchise:{" "}
          <select
            value={quantity}
            onChange={(e) => setQuantity(parseInt(e.target.value))}
            style={{
              backgroundColor: Theme.bg.darkgrey,
              color: Theme.bg.white,
              padding: 5,
              borderRadius: 10,
              minWidth: 70,
            }}
          >
            {new Array(20).fill(0).map((_, i) => (
              <option key={`${i}-select`} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
        <PrimaryButton
          text={"Buy Now"}
          onClick={() => onClose({ data, quantity })}
          sx={{
            color: Theme.color.basic,
            backgroundColor: Theme.bg.yellow,
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: Theme.bg.yellow,
                borderColor: Theme.bg.yellow,
              },
            },
            width: "70%",
            py: 1.5,
            fontFamily: "Urbanist-Bold",
            borderRadius: "10px",
            fontSize: "16px",
            // px:
          }}
        />
      </Box>
    </Grid>
  );
};

export default FranchiseCard;
