import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { Theme } from "../../Theme";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import PackagesComp from "../../Components/BuyPackagesComp/PackagesComp";
import BillingComp from "../../Components/BuyPackagesComp/BillingComp";
import SideBar from "../../Components/Sidebar";
import FranchisePackForm from "../../Components/Forms/FranchisePackForm";
import usePayment from "../../ApiHooks/usePayment";
import CampaignPackForm from "../../Components/Forms/CampaignPackForm";
import NewCardForm from "../../Components/Forms/NewCardForm";
import { useSelector } from "react-redux";

function CustomTabPanel(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: isSmallScreen ? 1 : 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BuyPackages = () => {
  const { loading } = usePayment();
  const [value, setValue] = React.useState(0);
  const packages = useSelector((state) => state.paymentReducer.packages);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [franchiseSidebar, setFranchiseSidebar] = useState(false);
  const [campaignSidebar, setCampaignSidebar] = useState(false);
  const [newCardSidebar, setNewCardSidebar] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  return (
    <Box sx={{ mt: 4 }}>
      {loading && packages?.length === 0 ? (
        <Grid
          container
          alignContent={"center"}
          justifyContent={"center"}
          sx={{ height: "60%" }}
        >
          <Grid item alignSelf={"center"}>
            <CircularProgress size={20} />
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              ml: { xs: 0, sm: 0, md: 4 },
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              indicatorColor="secondary"
              textColor="primary"
              sx={{
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              <Tab
                label="Packages"
                {...a11yProps(0)}
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: 17,
                  minWidth: {
                    xs: "150px",
                    sm: "200px",
                    md: "300px",
                    "&.Mui-selected": {
                      backgroundColor: "#fff",
                    },
                  },
                }}
              />
              <Tab
                indicatorColor="secondary"
                label="Billing"
                {...a11yProps(1)}
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: 17,

                  minWidth: { xs: "150px", sm: "200px", md: "300px" },
                  "&.Mui-selected": {
                    backgroundColor: "#fff",
                  },
                }}
              />
            </Tabs>
          </Box>
          <Box sx={{ mt: 4 }}>
            <CustomTabPanel value={value} index={0}>
              <PackagesComp
                onFranchise={(e) => setFranchiseSidebar(e)}
                onCampaign={(e) => setCampaignSidebar(e)}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <BillingComp onNewCard={() => setNewCardSidebar(true)} />
            </CustomTabPanel>
          </Box>
        </>
      )}

      {Boolean(franchiseSidebar) && (
        <SideBar
          open={Boolean(franchiseSidebar)}
          onClose={() => setFranchiseSidebar(null)}
          title={selectedId ? "Edit Client" : "Franchise Package"}
          form={
            <FranchisePackForm
              id={selectedId}
              data={franchiseSidebar}
              onClose={() => setFranchiseSidebar(null)}
            />
          }
        />
      )}
      {Boolean(campaignSidebar) && (
        <SideBar
          open={Boolean(campaignSidebar)}
          onClose={() => setCampaignSidebar(null)}
          title={selectedId ? "Edit Client" : "Campaign Package"}
          form={
            <CampaignPackForm
              id={selectedId}
              data={campaignSidebar}
              onClose={() => setCampaignSidebar(null)}
            />
          }
        />
      )}
      {Boolean(newCardSidebar) && (
        <SideBar
          open={Boolean(newCardSidebar)}
          onClose={() => setNewCardSidebar(null)}
          title={"Add New Card"}
          form={
            <NewCardForm
              id={selectedId}
              data={newCardSidebar}
              onClose={() => setNewCardSidebar(null)}
            />
          }
        />
      )}
    </Box>
  );
};

export default BuyPackages;
