import { Label } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { AiFillExclamationCircle } from "react-icons/ai";
import { Theme } from "../../Theme";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const SimpleInput = ({
  label,
  required,
  simple,
  icon,
  tooltipTitle,
  ...props
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* <Typography sx={{ my: 1 }}>
        {label}{" "}
        {required ? <span style={{ color: Theme.color.red }}>*</span> : null}
      </Typography> */}
      {label ? (
        <InputLabel
          htmlFor="outlined-adornment-password"
          sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
        >
          {label}
          {required ? (
            <span style={{ color: Theme.color.red }}>*</span>
          ) : simple ? null : (
            <Tooltip title={tooltipTitle}>
              <IconButton>
                <AiFillExclamationCircle size={15} color="#0088FF" />
              </IconButton>
            </Tooltip>
          )}
        </InputLabel>
      ) : null}
      <TextField
        variant="outlined"
        sx={{
          bgcolor: "#F8FAFB",
          borderRadius: "6px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "6px",
            borderColor: "#DDE6EE",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderRadius: "6px",
            borderColor: "#DDE6EE",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderRadius: "6px",
            borderColor: "#DDE6EE",
          },
        }}
        {...props}
      />
    </Box>
  );
};

export default SimpleInput;
