import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SimpleInput from "../Inputs/SimpleInput";
import IconInput from "../Inputs/IconInput";
import GoogleMap from "../GoogleMap/GoogleMap";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ImageUploader from "../ImageUploader";
import LocationInput from "../Inputs/LocationInput";
import { useSelector } from "react-redux";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { storage } from "../../Constants/Firebase";
import getMimeType from "../../Utils/getMimeType";
import useProfile from "../../ApiHooks/useProfie";
import isValidLink from "../../Utils/isValidLink";

const MyProfile = () => {
  const { editProfile, loading } = useProfile();
  const partner = useSelector((state) => state.userReducer.user);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [businessContact, setBusinessContact] = useState("");

  const [fullAddress, setFullAddress] = useState();
  const [address, setAddress] = useState();
  const [matchRange, setMatchRange] = useState(3000);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState();

  const [errors, setErrors] = useState({});

  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    // Handle the selected file after the user has chosen it.
    const file = event?.target?.files?.[0];
    setSelectedFile(file || null);
  };

  const getAddress = (address, lat, lng) => {
    if (lat && lng) {
      setLatLng(lat, lng);
    }
    if (address.length > 50) {
      setAddress(address.substr(0, 50) + "...");
      setFullAddress(address);
    } else {
      setAddress(address);
      setFullAddress(address);
    }
  };

  function setLatLng(lat, lng) {
    setLatitude("");
    setLongitude("");
    setLatitude(lat);
    setLongitude(lng);
  }

  useEffect(() => {
    // console.log(partner, "partner");
    if (partner) {
      setFullName(partner?.fullName);
      setEmail(partner?.email);
      setBusinessName(partner?.companyName);
      setContactNumber(partner?.phone);
      setBusinessContact(partner?.businessContact);
      setDescription(partner?.description);
      setWebsite(partner?.website);
      setFacebookLink(partner?.facebookLink);
      setInstagramLink(partner?.instagramLink);
      setYoutubeLink(partner?.youtubeLink);
      setTwitterLink(partner?.twitterLink);
      setSelectedFile(partner?.logoUrl);
      setAddress(partner?.address);
      Boolean(partner?.latitude && partner?.longitude) &&
        setLatLng(partner?.latitude, partner?.longitude);
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const reqObj = {
        fullName,
        email,
        businessContact,
        companyName: businessName,
        description,
        website,
        facebookLink,
        instagramLink,
        youtubeLink,
        twitterLink,
        latitude,
        longitude,
      };

      const errorsObj = {};

      if (!reqObj.fullName) {
        errorsObj.fullName = "Full Name is Required";
      }

      if (!reqObj.email) {
        errorsObj.email = "Email is Required";
      }

      if (!reqObj.businessContact) {
        errorsObj.businessContact = "Business Contact is Required";
      }

      if (!reqObj.companyName) {
        errorsObj.companyName = "Company Name is Required";
      }

      if (!reqObj.description) {
        errorsObj.description = "Description is Required";
      }
      if (reqObj.website) {
        if (!isValidLink(reqObj.website)) {
          errorsObj.website = "Please enter a valid link";
        }
      }

      if (reqObj.facebookLink) {
        if (!isValidLink(reqObj.facebookLink)) {
          errorsObj.facebookLink = "Please enter a valid link";
        }
      }
      if (reqObj.instagramLink) {
        if (!isValidLink(reqObj.instagramLink)) {
          errorsObj.instagramLink = "Please enter a valid link";
        }
      }
      if (reqObj.twitterLink) {
        if (!isValidLink(reqObj.twitterLink)) {
          errorsObj.twitterLink = "Please enter a valid link";
        }
      }
      if (reqObj.youtubeLink) {
        if (!isValidLink(reqObj.youtubeLink)) {
          errorsObj.youtubeLink = "Please enter a valid link";
        }
      }

      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }

      setErrors({});

      await editProfile(reqObj, selectedFile);
    } catch (err) {
      console.log(err.message);
    } finally {
      // setLoading(false);
    }
  };

  // console.log(errors, "LOGOURL_UPLOAD");
  return (
    <Box sx={{ p: 3, pl: 5, pr: 5, pt: 2 }}>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Full Name"
            fullWidth
            required
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            error={errors?.fullName}
            helperText={errors?.fullName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Email Address"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errors?.email}
            helperText={errors?.email}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Business Name"
            fullWidth
            required
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            error={errors?.companyName}
            helperText={errors?.companyName}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Contact Number"
            fullWidth
            required
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            error={errors?.contactNumber}
            helperText={errors?.contactNumber}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <SimpleInput
            label="Business Description"
            fullWidth
            required
            multiline
            minRows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={errors?.description}
            helperText={errors?.description}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Website"
            simple
            fullWidth
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            error={errors?.website}
            helperText={errors?.website}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Facebook Link"
            simple
            fullWidth
            value={facebookLink}
            onChange={(e) => setFacebookLink(e.target.value)}
            error={errors?.facebookLink}
            helperText={errors?.facebookLink}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Instagram Link"
            simple
            fullWidth
            value={instagramLink}
            onChange={(e) => setInstagramLink(e.target.value)}
            error={errors?.instagramLink}
            helperText={errors?.instagramLink}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Twitter Link"
            simple
            fullWidth
            value={twitterLink}
            onChange={(e) => setTwitterLink(e.target.value)}
            error={errors?.twitterLink}
            helperText={errors?.twitterLink}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="YouTube Link"
            simple
            fullWidth
            value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)}
            error={errors?.youtubeLink}
            helperText={errors?.youtubeLink}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Business Contact"
            simple
            fullWidth
            value={businessContact}
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                setBusinessContact(e.target.value);
              }
            }}
            error={errors?.businessContact}
            helperText={errors?.businessContact}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <ImageUploader
            file={selectedFile}
            handleFileInputClick={handleFileInputClick}
            fileInputRef={fileInputRef}
            handleFileChange={handleFileChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <LocationInput
            getAddress={getAddress}
            address={address}
            label="Location"
            fullWidth
            placeholder={"Location"}
            simple
            error={errors.location}
            helperText={errors.location}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} style={{ height: 250, width: "100%" }}>
          <GoogleMap
            style={{ height: 250, width: "95%" }}
            lat={latitude}
            lng={longitude}
            latitude={latitude}
            longitude={longitude}
            range={matchRange}
            getAddress={getAddress}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1, mt: 2 }}>
        <Grid item xs={12}>
          <PrimaryButton
            text={"SAVE"}
            onClick={handleSubmit}
            elevation={3}
            size="large"
            fullWidth
            loading={loading}
            sx={{
              fontWeight: "bold",
              color: Theme.color.basic,
              backgroundColor: Theme.bg.yellow,
              boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              "&.MuiButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.yellow,
                  borderColor: Theme.bg.yellow,
                  boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyProfile;
