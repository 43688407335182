import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AiFillExclamationCircle } from "react-icons/ai";
import { Theme } from "../../Theme";
const ToggleInput = ({ label, required, simple, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <InputLabel
        htmlFor="outlined-adornment-password"
        sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
      >
        {label}
        {required ? (
          <span style={{ color: Theme.color.red }}>*</span>
        ) : simple ? null : (
          <Tooltip title="Delete">
            <IconButton>
              <AiFillExclamationCircle size={15} color="#0088FF" />
            </IconButton>
          </Tooltip>
        )}
      </InputLabel>
      <FormControl
        // sx={{ width: "full", backgroundColor: "#F3F6F9" }}
        variant="outlined"
        sx={{
          width: "100%",
          // bgcolor: "#F3F6F9",
          borderRadius: "6px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "6px",
            borderColor: "#DDE6EE",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderRadius: "6px",
            borderColor: "#DDE6EE",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderRadius: "6px",
            borderColor: "#DDE6EE",
          },
        }}
        {...props}
      >
        <OutlinedInput
          type={showPassword ? "text" : "password"}
          sx={!showPassword && { fontSize: 18 }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          {...props}
        />
        {props?.helperText && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default ToggleInput;
