export const ADD_USER = "ADD_USER";
export const ADD_IS_BANNED = "ADD_IS_BANNED";
export const ADD_PARTNER_FINCHES = "ADD_PARTNER_FINCHES";
export const ADD_PARTNER_FRANCHISES = "ADD_PARTNER_FRANCHISES";
export const ADD_PARTNER_DATA = "ADD_PARTNER_DATA";
export const ADD_CATEGORIES = "ADD_CATEGORIES";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const ADD_PACKAGE = "ADD_PACKAGE";
export const ADD_CUSTOMER_SUBSCRIPTION = "ADD_CUSTOMER_SUBSCRIPTION";
export const ADD_PACKAGE_DETAILS = "ADD_PACKAGE_DETAILS";
export const ADD_PRIVACY = "ADD_PRIVACY";
