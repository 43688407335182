import { Button } from "@mui/material";
import React from "react";
import { LuFilter } from "react-icons/lu";

const FilterButton = ({ ...props }) => {
  return (
    <Button
      variant="outlined"
      style={{
        color: "#88949E",
        borderColor: "#E7EAEE",
        backgroundColor: "rgba(255, 255, 255, 1)",
      }}
      startIcon={<LuFilter />}
      size="large"
      sx={{ px: { xs: 2, sm: 2, md: 3 } }}
    //   onClick={()=>{onClick()}}
    {...props}
    >
      Filter
    </Button>
  );
};

export default FilterButton;
