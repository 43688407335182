import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Grid,
  Button,
  Paper,
  useMediaQuery,
  CircularProgress,
  Alert,
} from "@mui/material";
import StickyHeadTable from "../../Components/Table/index.jsx";

import GradientBox from "../../Components/GradientBox";
import SideBar from "../../Components/Sidebar";
import CreateFinch from "../../Components/Forms/CreateFinch";
import BasicModal from "../../Components/Modal";
import DeleteComp from "../../Components/Modal/DeleteComp";
import "../../App.css";
import DashboardFilter from "../../Components/Menu/DashboardFilter";
import ColumnMenu from "../../Components/Menu/ColumnMenu";
import ExpandableSearchBar from "../../Components/Inputs/ExpandableSearchBar";
import SimpleMenu from "../../Components/Menu";
import FilterButton from "../../Components/Buttons/FilterButton";
import ColumnButton from "../../Components/Buttons/ColumnButton";

import useFinch from "../../ApiHooks/useFinch";
import usePayment from "../../ApiHooks/usePayment.js";
import { useSelector } from "react-redux";
import { Link, useOutletContext } from "react-router-dom";
import tooltipWrapper from "../../Utils/tooltipWrapper.js";

const Dashboard = ({}) => {
  const [finchLoading] = useOutletContext();
  console.log(finchLoading, "finchLoading");
  const {
    loading,
    finchData,
    columns,
    options,
    selectedId,
    setSelectedId,
    setSidebarOpen,
    sidebarOpen,
    deleteModal,
    setDeleteModal,
    handleFinchDelete,
    handleColumnSelected,
    optionsSelected,
    setFilterType,
    setFilterStatus,
    setFilterFranchise,
    filterType,
    filterStatus,
    filterFranchise,
    fetchLoading,
  } = useFinch(finchLoading);
  const { getPackageDetails } = usePayment();
  const packageDetails = useSelector(
    (state) => state.paymentReducer.packageDetails
  );
  const user = useSelector((state) => state.userReducer.user);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [searchText, setSearchText] = useState("");

  const menuOpen1 = Boolean(anchorEl1);
  const menuOpen2 = Boolean(anchorEl2);

  const handleFilterChange = (value, label) => {
    switch (label) {
      case "status":
        setFilterStatus(value);
        break;
      case "type":
        setFilterType(value);
        break;
      case "franchise":
        setFilterFranchise(value);
    }
  };

  const handleMenuClick = (event, state) => {
    if (state === "filter") {
      setAnchorEl1(event.currentTarget);
    } else if (state === "column") {
      setAnchorEl2(event.currentTarget);
    }
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const filteredFinches = finchData?.filter((item) =>
    item?.title?.props?.children
      ?.toLowerCase()
      .includes(searchText.toLowerCase())
  );

  return (
    <>
      {user?.isProfileCompleted === false && (
        <div className={"my-10 px-20"}>
          <Alert style={{ backgroundColor: "#E5F6FD" }} severity="info">
            Please setup your profile to create goldfinches.{" "}
          </Alert>
        </div>
      )}

      <Box sx={{ p: 1, height: "auto" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", sm: "wrap", md: "nowrap" },
            my: 2,
          }}
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={6}>
            <GradientBox
              text={"Remaining Finches"}
              count={packageDetails?.campaign?.finches}
              fontFamily="Urbanist-Medium"
              fontSize={"32px"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <GradientBox
              text={"Remaining GoldFinches"}
              count={packageDetails?.campaign?.goldfinches}
              fontFamily="Urbanist-Medium"
              fontSize={"32px"}
            />
          </Grid>
        </Grid>
        {isSmallScreen ? (
          <Paper sx={{ px: 1, py: 2 }}>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 3,
                // px:1
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: 1, sm: 2, md: 5 },
                  // flexWrap: "wrap",
                  justifyContent: {
                    xs: "space-between",
                    sm: "space-between",
                    md: "none",
                  },
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}
                >
                  Campaigns
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: 1, sm: 2, md: 5 },
                  justifyContent: {
                    xs: "space-between",
                    sm: "space-between",
                    md: "none",
                  },
                  // flexWrap:"wrap",
                  width: "100%",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {tooltipWrapper(
                      <Button
                        onClick={() => {
                          setSidebarOpen("Finch");
                          setSelectedId(null);
                        }}
                        style={{ backgroundColor: "#5BC0DE" }}
                        sx={{
                          color: "white",
                          py: 1,
                          px: { xs: 1, sm: 2, md: 3 },
                        }}
                        disabled={
                          !user?.isProfileCompleted ||
                          packageDetails?.campaign?.finches <= 0
                        }
                        fullWidth
                      >
                        Create Finch
                      </Button>,
                      packageDetails?.campaign?.finches <= 0,
                      "Subscribe to a campaign package to create Finches."
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {tooltipWrapper(
                      <Button
                        onClick={() => {
                          setSidebarOpen("GoldFinch");
                          setSelectedId(null);
                        }}
                        style={{ backgroundColor: "#32C28C" }}
                        sx={{
                          color: "white",
                          py: 1,
                          px: { xs: 1, sm: 2, md: 3 },
                        }}
                        disabled={
                          !user?.isProfileCompleted ||
                          packageDetails?.campaign?.goldfinches <= 0
                        }
                        fullWidth
                      >
                        Create GoldFinch
                      </Button>,
                      packageDetails?.campaign?.goldfinches <= 0,
                      "Subscribe to a campaign package to create Goldfinches."
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <FilterButton
                      onClick={(e) => {
                        handleMenuClick(e, "filter");
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ColumnButton
                      onClick={(e) => {
                        handleMenuClick(e, "column");
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ExpandableSearchBar
                      search={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",

              width: "100%",
              my: 3,
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 2, sm: 3, md: 2 },
                justifyContent: {
                  xs: "space-between",
                  sm: "space-between",
                  md: "flex-start",
                },
                // width: "100%",
              }}
            >
              <Typography
                sx={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}
              >
                Campaigns
              </Typography>

              <FilterButton
                onClick={(e) => {
                  handleMenuClick(e, "filter");
                }}
              />
              <ColumnButton
                onClick={(e) => {
                  handleMenuClick(e, "column");
                }}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 2, sm: 3, md: 2 },
                justifyContent: {
                  xs: "space-between",
                  sm: "space-between",
                  md: "flex-start",
                },
                // width: "100%",
              }}
            >
              <ExpandableSearchBar
                search={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {tooltipWrapper(
                <Button
                  onClick={() => {
                    setSidebarOpen("Finch");
                    setSelectedId(null);
                  }}
                  style={{ backgroundColor: "#5BC0DE" }}
                  sx={{
                    color: "white",
                    py: 1,
                    px: { xs: 1, sm: 2, md: 3 },
                  }}
                  disabled={
                    !user?.isProfileCompleted ||
                    packageDetails?.campaign?.finches <= 0
                  }
                >
                  Create Finch
                </Button>,
                packageDetails?.campaign?.finches <= 0,
                "Subscribe to a campaign package to create Finches."
              )}
              {tooltipWrapper(
                <Button
                  onClick={() => {
                    setSidebarOpen("GoldFinch");
                    setSelectedId(null);
                  }}
                  style={{ backgroundColor: "#32C28C" }}
                  sx={{
                    color: "white",
                    py: 1,
                    px: { xs: 1, sm: 2, md: 3 },
                  }}
                  disabled={
                    !user?.isProfileCompleted ||
                    packageDetails?.campaign?.goldfinches <= 0
                  }
                >
                  Create GoldFinch
                </Button>,
                packageDetails?.campaign?.goldfinches <= 0,
                "Subscribe to a campaign package to create Goldfinches."
              )}
            </Grid>
          </Grid>
        )}
        {fetchLoading && !sidebarOpen && !finchData?.length ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ my: 2 }}>
            <StickyHeadTable
              columns={columns}
              rows={filteredFinches}
              columnsFilter={optionsSelected}
            />
          </Box>
        )}
        <SimpleMenu
          handleClose={handleClose1}
          open={menuOpen1}
          anchorEl={anchorEl1}
          data={
            <DashboardFilter
              handleChange={handleFilterChange}
              filterType={filterType}
              filterStatus={filterStatus}
              filterFranchise={filterFranchise}
            />
          }
        />
        <SimpleMenu
          handleClose={handleClose2}
          open={menuOpen2}
          anchorEl={anchorEl2}
          data={
            <ColumnMenu
              selectedId={selectedId}
              handleOptionClick={handleColumnSelected}
              options={options}
              optionsSelected={optionsSelected}
            />
          }
        />
        {sidebarOpen && (
          <SideBar
            open={sidebarOpen}
            onClose={() => {
              setSidebarOpen(null);
            }}
            title={
              selectedId
                ? sidebarOpen === "Duplicate"
                  ? "Create Duplicate"
                  : `Edit ${sidebarOpen}`
                : `Create ${sidebarOpen}`
            }
            form={
              <CreateFinch
                id={selectedId}
                type={sidebarOpen}
                onClose={() => {
                  setSidebarOpen(null);
                }}
                open={Boolean(sidebarOpen)}
              />
            }
          />
        )}

        <BasicModal
          open={deleteModal}
          handleClose={() => setDeleteModal(null)}
          data={
            <DeleteComp
              onClose={() => setDeleteModal(null)}
              id={deleteModal}
              loading={loading}
              onSubmit={() => handleFinchDelete({ finchId: deleteModal })}
            />
          }
        />
      </Box>
    </>
  );
};

export default Dashboard;
