import React, { useState } from "react";
import { Chart } from "../../Components/Charts/Chart";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SlCalender } from "react-icons/sl";
import "../../App.css";
import { Theme } from "../../Theme/index";
import { addDays, subDays, format } from "date-fns";
import DateRangeModal from "../../Components/Modal/DateRangeModal";
import useAnalytics from "../../ApiHooks/useAnalytics";

const Analytics = () => {
  const {
    data,
    loading,
    getPartnerAnalytics,
    savedFinches,
    viewedFinches,
    directions,
    phoneNo,
    website,
    media,
    analyticType,
  } = useAnalytics();

  console.log(savedFinches, "savedFinches");
  const [value, setValue] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  return (
    <Box sx={{ p: 1, bgcolor: Theme.bg.bg }}>
      <Box sx={{ my: 2 }}>
        <Typography sx={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}>
          Global Analytics
        </Typography>
      </Box>
      <Box sx={{ my: 2 }}>
        {/* <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
          <DateRangeModal
            value={value}
            onChange={setValue}
            getData={getPartnerAnalytics}
          />
        </Box> */}
        {/* <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              sx={{
                height: { xs: "auto", sm: "auto", md: 600 },
                width: "100%",
              }}
            >
              <Chart
                label={"Users Received Notifications"}
                GoldFinches={"GoldFinches"}
                Finches={"Finches"}
                style={{ minWidth: "100%", height: 600 }}
              />
            </Paper>
          </Grid>
        </Grid> */}
      </Box>
      <Box sx={{ my: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
          <DateRangeModal
            value={value}
            onChange={setValue}
            getData={getPartnerAnalytics}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper sx={{ height: { xs: "300px", sm: "auto" }, p: 1 }}>
              <Chart
                label={"Users Saved Goldfinch/Finches"}
                style={{
                  minWidth: "100%",
                  height: { xs: 600, sm: 600, md: "auto" },
                }}
                data={savedFinches}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper sx={{ height: { xs: "300px", sm: "auto" }, p: 1 }}>
              <Chart
                label={"Users Viewed Goldfinch/Finches"}
                GoldFinches={"GoldFinches"}
                Finches={"Finches"}
                style={{
                  minWidth: "100%",
                  height: { xs: 600, sm: 600, md: "auto" },
                }}
                data={viewedFinches}
              />
            </Paper>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6}>
            <Paper sx={{height: {xs:"auto",sm:"auto",},p:1}}>
              <Chart
                label={"Users Viewed Goldfinch/Finches"}
                GoldFinches={"GoldFinches"}
                Finches={"Finches"}
                style={{ minWidth: "100%", height: {xs:600,sm:600,md:"auto"} }}
              />
            </Paper>
          </Grid> */}
        </Grid>
      </Box>
      <Box sx={{ my: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Paper sx={{ height: { xs: "300px", sm: "auto" }, p: 1 }}>
              <Chart
                label={"Users Pressed Get Directions"}
                GoldFinches={"GoldFinches"}
                Finches={"Finches"}
                style={{
                  minWidth: "100%",
                  height: { xs: 600, sm: 600, md: "auto" },
                }}
                data={directions}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Paper sx={{ height: { xs: "300px", sm: "auto" }, p: 1 }}>
              <Chart
                label={"Users Pressed Website"}
                GoldFinches={"GoldFinches"}
                Finches={"Finches"}
                style={{
                  minWidth: "100%",
                  height: { xs: 600, sm: 600, md: "auto" },
                }}
                data={website}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Paper sx={{ height: { xs: "300px", sm: "auto" }, p: 1 }}>
              <Chart
                label={"Users Pressed Phone Number"}
                GoldFinches={"GoldFinches"}
                Finches={"Finches"}
                style={{
                  minWidth: "100%",
                  height: { xs: 600, sm: 600, md: "auto" },
                }}
                data={phoneNo}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          my: 3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              sx={{
                height: { xs: "auto", sm: "auto", md: 600 },
                width: "100%",
              }}
            >
              <Chart
                label={"Social Media Platforms Activity"}
                data={media}
                style={{ minWidth: "100%", height: 600 }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Analytics;
