import { useState } from "react";
import useFetch from "../Utils/useFetch";
import {
  GET_PARTNER_ANALYTICS_API,
  GET_PARTNER_DATA_API,
} from "../Constants/APIs";
import { format } from "date-fns";
import _ from "lodash";

const useAnalytics = () => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [savedFinches, setSavedFinches] = useState([]);
  const [viewedFinches, setViewedFinches] = useState([]);
  const [directions, setDirections] = useState([]);
  const [phoneNo, setPhoneNo] = useState([]);
  const [website, setWebsite] = useState([]);
  const [media, setMedia] = useState([]);
  const [analyticType, setAnalyticType] = useState();
  const getPartnerAnalytics = async (body) => {
    try {
      setLoading(true);
      const req = await request(GET_PARTNER_ANALYTICS_API, "POST", body);
      setLoading(false);
      const dataArray = req?.data?.data;
      setSavedFinches([...sortFilterTypeData(dataArray, "claimed")]);
      setViewedFinches([...sortFilterTypeData(dataArray, "viewed")]);
      setDirections(sortFilterData(dataArray, "location"));
      setPhoneNo(sortFilterData(dataArray, "phone"));
      setWebsite(sortFilterData(dataArray, "website"));

      setMedia(sortMediaData(dataArray, "social"));
      setData(req?.data?.data);
    } catch (err) {
      console.log(err, "err.message");
    }
  };
  return {
    getPartnerAnalytics,
    loading,
    data,
    savedFinches,
    viewedFinches,
    directions,
    phoneNo,
    website,
    media,
    analyticType,
  };
};

export default useAnalytics;

const sortFilterTypeData = (arr, type) => {
  const newArr = arr.filter((item) => item.type === type);
  const drizzle = newArr.filter((item) => item.finchType === "drizzles");
  const drenches = newArr.filter((item) => item.finchType !== "drizzles");
  const drenchesData = filterSortDrenches(drenches);
  const drizzlesData = filterSortDrenches(drizzle);
  // console.log(drenchesData, drizzlesData, "drenchesData, drizzlesData");
  return [
    {
      label: "Finch",
      data: drizzlesData,
      borderColor: "#F7A9B9",
      backgroundColor: "#F7A9B9",
    },
    {
      label: "GoldFinch",
      data: drenchesData,
      backgroundColor: "#5DF9B8",
      borderColor: "#5DF9B8",
    },
  ];
};
const sortMediaData = (data, type) => {
  let dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const mediaSupported = ["facebook", "instagram", "twitter", "youtube"];
  const mediaColor = ["#4267B2", "#3F729B", "#00ACEE", "#E7404A"];
  const mediaData = data.filter((item) =>
    mediaSupported.some((v) => v === item?.platform)
  );
  const dateArr = mediaData.map((item) => {
    return {
      ...item,
      dateString: format(
        new Date((item.createdAt?.seconds || item.createdAt?._seconds) * 1000),
        "EEE"
      ),
    };
  });

  const sortedData = _.groupBy(dateArr, "dateString");

  const mediaArr = mediaSupported.map((v, i) => {
    return {
      label: v,
      data: dayArr.map((key) => {
        return (
          sortedData?.[key]?.filter((item) => item.platform === v)?.length || 0
        );
      }),

      backgroundColor: mediaColor[i],
      borderColor: mediaColor[i],
    };
  });

  return mediaArr;
};

const sortFilterData = (arr, type) => {
  const newArr = arr.filter((item) => item.type === type);

  const drenches = sortByType(newArr, "drenches");
  const drizzles = sortByType(newArr, "drizzles");

  return [
    {
      label: "Finch",
      data: drizzles,
      borderColor: "#F7A9B9",
      backgroundColor: "#F7A9B9",
    },
    {
      label: "GoldFinch",
      data: drenches,
      backgroundColor: "#5DF9B8",
      borderColor: "#5DF9B8",
    },
  ];
};

const sortByType = (newArr, type) => {
  let dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const data = newArr.filter((item) => item.finchType === type);
  const dateArr = data.map((item) => {
    return {
      ...item,
      dateString: format(
        new Date((item.createdAt?.seconds || item.createdAt?._seconds) * 1000),
        "EEE"
      ),
    };
  });
  // console.log(newArr, "newArr", type);
  const sortedByData = dateArr.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return (
      new Date((b.createdAt?.seconds || a.createdAt?._seconds) * 1000) -
      new Date((a.createdAt?.seconds || b.createdAt?._seconds) * 1000)
    );
  });

  const groupByDay = _.groupBy(sortedByData, "dateString");

  return dayArr.map((key) => {
    return groupByDay?.[key]?.length || 0;
  });
};

const filterSortDrenches = (arr, type) => {
  let dayArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dateArr = arr.map((item) => {
    // console.log(item, "LOLOLOs");
    return {
      ...item,
      dateString: format(
        new Date((item.createdAt?.seconds || item.createdAt?._seconds) * 1000),
        "EEE"
      ),
    };
  });
  // console.log(newArr, "newArr", type);
  const sortedByData = dateArr.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return (
      new Date((b.createdAt?.seconds || a.createdAt?._seconds) * 1000) -
      new Date((a.createdAt?.seconds || b.createdAt?._seconds) * 1000)
    );
  });

  const groupByDay = _.groupBy(sortedByData, "dateString");

  const newGroupByDay = dayArr.map((key) => {
    return groupByDay?.[key]?.length || 0;
  });
  return newGroupByDay;
};
