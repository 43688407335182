export const Theme = {
  color: {
    basic: "#292929",
    white: "#fff",
    text: "#4A4C51",
    primary: "#03295E",
    golden: "#C7991A",
    red: "#DC2845",
    darkSky: "#1594AE",
    black: "#191D26",
    gray: "#4F5C6A",
    borderColor: "#DDE6EE",
    darkRed: "#E7404A",
    yellow: "#FFD831",
    darkGreen: "#1CBC3C",
  },
  bg: {
    white: "#fff",
    bg: "#F3F6F9",
    lightYellow: "#FFDC44",
    lightSky: "#00C3EB",
    lightRed: "#E3536A",
    lightGreen: "#5BCEA3",
    green: "#97FFAF",
    blueBtn: "#5BC0DE",
    greenBtn: "#32C28C",
    redBtn: "#E7404A",
    yellowBtn: "#FFD831",
    darkBlue: "#009BEB",
    gray: "#4F5C6A",
    yellow: "#FFD831",
    darkgrey: "#292929",
    darkGreen: "#1CBC3C",
    lightGrey: "#F8FAFB",
  },
};
