import { useEffect, useState } from "react";
import useFetch from "../Utils/useFetch";
import { GET_PRIVACY_POLICY_API } from "../Constants/APIs";
import { useDispatch } from "react-redux";
import { ADD_PRIVACY } from "../Redux/types";

const usePrivacy = () => {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getPrivacyPolicy = async () => {
    try {
      setLoading(true);
      const req = await request(GET_PRIVACY_POLICY_API, "POST");
      dispatch({ type: ADD_PRIVACY, payload: req.data?.data });
    } catch (err) {
      console.log(err, "err.message");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return {
    getPrivacyPolicy,
    loading,
  };
};

export default usePrivacy;
