import React from "react";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import UrbanistRegular from "../src/Assets/Fonts/Urbanist.ttf";
import UrbanistMedium from "../src/Assets/Fonts/Urbanist-Medium.ttf";
import UrbanistSemibold from "../src/Assets/Fonts/Urbanist-SemiBold.ttf";
import UrbanistBold from "../src/Assets/Fonts/Urbanist-Bold.ttf";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux";
import { Theme } from "./Theme";
import Navigation from "./Navigation";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "../src/Components/Stripe/Stripe";
import { ConfirmProvider } from "material-ui-confirm";

let theme = createTheme({
  palette: {
    primary: {
      main: Theme.color.basic,
    },
    secondary: {
      main: "#FFD831",
    },
  },
  typography: {
    fontFamily: [
      UrbanistMedium,
      UrbanistSemibold,
      UrbanistBold,
      UrbanistRegular,
    ],
    allVariants: {
      fontFamily: "Urbanist-Regular",
    },
    button: {
      textTransform: "none",
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Elements stripe={Stripe}>
            <ConfirmProvider>
              <Navigation />
            </ConfirmProvider>
          </Elements>
        </ThemeProvider>
        <ToastContainer />
      </PersistGate>
    </Provider>
  );
}

export default App;
