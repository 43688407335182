import React, { useEffect, useState } from "react";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import Logo from "../../Assets/Image/LogoBig.png";
import Underline from "../../Components/Buttons/Underline";
import GoogleMap from "../../Components/GoogleMap/GoogleMap";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import BANNER_IMG from "../../Assets/Image/Banner1.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Theme } from "../../Theme";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "../../App.css";
import { SIGN_IN_PATH, TERMS_PRIVACY_PATH } from "../../Constants/Paths";
import { useSelector } from "react-redux";
import LocationInput from "../../Components/Inputs/LocationInput";
import useAuth from "../../ApiHooks/useAuth";

const SignUp = () => {
  const [color, setColor] = useState("#FFD831");
  const { signup, loading } = useAuth();
  const navigate = useNavigate();
  const [swapForm, setSwapForm] = useState(true);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [location, setLocation] = useState("");
  const user = useSelector((state) => state.userReducer.user);
  const [errors, setErrors] = useState("");
  const [fullAddress, setFullAddress] = useState();
  const [address, setAddress] = useState();
  const [matchRange, setMatchRange] = useState(3000);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState();
  const [privacy, setPrivacy] = useState(false);
  const getAddress = (address, lat, lng) => {
    if (lat && lng) {
      setLatLng(lat, lng);
    }
    if (address.length > 50) {
      setAddress(address.substr(0, 50) + "...");
      setFullAddress(address);
    } else {
      setAddress(address);
      setFullAddress(address);
    }
  };

  function setLatLng(lat, lng) {
    setLatitude("");
    setLongitude("");
    setLatitude(lat);
    setLongitude(lng);
  }

  useEffect(() => {
    let isAuth = JSON.parse(localStorage.getItem("user"));
    if (user && user !== null) {
      navigate("/");
    }
  }, []);

  const toggleColor = () => {
    const errorsObj = {};

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errorsObj.email = "Please enter a valid email address";
    }

    if (!fullName) {
      errorsObj.fullName = "Full Name is required";
    }

    if (!password) {
      errorsObj.password = "Password is required";
    }

    if (!contactNumber) {
      errorsObj.contactNumber = "Contact is required";
    }

    if (Object.keys(errorsObj).length) {
      setErrors(errorsObj);
      return;
    }
    setColor((prevColor) => (prevColor === "#FFD831" ? "#E6EAEF" : "#FFD831"));
    setSwapForm((prevValue) => !prevValue);
    // navigate(SIGN_IN_PATH, { replace: false })
  };

  const handleSignUp = async () => {
    try {
      const errorsObj = {};
      if (!businessName) {
        errorsObj.businessName = "Business Name is required";
      }
      if (!businessDescription) {
        errorsObj.businessDescription = "Business Name is required";
      }

      if (!latitude) {
        errorsObj.location = "Location is required";
      }
      if (!longitude) {
        errorsObj.location = "Location is required";
      }
      if (!address) {
        errorsObj.location = "Location is required";
      }

      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }

      let formData = {
        fullName,
        email,
        companyName: businessName,
        password: password,
        description: businessDescription,
        address,
        phone: contactNumber,
        longitude: longitude,
        latitude: latitude,
        createdAt: new Date(),
      };
      signup(formData);
      // toast.success("Account Created Successfully ");
      // navigate(SIGN_IN_PATH, { replace: false });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: { xs: "none", lg: "block" },
          }}
        >
          <img
            src={BANNER_IMG}
            alt="Banner Image"
            style={{ width: "100%", height: swapForm ? "100vh" : "100%" }}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ px: 2 }}>
          <Box sx={{ width: "100%", height: "auto" }}>
            {!swapForm ? (
              <Paper
                sx={{
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ml: { xs: 0, sm: 5 },
                  mt: 5,
                  boxShadow: "10px 10px 30px #00000029",
                  borderRadius: "15px",
                  py: 0.5,
                }}
                onClick={() => setSwapForm(true)}
              >
                <IconButton>
                  <ArrowBackIosNewIcon sx={{ color: Theme.color.basic }} />
                </IconButton>
              </Paper>
            ) : null}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height:""
              }}
            >
              <Box sx={{ my: 5, width: "500px" }}>
                <Box className="flex flex-col justify-center items-center w-full">
                  <img src={Logo} className="w-[128px] max-w-full my-10" />
                  <Box
                    sx={{
                      display: "flex",
                      // justifyContent: {xs:"flex-start",sm:"center"},
                      alignItems: {
                        xs: "flex-start",
                        sm: "flex-start",
                        md: "center",
                      },
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Urbanist-Bold",
                        my: 3,
                        fontSize: "32px",
                      }}
                    >
                      Create an Account
                    </Typography>
                    <Typography className="mb-2 md:mb-10 text-[#8A8A8A] font-['Urbanist-Medium']">
                      Already have an account?{" "}
                      <Link
                        to={SIGN_IN_PATH}
                        style={{
                          color: "#1EA0FF",
                          fontFamily: "Urbanist-Bold",
                        }}
                      >
                        Sign In
                      </Link>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 5,
                      my: 2,
                    }}
                  >
                    <Box
                      style={{ border: `2px solid ${color}` }}
                      className="border-b-[5px] rounded-xl w-14"
                    ></Box>
                    <Box
                      style={{
                        border: `2px solid ${
                          color === "#FFD831" ? "#E6EAEF" : "#FFD831"
                        }`,
                      }}
                      className="border-b-[5px] rounded-xl w-14"
                    ></Box>
                  </Box>
                </Box>

                {swapForm ? (
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    <SimpleInput
                      label="Full Name"
                      fullWidth
                      placeholder={"Full Name"}
                      simple
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      error={errors.fullName}
                      helperText={errors.fullName}
                    />
                    <SimpleInput
                      label="Email Address"
                      fullWidth
                      placeholder={"Email Address"}
                      simple
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      error={errors.email}
                      helperText={errors.email}
                    />
                    <SimpleInput
                      label="Create Password"
                      fullWidth
                      placeholder={"Create Password"}
                      simple
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      error={errors.password}
                      helperText={errors.password}
                      type="password"
                    />
                    <SimpleInput
                      label="Contact Number"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      autoComplete="off"
                      fullWidth
                      placeholder={"Contact Number"}
                      simple
                      onChange={(e) => setContactNumber(e.target.value)}
                      value={contactNumber}
                      error={errors.contactNumber}
                      helperText={errors.contactNumber}
                    />

                    {/* <PrimaryButton text={"Next"} onClick={toggleColor} /> */}
                    <PrimaryButton
                      text={"NEXT"}
                      onClick={toggleColor}
                      sx={{
                        color: Theme.color.basic,
                        backgroundColor: Theme.bg.yellow,
                        borderRadius: "8px",
                        fontSize: "18px",
                        "&.MuiButton-root": {
                          "&:hover": {
                            backgroundColor: Theme.bg.yellow,
                            borderColor: Theme.bg.yellow,
                            boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                          },
                        },
                        fontFamily: "Urbanist-Bold",
                        boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      // justifyContent: "center",
                      // alignItems: "center",
                    }}
                  >
                    {/* <div className="w-[300px] lg:w-[475px]"> */}
                    <SimpleInput
                      label="Business Name"
                      fullWidth
                      placeholder={"Business Name"}
                      simple
                      onChange={(e) => setBusinessName(e.target.value)}
                      value={businessName}
                      error={errors.businessName}
                      helperText={errors.businessName}
                    />
                    <SimpleInput
                      label="Business Description"
                      fullWidth
                      placeholder={"Business Description"}
                      simple
                      onChange={(e) => setBusinessDescription(e.target.value)}
                      value={businessDescription}
                      error={errors.businessDescription}
                      helperText={errors.businessDescription}
                    />
                    {/* <SimpleInput
                      label="Location"
                      fullWidth
                      placeholder={"Location"}
                      simple
                    /> */}
                    <LocationInput
                      getAddress={getAddress}
                      address={address}
                      label="Location"
                      fullWidth
                      placeholder={"Location"}
                      simple
                      error={errors.location}
                      helperText={errors.location}
                    />
                    {/* <GoogleMap /> */}
                    <div
                      style={{
                        // position: "absolute",
                        // top: 0,
                        height: 250,
                        marginTop: 20,
                      }}
                    >
                      <GoogleMap
                        lat={latitude}
                        lng={longitude}
                        latitude={latitude}
                        longitude={longitude}
                        range={matchRange}
                        getAddress={getAddress}
                      />
                    </div>
                    {/* <PrimaryButton text={"Sign Up"} onClick={handleSignUp} /> */}
                    <PrimaryButton
                      text={"SIGN UP"}
                      onClick={handleSignUp}
                      loading={loading}
                      disabled={!privacy || loading}
                      sx={{
                        color: Theme.color.basic,
                        backgroundColor: Theme.bg.yellow,
                        borderRadius: "8px",
                        fontSize: "18px",
                        "&.MuiButton-root": {
                          "&:hover": {
                            backgroundColor: Theme.bg.yellow,
                            borderColor: Theme.bg.yellow,
                            boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                          },
                        },
                        fontFamily: "Urbanist-Bold",
                        boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                      }}
                    />
                    <Underline />
                    <div
                      style={{
                        zIndex: 2000,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Checkbox
                        checked={privacy ? "checked" : undefined}
                        onChange={() => setPrivacy(!privacy)}
                      />
                      <Typography
                        sx={{
                          mt: 1.4,
                          textAlign: "center",
                          color: "#8A8A8A",
                          fontSize: "14px",
                          fontFamily: "Urbanist-Regular",
                        }}
                      >
                        By signing up you agree to Goldfinch{" "}
                        <Link
                          to={TERMS_PRIVACY_PATH}
                          style={{
                            color: "#1EA0FF",
                            fontFamily: "Urbanist-Bold",
                            cursor: "pointer",
                            // fontSize:'14px'
                          }}
                        >
                          Privacy Policy
                        </Link>
                      </Typography>
                    </div>
                    {/* </div> */}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUp;
