import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Switch from "../../Assets/SVG/Switch.svg";
import {
  Avatar,
  Button,
  ButtonGroup,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import Refresh from "../../Assets/SVG/Refresh.svg";
import Profile from "../../Assets/SVG/Profile.svg";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Theme } from "../../Theme";
import { LuRefreshCw } from "react-icons/lu";
import { TbPackage } from "react-icons/tb";
import { BsFillPersonFill } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoLogOut } from "react-icons/io5";
import IconBtn from "../Buttons/IconButton";
import ROUTES from "../../Navigation/ROUTES";
import LOGO_IMG from "../../Assets/Image/LogoWhite.png";
import "../../App.css";
import "./index.css";
import {
  ADD_FRANCHISE_PATH,
  ANALYTICS_PATH,
  BUY_PACKAGES_PATH,
  DASHBOARD_PATH,
  SAVED_GOLD_FINCHES_PATH,
  SIGN_IN_PATH,
} from "../../Constants/Paths";
import SideBar from "../Sidebar";
import MyProfile from "../Forms/MyProfile";
import { useState } from "react";
import { grey } from "@mui/material/colors";
import { Global } from "@emotion/react";
import { useDispatch } from "react-redux";
import { ADD_USER } from "../../Redux/types";
import useFinch from "../../ApiHooks/useFinch";
import useFranchises from "../../ApiHooks/useFranchise";

const drawerWidth = 240;
const drawerBleeding = 56;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export default function PersistentDrawerLeft(props) {
  const { fetchLoading: finchLoading, getAllPartnerFinches } = useFinch();
  const { loading: franchiseLoading, getFranchises } = useFranchises();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [swipeOpen, setSwipeOpen] = React.useState(false);
  const [userType, setUserType] = useState("Partner");
  const [checked, setChecked] = React.useState(false);
  const [campaignSidebar, setCampaignSidebar] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch({
      type: ADD_USER,
      payload: null,
    });
    navigate(SIGN_IN_PATH);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleSwipeDrawerOpen = () => {
    setSwipeOpen(!swipeOpen);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleRefresh = () => {
    try {
      switch (location.pathname) {
        case DASHBOARD_PATH:
          getAllPartnerFinches();
          break;
        case ADD_FRANCHISE_PATH:
          getFranchises();
          break;
        case SAVED_GOLD_FINCHES_PATH:
          getAllPartnerFinches();
          break;
      }
    } catch (err) {
      console.log(err, "ERROR");
    }
  };

  const isRefreshButton = () => {
    switch (location.pathname) {
      case ANALYTICS_PATH:
        return false;
      case BUY_PACKAGES_PATH:
        return false;

      default:
        return true;
    }
  };

  const isLoading = finchLoading || franchiseLoading;

  return (
    <Box>
      <CssBaseline />
      {!isSmallScreen ? (
        <AppBar
          position="fixed"
          open={open}
          sx={{
            backgroundColor: Theme.bg.white,
            "&.MuiAppBar-root": { boxShadow: "none" },
          }}
        >
          <div className="flex flex-row pr-10 gap-5">
            <div className="border-r-[2px] border-[#0000000F] p-6">
              <ButtonGroup
                size="small"
                aria-label="small button group"
                onClick={handleDrawerOpen}
                sx={{ border: `2px solid rgba(140, 151, 161, 0.23)` }}
              >
                <Button
                  key="one"
                  style={{
                    maxWidth: "10px",
                    maxHeight: "25px",
                    minWidth: "10px",
                    minHeight: "25px",
                    border: 0,
                    borderRadius: 0,
                    backgroundColor: !open ? "rgba(140, 151, 161, 0.23)" : null,
                  }}
                ></Button>
                <Button
                  key="two"
                  style={{
                    maxWidth: "10px",
                    maxHeight: "25px",
                    minWidth: "10px",
                    minHeight: "25px",
                    border: 0,
                    borderRadius: 0,
                    backgroundColor: open ? "rgba(140, 151, 161, 0.23)" : null,
                  }}
                ></Button>
              </ButtonGroup>
            </div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // flexWrap:"wrap",
                width: "100%",
              }}
            >
              {Boolean(isRefreshButton()) ? (
                <IconBtn
                  text="Refresh"
                  startIcon={<LuRefreshCw />}
                  variant={"contained"}
                  sx={{
                    backgroundColor: Theme.bg.greenBtn,
                    "&.MuiButton-root": {
                      "&:hover": {
                        backgroundColor: Theme.bg.greenBtn,
                        borderColor: Theme.bg.greenBtn,
                      },
                    },
                    py: 1,
                    px: 6,
                  }}
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={handleRefresh}
                />
              ) : (
                <div style={{ flex: 1 }} />
              )}
              {userType === "Partner" ? (
                <IconBtn
                  onClick={() => setCampaignSidebar(true)}
                  text="My Profile"
                  startIcon={<BsFillPersonFill />}
                  variant={"contained"}
                  sx={{
                    backgroundColor: Theme.bg.yellowBtn,
                    "&.MuiButton-root": {
                      "&:hover": {
                        backgroundColor: Theme.bg.yellowBtn,
                        borderColor: Theme.bg.yellowBtn,
                      },
                    },
                    py: 1,
                    px: 6,
                    color: Theme.color.basic,
                    fontFamily: "Urbanist",
                  }}
                />
              ) : null}
            </Box>
          </div>
        </AppBar>
      ) : (
        <AppBar
          position="fixed"
          open={swipeOpen}
          sx={{
            backgroundColor: Theme.bg.white,
            "&.MuiAppBar-root": { boxShadow: "none" },
          }}
        >
          <Box className="flex flex-row pr-10 gap-5">
            <Box sx={{ py: 2, px: 1 }}>
              <IconButton aria-label="delete" onClick={handleSwipeDrawerOpen}>
                <RxHamburgerMenu style={{ color: Theme.color.basic }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: Theme.color.basic,
                  fontFamily: "Urbanist-Medium",
                  fontSize: "24px",
                }}
              >
                {/* Statistics */}
              </Typography>
            </Box>
            {userType === "Partner" ? (
              <IconBtn
                onClick={() => setCampaignSidebar(true)}
                text="My Profile"
                startIcon={<BsFillPersonFill />}
                variant={"contained"}
                sx={{
                  backgroundColor: Theme.bg.yellowBtn,
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.yellowBtn,
                      borderColor: Theme.bg.yellowBtn,
                    },
                  },
                  py: 1,
                  px: 2,
                  color: Theme.color.basic,
                  fontFamily: "Urbanist",
                  minWidth: 150,
                  maxHeight: 40,
                  mt: 2,
                }}
                size="small"
              />
            ) : null}
          </Box>
        </AppBar>
      )}
      {!isSmallScreen ? (
        <Drawer
          sx={{
            width: open ? drawerWidth : 0,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? drawerWidth : 0,
              boxSizing: "border-box",
            },
            "& .MuiDrawer-root": {},
          }}
          variant={"persistent"}
          // anchor="left"
          open={open}
        >
          <DrawerHeader
            sx={{
              backgroundColor: Theme.color.basic,
              py: 6,
              px: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRight: "none !important",
              height: "auto !important",
            }}
          >
            <img
              src={LOGO_IMG}
              alt=""
              style={{ width: "50% !important", height: "auto" }}
            />
          </DrawerHeader>
          <List
            sx={{
              backgroundColor: Theme.color.basic,
              height: "100% !important",
            }}
          >
            {ROUTES.map((v, i) => (
              <NavLink
                to={v.path}
                style={{ textDecoration: "none" }}
                activeClassName="active-link"
                key={i}
              >
                {v.userType === userType ? (
                  <ListItem
                    key={v.title}
                    disablePadding
                    sx={{ my: userType === "Admin" ? 1 : 2 }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        display: "flex",
                        justifyContent: open ? "initial" : "center",
                        gap: 2,
                        // px: 4,
                        backgroundColor:
                          location.pathname === v.path
                            ? Theme.bg.yellow
                            : Theme.bg.basic,
                        "&:hover": {
                          backgroundColor:
                            location.pathname === v.path
                              ? Theme.bg.yellow
                              : Theme.bg.primary,
                        },
                        mx: 1.5,
                        borderRadius: "8px",
                      }}
                    >
                      <Avatar
                        alt={v.title}
                        src={
                          location.pathname === v.path ? v.iconBlack : v.icon
                        }
                        sx={{ borderRadius: 0, height: "20px", width: "20px" }}
                        // className={location.pathname === v.path ? 'active-avatar' : 'inactive-avatar'}
                      />
                      <ListItemText
                        primary={v.title}
                        sx={{
                          opacity: open ? 1 : 0,
                          color:
                            location.pathname === v.path
                              ? Theme.color.basic
                              : Theme.color.white,
                          fontWeight:
                            location.pathname === v.path ? "bold" : "400",
                        }}
                        primaryTypographyProps={{
                          style: {
                            fontWeight:
                              location.pathname === v.path ? "bolder" : "400",
                          },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : null}
              </NavLink>
            ))}
          </List>
          <DrawerHeader
            sx={{
              backgroundColor: Theme.color.basic,
              paddingY: userType === "partner" ? 6 : 2,
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              gap: 2,
              // borderRight: "none !important",
              // height: "auto !important",
            }}
          >
            {userType === "Partner" ? (
              <IconBtn
                text="Buy Packages"
                startIcon={<TbPackage />}
                variant={"contained"}
                fullWidth
                onClick={() => {
                  navigate("/buy-packages");
                }}
                sx={{
                  backgroundColor: Theme.bg.greenBtn,
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.greenBtn,
                      borderColor: Theme.bg.greenBtn,
                    },
                  },
                  py: 1,
                  px: 6,
                  color: Theme.color.white,
                  fontWeight: "bold",
                }}
              />
            ) : null}
            <IconBtn
              onClick={handleLogout}
              text="Logout"
              startIcon={<IoLogOut />}
              variant={"contained"}
              fullWidth
              sx={{
                backgroundColor: Theme.bg.redBtn,
                "&.MuiButton-root": {
                  "&:hover": {
                    backgroundColor: Theme.bg.redBtn,
                    borderColor: Theme.bg.redBtn,
                  },
                },
                py: 1,
                px: 6,
                color: Theme.color.white,
                fontWeight: "bold",
              }}
            />
          </DrawerHeader>
        </Drawer>
      ) : (
        <Drawer
          anchor={"left"}
          open={swipeOpen}
          onClose={handleSwipeDrawerOpen}
        >
          <DrawerHeader
            sx={{
              backgroundColor: Theme.color.basic,
              py: 6,
              px: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRight: "none !important",
              height: "auto !important",
            }}
          >
            <img
              src={LOGO_IMG}
              alt=""
              style={{ width: "50% !important", height: "auto" }}
            />
          </DrawerHeader>
          <List
            sx={{
              backgroundColor: Theme.color.basic,
              height: "100% !important",
            }}
          >
            {ROUTES.map((v, i) => (
              <NavLink
                to={v.path}
                style={{ textDecoration: "none" }}
                activeClassName="active-link"
                key={i}
                onClick={handleSwipeDrawerOpen}
              >
                {v.userType === userType ? (
                  <ListItem
                    key={v.title}
                    disablePadding
                    sx={{ my: userType === "Admin" ? 1 : 2 }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        display: "flex",
                        justifyContent: open ? "initial" : "center",
                        gap: 2,
                        // px: 4,
                        backgroundColor:
                          location.pathname === v.path
                            ? Theme.bg.yellow
                            : Theme.bg.basic,
                        "&:hover": {
                          backgroundColor:
                            location.pathname === v.path
                              ? Theme.bg.yellow
                              : Theme.bg.primary,
                        },
                        mx: 1.5,
                        borderRadius: "8px",
                        fontWeight:
                          location.pathname === v.path ? "bold" : "400",
                      }}
                      style={{
                        fontWeight:
                          location.pathname === v.path ? "bold" : "400",
                      }}
                    >
                      <Avatar
                        alt={v.title}
                        src={
                          location.pathname === v.path ? v.iconBlack : v.icon
                        }
                        sx={{ borderRadius: 0, height: "20px", width: "20px" }}
                        style={{
                          fontWeight:
                            location.pathname === v.path ? "bold" : "400",
                        }}
                        // className={location.pathname === v.path ? 'active-avatar' : 'inactive-avatar'}
                      />
                      <ListItemText
                        primary={v.title}
                        sx={{
                          opacity: open ? 1 : 0,
                          color:
                            location.pathname === v.path
                              ? Theme.color.basic
                              : Theme.color.white,
                        }}
                        style={{
                          fontWeight:
                            location.pathname === v.path ? "bold" : "400",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : null}
              </NavLink>
            ))}
          </List>
          <DrawerHeader
            sx={{
              backgroundColor: Theme.color.basic,
              paddingY: userType === "partner" ? 6 : 2,
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              gap: 2,
              // borderRight: "none !important",
              // height: "auto !important",
            }}
          >
            {userType === "Partner" ? (
              <IconBtn
                text="Buy Packages"
                startIcon={<TbPackage />}
                variant={"contained"}
                fullWidth
                onClick={() => {
                  navigate("/buy-packages");
                  handleSwipeDrawerOpen();
                }}
                sx={{
                  backgroundColor: Theme.bg.greenBtn,
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.greenBtn,
                      borderColor: Theme.bg.greenBtn,
                    },
                  },
                  py: 1,
                  px: 6,
                  color: Theme.color.white,
                  fontWeight: "bold",
                }}
              />
            ) : null}
            <IconBtn
              onClick={handleLogout}
              text="Logout"
              startIcon={<IoLogOut />}
              variant={"contained"}
              fullWidth
              sx={{
                backgroundColor: Theme.bg.redBtn,
                "&.MuiButton-root": {
                  "&:hover": {
                    backgroundColor: Theme.bg.redBtn,
                    borderColor: Theme.bg.redBtn,
                  },
                },
                py: 1,
                px: 6,
                color: Theme.color.white,
              }}
            />
          </DrawerHeader>
        </Drawer>
      )}
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: Theme.bg.bg,
          marginLeft: open && !isSmallScreen ? "240px" : 0, // Adjust marginLeft when the drawer is open
          transition: "margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
          p: 1, // Add padding to the content area,
          height: "100vh",
        }}
      >
        <DrawerHeader />
        <Outlet context={[finchLoading]} />
        <SideBar
          open={campaignSidebar}
          onClose={() => setCampaignSidebar(false)}
          title={selectedId ? "Edit Client" : "Profile Details"}
          form={
            <MyProfile
              id={selectedId}
              // onEdit={getAllClients}
              onClose={() => setCampaignSidebar(false)}
            />
          }
        />
      </Box>
    </Box>
  );
}
