import { Box, Grid, InputLabel, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { Theme } from "../../Theme";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import SimpleSelect from "../../Components/Inputs/SimpleSelect";
import "./index.css";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import "../../App.css";

const PrivacyPolicy = () => {
  const initialText = `Goldfinch Inc. 
("Goldfinch," "we," "us," or "our") values the privacy of its users ("you" or "your"). This Privacy Policy explains how we collect, use, and share information through our Goldfinch mobile application (the "App"). By using the App, you agree to the terms of this Privacy Policy.
  
Information We Collect
a. Personal Information: We may collect personal information such as your full name, email address, phone number, gender, date of birth, and password when you create an account with the App.
b. Location Information: We use your device's GPS or location services to determine your location to provide you with the best deals and discounts near you. By using the App, you agree to allow us to collect and use your location information.
  
How We Use Your Information
a. Personal Information: We use your personal information to create and manage your account, provide you with the App's services, and communicate with you.
b. Location Information: We use your location information to provide you with the best deals and discounts near you and to personalize your experience.
  
Sharing of Information
a. Third-Party Service Providers: We may share your personal information and location information with third-party service providers who assist us in providing the App's services. These service providers are bound by strict confidentiality agreements and are only allowed to use your information in accordance with our instructions.
b. Legal Compliance: We may disclose your personal information and location information if required by law or if we believe that such disclosure is necessary to comply with legal process, to protect the rights and safety of Goldfinch or others, or to enforce our terms and conditions.
  
Data Security
We take reasonable measures to protect your personal information and location information from unauthorized access, use, or disclosure. However, no method of electronic storage or transmission is 100% secure, and we cannot guarantee the absolute security of your information.

Deletion of Information
If you delete your account, we will remove your personal information and location information from our database. However, some information may remain in our archives for the purpose of compliance with legal obligations or for our internal record keeping.

Changes to This Privacy Policy
We may update this Privacy Policy from time to time to reflect changes in our practices or the law. Your continued use of the App constitutes your acceptance of any changes to this Privacy Policy.

Contact Us
If you have any questions or concerns about this Privacy Policy or our data collection and use practices, please contact us at [admin@thegoldfinch.app]. This Privacy Policy is governed by the laws of Canada. Last updated: 25, February 2023.`;

  const initialEditorState = EditorState.createWithContent(
    ContentState.createFromText(initialText)
  );
  const [privacyData, setPrivacyData] = useState(initialText);
  const [editorState, setEditorState] = useState(initialEditorState);
  // const [editorState, setEditorState] = useState(EditorState?.createEmpty());
  return (
    <Box sx={{ p: 1, backgroundColor: Theme.bg.bg }}>
      <Box sx={{ my: 3 }}>
        <Typography
          sx={{
            fontFamily: "Urbanist-Bold",
            fontSize: { xs: "28px", sm: "28px", md: "34px" },
          }}
        >
          Update Privacy Policy
        </Typography>
      </Box>
      <Box sx={{ mb: 4 }}>
        <Paper sx={{ py: 4, px: 2 }}>
          <Box sx={{ mb: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  htmlFor="bootstrap-input"
                  sx={{ fontFamily: "Urbanist-Bold", color: Theme.color.basic }}
                >
                  Type
                </InputLabel>
                <SimpleSelect
                  size="small"
                  // options={options}
                  fullWidth
                  label={"User"}
                  style={{
                    px: 10,
                    backgroundColor: Theme.bg.bg,
                    border: "1px solid #E7EAEE",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  htmlFor="bootstrap-input"
                  sx={{ fontFamily: "Urbanist-Bold", color: Theme.color.basic }}
                >
                  Title
                </InputLabel>
                <SimpleSelect
                  size="small"
                  // options={options}
                  fullWidth
                  label={"Input Title"}
                  style={{
                    px: 10,
                    backgroundColor: Theme.bg.bg,
                    border: "1px solid #E7EAEE",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Editor
            editorState={editorState}
            spellCheck={true}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="my-editor-className"
            onEditorStateChange={setEditorState}
          />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <PrimaryButton
              text={"SEND"}
              //   onClick={handleResetPassword}
              // fullWidth
              sx={{
                color: Theme.color.basic,
                backgroundColor: Theme.bg.yellow,
                borderRadius: "8px",
                // my: 6,
                mt: 4,
                width: "600px",
                fontSize: "18px",
                "&.MuiButton-root": {
                  "&:hover": {
                    backgroundColor: Theme.bg.yellow,
                    borderColor: Theme.bg.yellow,
                    boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                  },
                },
                boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                fontFamily: "Urbanist-Bold",
              }}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
