import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "./TablePagination";

const StickyHeadTable = ({
  columns,
  rows,
  previous,
  next,
  loading,
  columnsFilter,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  return (
    <div
      sx={{
        width: "100%",
        minWidth: "290px",
        overflow: "hidden",
        borderRadius: "5px",
      }}
    >
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          cellSpacing={10}
          hideSortIcon={true}
        >
          <TableHead>
            <TableRow>
              {columns
                .filter((v) =>
                  Boolean(columnsFilter)
                    ? columnsFilter.includes(v.stringLabel)
                    : true
                )
                .map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      width: column.width || null,
                      fontSize: column.fontSize,
                      fontWeight: column.fontWeight,
                      marginBottom: "20px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#fff" }}>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns
                      .filter((v) =>
                        Boolean(columnsFilter)
                          ? columnsFilter.includes(v.stringLabel)
                          : true
                      )
                      .map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            // style={{ borderBottom: "none" }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        data={rows}
        itemsPerPage={rowsPerPage}
        previous={previous}
        next={next}
        count={rows?.length}
        loading={loading}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};
export default StickyHeadTable;
