function getMimeType(mimeType) {
  const mimeMap = {
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/gif": "gif",
    "image/svg+xml": "svg",
    "text/plain": "txt",
    "application/pdf": "pdf",
    // Add more MIME types and extensions as needed
  };

  // Default to 'unknown' if no match is found
  return mimeMap[mimeType] || "unknown";
}

export default getMimeType;
