import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import SimpleInput from "../Inputs/SimpleInput";
import IconInput from "../Inputs/IconInput";
import GoogleMap from "../GoogleMap/GoogleMap";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyDropzone from "../ImageUploader/MyDropzone";
import DatePickerInput from "../Inputs/DatePickerInput";
import { AiFillExclamationCircle } from "react-icons/ai";

const CreateGoldFinch = () => {

  const [loading, setLoading] = useState("");
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  return (
    <Box sx={{ p: 3, pl: 5, pr: 5, pt: 2 }}>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Name"
            fullWidth
            // required
            placeholder={"Input your name"}
            // defaultValue="Input your name"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="GoldFinch's Quantity"
            fullWidth
            // required
            placeholder={"Unlimited"}
            // defaultValue="Unlimited"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Discount Percentage (%)"
            fullWidth
            // required
            placeholder={"eg: 10"}
            // defaultValue="eg: 10"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DatePickerInput
            label="Activation Date & Time"
            value="Select date and time"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <DatePickerInput
            label="End Date & Time"
            value="Select date and time"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Campaign Duration"
            fullWidth
            // required
            placeholder={""}
            // defaultValue=""
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <DatePickerInput label="Opening time" value="--:-- --" />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <DatePickerInput label="Closing time" value="--:-- --" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid>
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ mb: 0.7, color: "#363636" }}
          >
            {"Day to Skip"}{" "}
            <Tooltip title="Delete">
              <IconButton>
                <AiFillExclamationCircle size={15} color="#0088FF" />
              </IconButton>
            </Tooltip>
          </InputLabel>
        </Grid>
        <Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ mb: 2 }}
          >
            <Grid item xs="auto">
              <PrimaryButton
                text={"Sunday"}
                sx={{
                  color: Theme.color.basic,
                  backgroundColor: Theme.bg.yellow,
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.yellow,
                      borderColor: Theme.bg.yellow,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <PrimaryButton
                text={"monday"}
                sx={{
                  color: Theme.color.basic,
                  backgroundColor: Theme.bg.yellow,
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.yellow,
                      borderColor: Theme.bg.yellow,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <PrimaryButton
                text={"tuesday"}
                sx={{
                  color: Theme.color.basic,
                  backgroundColor: Theme.bg.yellow,
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.yellow,
                      borderColor: Theme.bg.yellow,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <PrimaryButton
                text={"Wednesday"}
                sx={{
                  color: Theme.color.basic,
                  backgroundColor: Theme.bg.yellow,
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.yellow,
                      borderColor: Theme.bg.yellow,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <PrimaryButton
                text={"Thursday"}
                sx={{
                  color: Theme.color.basic,
                  backgroundColor: Theme.bg.yellow,
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.yellow,
                      borderColor: Theme.bg.yellow,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <PrimaryButton
                text={"Friday"}
                sx={{
                  color: Theme.color.basic,
                  backgroundColor: Theme.bg.yellow,
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.yellow,
                      borderColor: Theme.bg.yellow,
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <PrimaryButton
                text={"Saturday"}
                sx={{
                  color: Theme.color.basic,
                  backgroundColor: Theme.bg.yellow,
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.yellow,
                      borderColor: Theme.bg.yellow,
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <SimpleInput
            label="Notification Message"
            fullWidth
            // required
            placeholder={"Enter Notification Message"}
            // defaultValue=
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <SimpleInput
            label="Description"
            fullWidth
            // required
            multiline
            minRows={3}
            placeholder={"Short Description for your offer"}
            // defaultValue=
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            error
            id="outlined-select-categories"
            select
            label="Select Categories"
            defaultValue="Select..."
            helperText="categories field must have at least 1 items"
          ></SimpleInput>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            error
            id="outlined-select-categories"
            select
            label="Select Franchises"
            defaultValue="Select..."
            helperText="franchises field must have at least 1 items"
          ></SimpleInput>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <MyDropzone
            label="Upload Image"
            value={selectedFiles}
            onChange={setSelectedFiles}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <PrimaryButton
            text={"SAVE AS DRAFT"}
            elevation={3}
            size="large"
            fullWidth
            sx={{
              color: Theme.color.white,
              backgroundColor: Theme.bg.darkgrey,
              "&.MuiButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.darkgrey,
                  borderColor: Theme.bg.darkgrey,
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <PrimaryButton
            text={"SCHEDULE"}
            elevation={3}
            size="large"
            fullWidth
            sx={{
              color: Theme.color.basic,
              backgroundColor: Theme.bg.yellow,
              boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              "&.MuiButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.yellow,
                  borderColor: Theme.bg.yellow,
                  boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateGoldFinch;
