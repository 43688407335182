import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import IconFeather from "../../Assets/SVG/Iconfeather.svg";
import { BsSearch } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import {
  Box,
  Stack,
  IconButton as IconBtn,
  Typography,
  Grid,
  IconButton,
  Button,
  useMediaQuery,
  Paper,
  CircularProgress,
} from "@mui/material";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StickyHeadTable from "../../Components/Table/index.jsx";
import { styled } from "@mui/material/styles";
import { VscEdit } from "react-icons/vsc";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiAddToQueue } from "react-icons/bi";
import { HiOutlineArchiveBox } from "react-icons/hi2";
import { RiArrowDropUpFill } from "react-icons/ri";
import { RiArrowDropDownFill } from "react-icons/ri";
import { Theme } from "../../Theme";
import GradientBox from "../../Components/GradientBox";
import SideBar from "../../Components/Sidebar";
import AddFranchiseForm from "../../Components/Forms/AddFranchiseForm";
import IconFeather_IMG from "../../Assets/SVG/Iconfeather.svg";
import ExpandableSearchBar from "../../Components/Inputs/ExpandableSearchBar";
import ColumnMenu from "../../Components/Menu/ColumnMenu";
import "../../App.css";
import ColumnButton from "../../Components/Buttons/ColumnButton";
import SimpleMenu from "../../Components/Menu";
import { useSelector } from "react-redux";
import useFranchises from "../../ApiHooks/useFranchise";
import moment from "moment";
import usePayment from "../../ApiHooks/usePayment.js";
import tooltipWrapper from "../../Utils/tooltipWrapper.js";
const titleLiner = (title) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
      {title}
    </Typography>
    {/* <Stack sx={{ m: 0, p: 0 }}>
      <RiArrowDropUpFill style={{ marginBottom: -5 }} />
      <RiArrowDropDownFill style={{ marginTop: -5 }} />
    </Stack> */}
  </Box>
);

const columns = [
  {
    id: "title",
    label: titleLiner("Title"),
    minWidth: 150,
    fontSize: "18px",
    fontWeight: 600,
    stringLabel: "Title",
  },
  {
    id: "location",
    label: titleLiner("Location"),
    minWidth: 200,
    fontSize: "18px",
    fontWeight: 600,
    stringLabel: "Location",
  },
  {
    id: "renewalDate",
    label: titleLiner("Renewal Date"),
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
    fontSize: "18px",
    fontWeight: 600,
    stringLabel: "Renewal Date",
  },
  {
    id: "actions",
    label: titleLiner("Actions"),
    minWidth: 10,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
    fontSize: "18px",
    fontWeight: 600,
    stringLabel: "Actions",
  },
  {
    id: "active",
    label: "Activate/Deactivate",
    minWidth: 10,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
    fontSize: "18px",
    fontWeight: 600,
    stringLabel: "Activate/Deactivate",
  },
];

const options = [
  "Title",
  "Location",
  "Renewal Date",
  "Actions",
  "Activate/Deactivate",
];

const AddFranchise = () => {
  const { editFranchiseStatus, loading } = useFranchises();
  const partnerFranchises = useSelector(
    (state) => state.partnerFranchisesReducer.partnerFranchises
  );
  const { getPackageDetails } = usePayment();
  const packageDetails = useSelector(
    (state) => state.paymentReducer.packageDetails
  );
  const [optionsSelected, setOptionSelected] = useState(options);
  const [franchisesData, setFranchisesData] = useState({});
  const [addFranchiseForm, setAddFranchiseForm] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [searchText, setSearchText] = useState("");
  const menuOpen2 = Boolean(anchorEl2);
  const handleMenuClick = (event, state) => {
    if (state === "filter") {
      setAnchorEl1(event.currentTarget);
    } else if (state === "column") {
      setAnchorEl2(event.currentTarget);
    }
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const textLiner = (color, fontSize, text, bgColor = null) => {
    return (
      <Typography
        sx={{
          color: color,
          fontSize: fontSize,
          backgroundColor: bgColor,
          borderRadius: "4px",
          fontFamily: "Urbanist-Semibold",
        }}
      >
        {text}
      </Typography>
    );
  };

  const handleChange = (event, id, value) => {
    editFranchiseStatus({
      franchiseId: id,
      isActive: !value,
    });
  };

  const handleEditAction = (id) => {
    setSelectedId(id);
    setAddFranchiseForm(true);
  };

  useEffect(() => {
    // console.log("USE_EFECT");
    const filteredItems = partnerFranchises.filter((item) =>
      item?.name?.toLowerCase().includes(searchText.toLowerCase())
    );
    let userData = filteredItems?.map((v) => {
      return {
        title: textLiner(Theme.color.text, "18px", v.name),
        location: textLiner(Theme.color.text, "18px", v.address),
        renewalDate: textLiner(
          Theme.color.text,
          "18px",
          moment(v?.validTill?._seconds * 1000).format("MMM-DD-YYYY")
        ),
        actions: (
          <Stack direction="row" spacing={1} justifyContent="center">
            <IconBtn
              aria-label="flag"
              disableElevation={true}
              disableRipple
              sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
              onClick={(e) => handleEditAction(v.franchiseId)}
            >
              <VscEdit color={Theme.color.white} />
              {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
            </IconBtn>
          </Stack>
        ),
        active: (
          <Switch
            checked={v.isActive}
            onClick={(e) => handleChange(e, v.franchiseId, v.isActive)}
          />
        ),
      };
    });

    //static pagination
    let franchisesData = {
      count: userData?.length,
      next: 2,
      previous: null,
      results: userData,
    };

    setFranchisesData(franchisesData);
  }, [addFranchiseForm, loading, searchText, partnerFranchises?.length]);

  const handleSidebarClose = (e) => {
    setAddFranchiseForm(false);
    setSelectedId(null);
  };

  const handleColumnSelected = (column, isChecked) => {
    if (isChecked) {
      // Add column to visibleColumns if checked
      setOptionSelected((prevVisibleColumns) => [
        ...prevVisibleColumns,
        column,
      ]);
    } else {
      // Remove column from visibleColumns if unchecked
      setOptionSelected((prevVisibleColumns) =>
        prevVisibleColumns.filter((item) => item !== column)
      );
    }
  };
  return (
    <Box sx={{ p: 1, height: "auto" }}>
      <Box sx={{ mt: 3 }}>
        <Grid container>
          <Grid item xs={12}>
            <GradientBox
              text={"Remaining Franchise"}
              count={packageDetails?.franchise}
            />
          </Grid>
        </Grid>
        {isSmallScreen ? (
          <Paper
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              my: 3,
              px: 1,
              py: 2,
              gap: 2,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 2, sm: 3, md: 5 },
                justifyContent: {
                  xs: "space-between",
                  sm: "space-between",
                  md: "flex-start",
                },
                width: "100%",
              }}
            >
              <Typography
                sx={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}
              >
                Franchises List
              </Typography>
              {/* <img src={IconFeather} alt="" /> */}
            </Box>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 2, sm: 3, md: 5 },
                justifyContent: {
                  xs: "space-between",
                  sm: "space-between",
                  md: "flex-start",
                },
                width: "100%",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {/* <IconButton aria-label="search">
                  <BsSearch />
                </IconButton> */}
                {/* <ExpandableSearchBar /> */}
              </Grid>
              <Grid item xs={12}>
                {tooltipWrapper(
                  <Button
                    disabled={packageDetails?.franchise <= 0}
                    variant="contained"
                    style={{ backgroundColor: "#5BC0DE" }}
                    startIcon={<AiOutlinePlus />}
                    onClick={() => setAddFranchiseForm(true)}
                    fullWidth
                  >
                    {" "}
                    Add Franchise
                  </Button>,
                  packageDetails?.franchise <= 0,
                  "Subscribe to a franchise package to create Franchises."
                )}
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              my: 3,
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
              <Typography
                sx={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}
              >
                Franchises List
              </Typography>

              <ColumnButton
                onClick={(e) => {
                  handleMenuClick(e, "column");
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                justifyContent: {
                  xs: "space-between",
                  sm: "space-between",
                  md: "none",
                  // width: "100%",
                },
                pl: 2,
              }}
            >
              <ExpandableSearchBar
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {tooltipWrapper(
                <Button
                  disabled={packageDetails?.franchise <= 0}
                  variant="contained"
                  style={{ backgroundColor: "#5BC0DE" }}
                  startIcon={<AiOutlinePlus />}
                  onClick={() => setAddFranchiseForm(true)}
                >
                  Add Franchise
                </Button>,
                packageDetails?.franchise <= 0,
                "Subscribe to a franchise package to create Franchises."
              )}
            </Box>
          </Box>
        )}
        {loading && !addFranchiseForm && !franchisesData?.results?.length ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <StickyHeadTable
            columns={columns}
            rows={franchisesData.results || []}
            columnsFilter={optionsSelected}
          />
        )}
      </Box>
      <SimpleMenu
        handleClose={handleClose2}
        open={menuOpen2}
        anchorEl={anchorEl2}
        data={
          <ColumnMenu
            selectedId={selectedId}
            handleOptionClick={handleColumnSelected}
            options={options}
            optionsSelected={optionsSelected}
          />
        }
      />
      {addFranchiseForm && (
        <SideBar
          open={addFranchiseForm}
          onClose={handleSidebarClose}
          title={selectedId ? "Update Location" : "Adding New Location"}
          form={
            <AddFranchiseForm
              id={selectedId}
              // onEdit={getAllClients}
              onClose={handleSidebarClose}
            />
          }
        />
      )}
    </Box>
  );
};

export default AddFranchise;
