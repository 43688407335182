import { Box, Typography } from "@mui/material";
import React from "react";
import "../../App.css";

const ViewFeedback = () => {
  return (
    <Box sx={{display:"flex",flexDirection:"column",gap:1}}>
      <Typography sx={{ fontSize: "22px", fontFamily: "Urbanist-Bold" }}>
        User Feedback
      </Typography>
      <Box sx={{ backgroundColor: "rgba(41, 41, 41, 0.05)",px:2,py:4}}>
        <Typography sx={{textAlign:"center"}}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque,
          repellendus magni, cupiditate architecto ipsam fuga consequuntur harum
          velit autem illo quas accusamus ullam eveniet quis optio voluptates
          quisquam perferendis magnam!
        </Typography>
      </Box>
    </Box>
  );
};

export default ViewFeedback;
