import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import SimpleSelect from "../Inputs/SimpleSelect";
import { Theme } from "../../Theme";
import "../../App.css";

export default function SimpleMenu({
  handleClose,
  open,
  anchorEl,
  data,
}) {
  return (
    <Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {data}
      </Menu>
    </Box>
  );
}
