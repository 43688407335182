import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../Utils/useFetch";
import { GET_CATEGORIES_API } from "../Constants/APIs";
import { ADD_CATEGORIES } from "../Redux/types";

const useCategories = () => {
  const { request } = useFetch();
  const categories = useSelector((state) => state.categoryReducer.categories);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const getCategories = async () => {
    try {
      setLoading(true);
      const req = await request(GET_CATEGORIES_API, "GET");
      dispatch({
        type: ADD_CATEGORIES,
        payload: req?.data?.data,
      });
    } catch (err) {
      console.log(err, "err.message");
    } finally {
      setLoading(false);
    }
  };

  return {
    getCategories,
    categories,
  };
};

export default useCategories;
