import { Box, Grid, InputLabel, Paper, Typography } from "@mui/material";
import React from "react";
import { Theme } from "../../Theme";
import SimpleSelect from "../../Components/Inputs/SimpleSelect";
import "../../App.css";
import DatePickerInput from "../../Components/Inputs/DatePickerInput";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
const FreeRewards = () => {
  return (
    <Box sx={{ p: 1, backgroundColor: Theme.bg.bg, height: "100vh" }}>
      <Box sx={{ my: 3 }}>
        <Typography style={{ fontFamily: "Urbanist-Bold",fontSize:"32px" }}>
          Free Rewards
        </Typography>
      </Box>
      <Box>
        <Paper sx={{ py: 2, px: 2 }}>
          <Grid container spacing={2} sx={{ my: 1 }}>
            <Grid item xs={12} sm={6}>
              <InputLabel
                htmlFor="bootstrap-input"
                sx={{ fontFamily: "Urbanist-Bold", color: Theme.color.basic }}
              >
                Select Partner
              </InputLabel>
              <SimpleSelect
                size="medium"
                // options={options}
                fullWidth
                label={"Select Partners"}
                style={{
                  px: 10,
                  backgroundColor: Theme.bg.bg,
                  border: "1px solid #E7EAEE",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel
                htmlFor="bootstrap-input"
                sx={{ fontFamily: "Urbanist-Bold", color: Theme.color.basic }}
              >
                Reward Type
              </InputLabel>
              <SimpleSelect
                size="medium"
                // options={options}
                fullWidth
                label={"Select Reward Type"}
                style={{
                  px: 10,
                  backgroundColor: Theme.bg.bg,
                  border: "1px solid #E7EAEE",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ my: 1 }}>
            <Grid item xs={12} sm={6}>
              <InputLabel
                htmlFor="bootstrap-input"
                sx={{ fontFamily: "Urbanist-Bold", color: Theme.color.basic }}
              >
                Start Date
              </InputLabel>
              <DatePickerInput
                // label="End Date & Time"
                value="Select date and time"
                simple
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel
                htmlFor="bootstrap-input"
                sx={{ fontFamily: "Urbanist-Bold", color: Theme.color.basic }}
              >
                End Date
              </InputLabel>
              <DatePickerInput
                // label="End Date & Time"
                value="Select date and time"
                simple
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ my: 1, }}>
            <Grid item xs={12}>
              <InputLabel
                htmlFor="bootstrap-input"
                sx={{ fontFamily: "Urbanist-Bold", color: Theme.color.basic }}
              >
                Select Partner
              </InputLabel>
              <SimpleInput
                // label="Name"
                fullWidth
                // required
                placeholder={"Quantity"}
                // defaultValue="Input your name"
              />
            </Grid>
          </Grid>
          <Box sx={{display:"flex",justifyContent:'center'}}>
            <PrimaryButton
              text={"SEND FREE REWARDS"}
            //   onClick={handleResetPassword}
              // fullWidth
              sx={{
                color: Theme.color.basic,
                backgroundColor: Theme.bg.yellow,
                borderRadius: "8px",
                // my: 6,
                my: 4,
                width: "600px",
                fontSize: "18px",
                "&.MuiButton-root": {
                  "&:hover": {
                    backgroundColor: Theme.bg.yellow,
                    borderColor: Theme.bg.yellow,
                    boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                  },
                },
                boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                fontFamily: "Urbanist-Bold",
              }}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default FreeRewards;
