import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Paper, useMediaQuery } from "@mui/material";

const BasicModal = ({
  open,
  handleClose = () => {},
  data,
  styles,
  ...props
}) => {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "auto" : styles?.width || 600,
    // height:styles?.height,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    py: 4,
    px: isSmallScreen ? 8:styles?.px || 12,
  };
  return (
    <Box sx={{ m: isSmallScreen ? 1 : 0 }}>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={3} sx={style} {...props}>
          {data}
        </Paper>
      </Modal>
    </Box>
  );
};
export default BasicModal;
