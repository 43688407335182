import React from "react";
import usePrivacy from "../../ApiHooks/usePrivacy";
import { useSelector } from "react-redux";
import { CircularProgress, Typography } from "@mui/material";
import Logo from "../../Assets/Image/LogoBig.png";
function TermAndPrivacy() {
  const { loading } = usePrivacy();
  const privacy = useSelector((state) => state.userReducer.privacy);
  return (
    <div style={{ height: "100%", overflow: "auto", paddingBottom: 20 }}>
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 16px",
        }}
      >
        <img
          src={Logo}
          alt="session-logo"
          className="img-fluid"
          width="110"
          height="35"
        />
        <Typography variant="h3" style={{ color: "#363636" }}>
          Privacy Policy
        </Typography>
        <div></div>
      </div>
      <div className="theme-line-breaker" />
      <div style={{ margin: "4%" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />{" "}
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: privacy }}></div>
        )}
      </div>
    </div>
  );
}

export default TermAndPrivacy;
