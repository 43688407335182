import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import SimpleInput from "../Inputs/SimpleInput";
import GoogleMap from "../GoogleMap/GoogleMap";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";
import SliderComp from "../Slider";
import { OpeningHours } from "../Inputs/OpeningHours";
import LocationInput from "../Inputs/LocationInput";
import useFranchises from "../../ApiHooks/useFranchise";
import { useSelector } from "react-redux";
const AddFranchiseForm = ({ id, onClose = () => null }) => {
  const partner = useSelector((state) => state.userReducer.partner);
  const partnerData = useSelector((state) => state.userReducer.user);

  const franchiseData = useSelector((state) =>
    state.partnerFranchisesReducer.partnerFranchises?.find(
      (v) => v.franchiseId === id
    )
  );
  const { addFranchise, loading, errors, editFranchise } = useFranchises();
  const [fullAddress, setFullAddress] = useState();
  const [address, setAddress] = useState("");
  const [matchRange, setMatchRange] = useState(3000);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [openingHoursObj, setOpeningHoursObj] = useState({});
  const [name, setName] = useState("");
  const getAddress = (address, lat, lng) => {
    if (lat && lng) {
      setLatLng(lat, lng);
    }
    if (address.length > 50) {
      setAddress(address.substr(0, 50) + "...");
      setFullAddress(address);
    } else {
      setAddress(address);
      setFullAddress(address);
    }
  };

  function setLatLng(lat, lng) {
    setLatitude("");
    setLongitude("");
    setLatitude(lat);
    setLongitude(lng);
  }

  const handleSubmit = () => {
    const obj = {
      name,
      address,
      latitude,
      longitude,
      restaurantId: partner?.uid,
      openingHours: openingHoursObj,
      matchRange: matchRange,
    };

    addFranchise(obj, () => handleClose());
  };

  const handleClose = (noClose = false) => {
    setName("");
    setLatitude("");
    setLongitude("");
    setAddress("");
    setMatchRange(3000);
    setFullAddress("");
    setOpeningHoursObj({});
    console.log(noClose, "noClose");
    !noClose && onClose();
  };
  const handleEdit = () => {
    const obj = {
      name,
      address,
      latitude,
      longitude,
      restaurantId: partner?.uid,
      openingHours: openingHoursObj,
      matchRange: matchRange,
      franchiseId: id,
      geofenceId: franchiseData?.geofenceId,
    };
    console.log(obj, "OP_OBJ");
    editFranchise(obj);
  };
  useEffect(() => {
    if (franchiseData) {
      setName(franchiseData?.name);
      setLatitude(franchiseData?.latitude);
      setLongitude(franchiseData?.longitude);
      setAddress(franchiseData?.address);
      setMatchRange(franchiseData?.matchRange);
      setFullAddress(franchiseData?.address);
      setOpeningHoursObj(franchiseData?.openingHours);
    } else {
      Boolean(partnerData?.latitude && partnerData?.longitude) &&
        setLatLng(partnerData?.latitude, partnerData?.longitude);
    }
  }, [id, loading]);

  console.log("ERROR:", errors);
  return (
    <Box sx={{ p: 3, pl: 5, pr: 5, pt: 2 }}>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <SimpleInput
            label="Franchise Name"
            fullWidth
            placeholder={"For Ex: Montreal location"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={errors.name}
            helperText={errors.name}
            tooltipTitle="Enter the name of your franchise here. If you operate multiple locations under the same name, you may also include the location name for clarity. Please note that this name will be displayed to users on the front end."
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <LocationInput
            getAddress={getAddress}
            address={address}
            label="Location"
            fullWidth
            placeholder={"Location"}
            error={errors.location}
            helperText={errors.location}
            tooltipTitle="Type in the correct address of your physically available store."
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1, mt: 1 }}>
        <Grid item xs={12} sx={{ height: 250, mb: 3 }}>
          <GoogleMap
            style={{ height: 250, width: "89.5%" }}
            lat={latitude}
            lng={longitude}
            latitude={latitude}
            longitude={longitude}
            range={matchRange}
            getAddress={getAddress}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <SliderComp
            label={"Set Diameter"}
            sx={{ width: "100%" }}
            value={(matchRange || 3000) / 1000}
            onChange={(e, value) => setMatchRange(value * 1000)}
            tooltipTitle="Define your campaign's reach by setting a radius around your business location. This feature allows you to target customers in proximity to your business, ensuring your promotion connects with the most relevant audience. Simply select a distance, up to a maximum of 5 kilometers (km), to establish a precise local marketing area. Perfect for promoting offers, events, or announcements to customers nearby, a targeted radius aids in increasing foot traffic and boosting engagement with your business."
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <OpeningHours
            openingHoursObj={openingHoursObj}
            setOpeningHoursObj={setOpeningHoursObj}
            showAllDays={true}
            hasMarginTop={false}
            previousSavedData={franchiseData}
            error={errors?.openingHours}
            id={id}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={12}>
          <PrimaryButton
            text={id ? "UPDATED" : "CREATE"}
            elevation={3}
            size="large"
            fullWidth
            sx={{
              fontWeight: "bold",
              color: Theme.color.basic,
              backgroundColor: Theme.bg.yellow,
              boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              "&.MuiButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.yellow,
                  borderColor: Theme.bg.yellow,
                  boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                },
              },
            }}
            onClick={id ? handleEdit : handleSubmit}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddFranchiseForm;
