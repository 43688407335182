import * as React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";

export default function IconBtn({
  variant,
  text,
  startIcon = <DeleteIcon />,
  ...props
}) {
  return (
    <Button
      variant={variant}
      startIcon={startIcon}
      {...props}
      style={{ borderRadius: "8px" }}
    >
      {text}
    </Button>
  );
}
