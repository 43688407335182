export function getFinchConfig() {
  //hours, days, weeks, months, years
  const goldfinch_duration_type =
    process.env.REACT_APP_GOLDFINCH_DURATION_TYPE || "hrs";
  const goldfinch_duration_number =
    parseInt(process.env.REACT_APP_GOLDFINCH_DURATION_NUMBER || 1) || 1;

  let return_data = {
    goldfinch_duration_type: goldfinch_duration_type,
    goldfinch_duration_number: goldfinch_duration_number,
  };
  let text = "";
  if (goldfinch_duration_type === "hours") {
    return_data.hours = goldfinch_duration_number;
    text = "hour";
  } else if (goldfinch_duration_type === "days") {
    return_data.hours = goldfinch_duration_number * 24;
    text = "day";
  } else if (goldfinch_duration_type === "weeks") {
    return_data.hours = goldfinch_duration_number * 24 * 7;
    text = "week";
  } else if (goldfinch_duration_type === "months") {
    return_data.hours = goldfinch_duration_number * 24 * 30;
    text = "month";
  } else if (goldfinch_duration_type === "years") {
    return_data.hours = goldfinch_duration_number * 24 * 365;
    text = "year";
  }
  text += goldfinch_duration_number > 1 ? "s" : "";
  return_data.goldfinch_duration_text = goldfinch_duration_number + " " + text;
  return_data.goldfinch_duration_ignore_for_one_text =
    (goldfinch_duration_number > 1 ? goldfinch_duration_number + " " : "") +
    text;
  return_data.goldfinch_hours_text =
    return_data.hours + " hour" + (return_data.hours > 1 ? "s" : "");

  return return_data;
}

export default getFinchConfig;
