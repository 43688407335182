export const SIGN_IN_PATH = "/sign-in";
export const SIGN_UP_PATH = "/sign-up";
export const RESET_PATH = "/reset-password";
export const FORGOT_PATH = "/forgot-password";
export const DASHBOARD_PATH = "/";
export const SAVED_GOLD_FINCHES_PATH = "/saved-goldfinches";
export const ADD_FRANCHISE_PATH = "/add-franchise";
export const ANALYTICS_PATH = "/analytics";
export const BUY_PACKAGES_PATH = "/buy-packages";
export const SUBSCRIPTION_PATH = "/subscription";
export const ACCOUNT_REQUEST_PATH = "/account-requests";
export const ACTIVE_CAMPAIGNS_PATH = "/active-campaigns";
export const APP_USERS_PATH = "/users";
export const USERS_FEEDBACK_PATH = "/feedback";
export const CATEGORIES_LIST_PATH = "/categories";
export const FREE_REWARDS_PATH = "/free-rewards";
export const EMAIL_BROADCAST_PATH = "/email-broadcast";
export const PRIVACY_POLICY_PATH = "/privacy-policy";
export const VIEW_PROFILE_PATH = "/profile";
export const VERIFY_OTP_PATH = "/verify-otp";
export const TERMS_PRIVACY_PATH = "/terms-and-privacy";
