import { applyMiddleware, combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./Reducers/userReducer";
import partnerFinchesReducer from "./Reducers/partnerFinchesReducer";
import partnerFranchisesReducer from "./Reducers/partnerFranchisesReducer";
import categoryReducer from "./Reducers/categoryReducer";
import paymentReducer from "./Reducers/paymentReducer";
// import dashboardReducer from "./Reducers/dashboardReducer"

import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import localStorage from "redux-persist/es/storage";

const persistConfig = {
  key: "root",
  storage: localStorage,
};
let rootReducer = combineReducers({
  userReducer,
  partnerFinchesReducer,
  partnerFranchisesReducer,
  categoryReducer,
  paymentReducer,
  // dashboardReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
const persistor = persistStore(store);

export { store, persistor };
