import {
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { AiFillExclamationCircle } from "react-icons/ai";
import { Theme } from "../../Theme";
import UPLOAD_ICON from "./../../Assets/Icons/upload.png";
import SimpleInput from "../Inputs/SimpleInput";
import { CancelOutlined } from "@mui/icons-material";
function MyDropzone({
  label,
  required,
  simple,
  onChange,
  value,
  tooltipTitle,
  ...props
}) {
  console.log(value, "OPOPOP");
  const [error, setError] = useState("");
  const removeImage = (i) => {
    const arrNew = [...value];
    arrNew.splice(i, 1);
    console.log(arrNew, "arrNew");
    onChange(arrNew);
  };
  return (
    <>
      {label ? (
        <InputLabel
          htmlFor="outlined-adornment-password"
          sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
        >
          {label}
          {required ? (
            <span style={{ color: Theme.color.red }}>*</span>
          ) : simple ? null : (
            <Tooltip title={tooltipTitle}>
              <IconButton>
                <AiFillExclamationCircle size={15} color="#0088FF" />
              </IconButton>
            </Tooltip>
          )}
        </InputLabel>
      ) : null}
      <Dropzone
        accept={{
          "image/jpeg": [],
          "image/png": [],
        }}
        onDrop={(acceptedFiles) => {
          console.log(acceptedFiles, "OPOPOP");

          const finalArr = [...(value || []), ...(acceptedFiles || [])];
          console.log(
            finalArr,
            "finalArr",
            finalArr?.length,
            finalArr?.length >= 3
          );
          if (finalArr?.length > 3) {
            setError("Too many files uploaded, only 3 files can be uploaded");
            return;
          }
          setError("");
          onChange(finalArr);
        }}
        onError={(error) => {}}
        onDropRejected={(error) => {
          if (error?.[0]?.errors?.[0]?.message === "Too many files") {
            setError("Too many files uploaded, only 3 files can be uploaded");
          }

          console.log(error?.[0]?.errors?.[0]?.message);
        }}
        maxFiles={3}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <SimpleInput {...getInputProps()} {...props} />
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                style={{
                  height: 150,
                  borderRadius: 5,
                  borderStyle: "dashed",
                  borderWidth: 1,
                  borderColor: props?.error ? "red" : "#BFBFBF",
                  backgroundColor: Theme.bg.lightGrey,
                  padding: 10,
                }}
              >
                <Grid item align="center">
                  <Grid container spacing={2}>
                    <Grid item>
                      <div className="w-11 h-11 rounded-full bg-white flex justify-center items-center">
                        <img src={UPLOAD_ICON} className="w-4 h-4" />
                      </div>
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: 20,
                          color: Theme.color.basic,
                          fontFamily: "Urbanist-Semibold",
                          textAlign: "left",
                        }}
                      >
                        Click to Upload thumbnail image
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 16,
                          mb: 0.4,
                          color: "#949494",
                          fontFamily: "Urbanist-Regular",
                          textAlign: "left",
                        }}
                      >
                        or just drag and drop
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </section>
        )}
      </Dropzone>
      <Grid container className="pt-3">
        {value?.map((file, i) => {
          return (
            <Grid
              item
              key={i}
              style={{
                borderRadius: 5,
                borderStyle: "dashed",
                borderWidth: 1,
                borderColor: "#BFBFBF",
                backgroundColor: Theme.bg.lightGrey,
                padding: 10,
                marginLeft: 5,
              }}
              align="right"
            >
              <CancelOutlined
                style={{
                  fontSize: 14,
                  color: Theme.color.darkRed,
                  position: "absolute",
                  textAlign: "right",
                  marginLeft: -10,
                  marginTop: -5,
                  cursor: "pointer",
                }}
                onClick={() => removeImage(i)}
              />
              <img
                src={file?.size ? URL.createObjectURL(file) : file?.image}
                className="w-16 h-16"
              />
            </Grid>
          );
        })}
      </Grid>
      {(props?.helperText || error) && (
        <FormHelperText style={{ color: "red" }}>
          {props.helperText || error}
        </FormHelperText>
      )}
    </>
  );
}
export default MyDropzone;

// import { Box, Button, IconButton, Typography } from "@mui/material";
// import React from "react";
// import UploadIcon_SVG from "../../Assets/SVG/upload.svg";
// import DoneIcon from "@mui/icons-material/Done";
// import { RiDeleteBinLine } from "react-icons/ri";
// import { Theme } from "../../Theme";
// const ImageUploader2 = ({
//   name,
//   size,
//   fileInputRef,
//   handleFileInputClick,
//   handleFileChange,
// }) => {
//   return (
//     <Box
//       sx={{
//         border: "2px dashed #DDE6EE",
//         borderRadius: "5px",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         px: 5,
//         py: 5,
//         gap: 2,
//       }}
//       onClick={handleFileInputClick}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           gap: 3,
//         }}
//       >
//         <Box>
//           <img src={UploadIcon_SVG} alt="" />
//         </Box>
//         <Box>
//           <Typography style={{ fontWeight: 600 }}>
//             Click to Upload thumbnail image
//           </Typography>
//           <Typography style={{ color: "#949494" }}>
//             or just drag and drop
//           </Typography>
//         </Box>
//       </Box>

//       <input
//         type="file"
//         ref={fileInputRef}
//         style={{ display: "none" }}
//         onChange={handleFileChange}
//         multiple
//       />
//     </Box>
//   );
// };

// export default ImageUploader2;
