import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RequireAuth from "./RequireAuth";
import Drawer from "../Components/Drawer/Drawer";
import SignUp from "../Screens/SignUp/SignUp";
import Dashboard from "../Screens/Dashboard/Dashboard";
import Login from "../Screens/Login/Login";
import {
  DASHBOARD_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  RESET_PATH,
  SAVED_GOLD_FINCHES_PATH,
  ADD_FRANCHISE_PATH,
  ADD_ANALYTICS_PATH,
  ANALYTICS_PATH,
  BUY_PACKAGES_PATH,
  FORGOT_PATH,
  SUBSCRIPTION_PATH,
  ACCOUNT_REQUEST_PATH,
  ACTIVE_CAMPAIGNS_PATH,
  APP_USERS_PATH,
  USERS_FEEDBACK_PATH,
  CATEGORIES_LIST_PATH,
  FREE_REWARDS_PATH,
  EMAIL_BROADCAST_PATH,
  PRIVACY_POLICY_PATH,
  VIEW_PROFILE_PATH,
  VERIFY_OTP_PATH,
  TERMS_PRIVACY_PATH,
} from "../Constants/Paths";
import SavedGoldFinches from "../Screens/SavedGoldFinches/SavedGoldFinches";
import AddFranchise from "../Screens/AddFranchise/AddFranchise";
import Analytics from "../Screens/Analytics/Analytics";
import BuyPackages from "../Screens/BuyPackages";
import ForgotPassword from "../Screens/ForgotPassword/ForgotPassword";
import ResetPassword from "../Screens/ResetPassword";
import Subscription from "../Screens/Subscription";
import AccountRequests from "../Screens/AccountRequests";
import ActiveCampaigns from "../Screens/ActiveCampaigns";
import AppUsers from "../Screens/AppUsers";
import UsersFeedback from "../Screens/UsersFeedback";
import CategoriesList from "../Screens/CategoriesList";
import FreeRewards from "../Screens/FreeRewards";
import EmailBroadcast from "../Screens/EmailBroadcast";
import PrivacyPolicy from "../Screens/PrivacyPolicy";
import ViewProfile from "../Screens/ViewProfile";
import { useSelector } from "react-redux";
import { auth } from "../Constants/Firebase";
import VerifyOtp from "../Screens/VerifyOtp";
import TermAndPrivacy from "../Screens/TermAndPrivacy";
// export const SIGN_UP_PATH = "/sign-up";
// export const SIGN_IN_PATH = "/sign-in";
// export const DASHBOARD_PATH = "/";
// export const RESET_PATH = "/";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <Drawer />
      </RequireAuth>
    ),
    children: [
      {
        path: DASHBOARD_PATH,
        element: <Dashboard />,
      },
      {
        path: SAVED_GOLD_FINCHES_PATH,
        element: <SavedGoldFinches />,
      },
      {
        path: ADD_FRANCHISE_PATH,
        element: <AddFranchise />,
      },
      {
        path: ANALYTICS_PATH,
        element: <Analytics />,
      },
      {
        path: BUY_PACKAGES_PATH,
        element: <BuyPackages />,
      },
      {
        path: SUBSCRIPTION_PATH,
        element: <Subscription />,
      },
      {
        path: ACCOUNT_REQUEST_PATH,
        element: <AccountRequests />,
      },
      {
        path: FREE_REWARDS_PATH,
        element: <FreeRewards />,
      },
      {
        path: ACTIVE_CAMPAIGNS_PATH,
        element: <ActiveCampaigns />,
      },
      {
        path: EMAIL_BROADCAST_PATH,
        element: <EmailBroadcast />,
      },
      {
        path: APP_USERS_PATH,
        element: <AppUsers />,
      },
      {
        path: USERS_FEEDBACK_PATH,
        element: <UsersFeedback />,
      },
      {
        path: CATEGORIES_LIST_PATH,
        element: <CategoriesList />,
      },
      {
        path: PRIVACY_POLICY_PATH,
        element: <PrivacyPolicy />,
      },
      {
        path: VIEW_PROFILE_PATH,
        element: <ViewProfile />,
      },
    ],
  },
  {
    path: SIGN_IN_PATH,
    element: <Login />,
  },
  {
    path: VERIFY_OTP_PATH,
    element: <VerifyOtp />,
  },
  {
    path: SIGN_UP_PATH,
    element: <SignUp />,
  },
  {
    path: FORGOT_PATH,
    element: <ForgotPassword />,
  },
  {
    path: RESET_PATH,
    element: <ResetPassword />,
  },
  {
    path: TERMS_PRIVACY_PATH,
    element: <TermAndPrivacy />,
  },
]);

const Navigation = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      console.log(user, "USER_LOL");
      if (user) {
        const userToken = await user.getIdToken();
        console.log(userToken);
        localStorage.setItem("@access_token", userToken);
      } else {
        localStorage.removeItem("@access_token");
      }
      setLoading(false);
    });
  }, []);
  console.log(loading, "loading");
  return (
    <>{loading ? "" : <RouterProvider router={router}></RouterProvider>}</>
  );
};

export default Navigation;
