import { Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import SimpleSelect from "../Inputs/SimpleSelect";

const TablePagination = ({
  data,
  itemsPerPage = 10,
  next,
  previous,
  loading,
  setPage,
  loadMore = false,
  setRowsPerPage,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(itemsPerPage);
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalItems = data?.length;

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage >= 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);
  const handleNext = () => {
    if (currentPage <= totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          onClick={() => handleClick(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </li>
      );
    }

    return loadMore ? (
      <ul className="pagination">
        {next && (
          <Button
            variant="outlined"
            onClick={() =>
              Boolean(next)
                ? next().then((e) => {
                    handleNext();
                  })
                : handleNext()
            }
            size="small"
            disabled={loading}
            style={{ borderRadius: 20 }}
          >
            Load More
          </Button>
        )}
      </ul>
    ) : (
      <ul className="pagination">
        {currentPage !== 0 && (
          <Button
            size="small"
            variant="outlined"
            onClick={() =>
              Boolean(previous)
                ? previous().then((e) => {
                    handlePrevious();
                  })
                : handlePrevious()
            }
            disabled={loading}
            style={{ borderRadius: 20 }}
          >
            Previous
          </Button>
        )}
        {currentPage + 1 !== totalPages && Boolean(data?.length) && (
          <Button
            variant="outlined"
            onClick={() =>
              Boolean(next)
                ? next().then((e) => {
                    handleNext();
                  })
                : handleNext()
            }
            size="small"
            disabled={loading}
            style={{ borderRadius: 20 }}
          >
            Next
          </Button>
        )}
      </ul>
    );
  };
  let options = [10, 20, 30];

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{ px: 2, p: 2 }}
    >
      {/* <Grid item>
        {firstVisibleItem > count ? count : firstVisibleItem}-{lastVisibleItem}{" "}
        of {count} items
      </Grid> */}
      {totalPages > 0 && (
        <Grid item sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          Row Per Page
          <SimpleSelect
            size="small"
            options={options?.map((v) => {
              return {
                label: v,
                value: v,
              };
            })}
            onChange={(c) => {
              setItemsToShow(c);
              setRowsPerPage(c);
            }}
            value={itemsToShow + ""}
          />
        </Grid>
      )}
      <Grid item>{renderPagination()}</Grid>
    </Grid>
  );
};

export default TablePagination;
