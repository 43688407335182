import React, { useState } from "react";
import DashLogo from "../../Assets/SVG/Dashlogo.svg";
import CreateCompain from "../../Assets/SVG/CreateCampaign.svg";
import SavedGoldFinches from "../../Assets/SVG/SavedGoldFinches.svg";
import AddFranchise from "../../Assets/SVG/AddFranchise.svg";
import Analytics from "../../Assets/SVG/Analytics.svg";
import BuyPackages from "../../Assets/SVG/BuyPackages.svg";
import Logout from "../../Assets/SVG/Logout.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { RiDeleteBinLine } from "react-icons/ri";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Box,
  Stack,
  IconButton as IconBtn,
  Typography,
  Grid,
  Button,
  IconButton,
  Checkbox,
  Paper,
  useMediaQuery,
} from "@mui/material";
import IconFeather_IMG from "../../Assets/SVG/Iconfeather.svg";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StickyHeadTable from "../../Components/Table/index.jsx";
import { styled } from "@mui/material/styles";
import { VscEdit } from "react-icons/vsc";
import { BiAddToQueue } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { RiArrowDropUpFill } from "react-icons/ri";
import { RiArrowDropDownFill } from "react-icons/ri";
import { Theme } from "../../Theme";
import GradientBox from "../../Components/GradientBox";
import SideBar from "../../Components/Sidebar";
import CreateFinch from "../../Components/Forms/CreateFinch";
import CreateGoldFinch from "../../Components/Forms/CreateGoldFinch";
import { BsSearch } from "react-icons/bs";
import BasicModal from "../../Components/Modal";
import DeleteComp from "../../Components/Modal/DeleteComp";
import DateChangeComp from "../../Components/Modal/DateChangeComp";
import CancelSubsComp from "../../Components/Modal/CancelSubsComp";
import "../../App.css";
import SimpleMenu from "../../Components/Menu";
import ColumnMenu from "../../Components/Menu/ColumnMenu";
import SubscriptionFilter from "../../Components/Menu/SubscriptionFilter";
import ExpandableSearchBar from "../../Components/Inputs/ExpandableSearchBar";
import ColumnButton from "../../Components/Buttons/ColumnButton";
import SimpleSelect from "../../Components/Inputs/SimpleSelect";

const ActiveCampaigns = () => {
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignSidebar, setCampaignSidebar] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [CreateFinchForm, setCreteFinchForm] = useState(false);
  const [CreateGoldFinchForm, setCreteGoldFinchForm] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const menuOpen1 = Boolean(anchorEl1);
  const menuOpen2 = Boolean(anchorEl2);

  // const [count, setCount] = useState(user?.count || 0);
  // const [nextPage, setNextPage] = useState(userData?.next);
  // const [previousPage, setPreviousPage] = useState(userData?.previous);

  const titleLiner = (title) => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
        {title}
      </Typography>
      {/* <Stack sx={{ m: 0, p: 0 }}>
        <RiArrowDropUpFill style={{ marginBottom: -5 }} />
        <RiArrowDropDownFill style={{ marginTop: -5 }} />
      </Stack> */}
    </Box>
  );

  const textLiner = (color, fontSize, text, bgColor = null) => {
    return (
      <Typography
        sx={{
          color: color,
          fontSize: fontSize,
          backgroundColor: bgColor,
          borderRadius: "4px",
          //   px: 1,
          fontFamily: "Urbanist-Semibold",
        }}
      >
        {text}
      </Typography>
    );
  };

  const columns = [
    {
      id: "title",
      label: titleLiner("Title"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "startDate",
      label: titleLiner("Start Date"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "endDate",
      label: titleLiner("End Date"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "type",
      label: titleLiner("Type"),
      minWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "status",
      label: titleLiner("Status"),
      minWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "actions",
      label: titleLiner("Actions"),
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "active",
      label: titleLiner("Active/Inactive"),
      minWidth: 10,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "finchSwitch",
      label: titleLiner("Switch to Goldfinch"),
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
  ];

  const handleClick = (event, id) => {
    setSelectedId(id);
    if (event === "delete") {
      handleModal(id);
    }
  };

  const handleMenuClick = (event, state) => {
    if (state === "filter") {
      setAnchorEl1(event.currentTarget);
    } else if (state === "column") {
      setAnchorEl2(event.currentTarget);
    }
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleModal = (id) => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //   const handleCheckboxChange = (index) => {
  //     // Create a copy of the userData array
  //     const updatedUserData = [...userData];

  //     // Toggle the checked property of the specific object
  //     updatedUserData[index].checked = !updatedUserData[index].checked;

  //     // Update the state with the new userData
  //     setUserData(updatedUserData);
  //   };

  let franchises = ["Calgary", "Line Chest", "Acura", "My Home"];

  let userData = [
    {
      title: textLiner(Theme.color.text, "18px", "Test 22-06-2023"),
      startDate: textLiner(Theme.color.text, "18px", "Jul-19-2023"),
      endDate: textLiner(Theme.color.text, "18px", "Jul-25-2023"),
      type: textLiner(
        Theme.color.golden,
        "16px",
        "Goldfinch",
        "rgba(255, 220, 68, 0.23)"
      ),
      status: textLiner(
        Theme.color.red,
        "16px",
        "Expired",
        "rgba(220, 40, 69, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            onClick={() => {
              setCreteFinchForm(true);
            }}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <RiDeleteBinLine color={Theme.color.white} />
          </IconBtn>
        </Stack>
      ),
      active: (
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          //   color="#32C28C"
          //   sx={{
          //     "&.MuiSwitch-root .MuiSwitch-switchBase": {
          //       color: "#32C28C",
          //     },
          //     "&.MuiSwitch-root .Mui-checked": {
          //       color: "#32C28C",
          //     },
          //   }}
        />
      ),
      finchSwitch: (
        <Checkbox
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            // color: Theme.color.yellow,
            "&.Mui-checked": {
              color: Theme.color.yellow,
            },
          }}
        />
      ),
    },
    {
      title: textLiner(Theme.color.text, "18px", "Test 22-06-2023"),
      startDate: textLiner(Theme.color.text, "18px", "Jul-19-2023"),
      endDate: textLiner(Theme.color.text, "18px", "Jul-25-2023"),
      type: textLiner(
        Theme.color.golden,
        "16px",
        "Goldfinch",
        "rgba(255, 220, 68, 0.23)"
      ),
      status: textLiner(
        Theme.color.red,
        "16px",
        "Expired",
        "rgba(220, 40, 69, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            onClick={() => {
              setCreteFinchForm(true);
            }}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <RiDeleteBinLine color={Theme.color.white} />
          </IconBtn>
        </Stack>
      ),
      active: (
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
      finchSwitch: (
        <Checkbox
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            // color: Theme.color.yellow,
            "&.Mui-checked": {
              color: Theme.color.yellow,
            },
          }}
        />
      ),
    },
    {
      title: textLiner(Theme.color.text, "18px", "Test 22-06-2023"),
      startDate: textLiner(Theme.color.text, "18px", "Jul-19-2023"),
      endDate: textLiner(Theme.color.text, "18px", "Jul-25-2023"),
      type: textLiner(
        Theme.color.golden,
        "16px",
        "Goldfinch",
        "rgba(255, 220, 68, 0.23)"
      ),
      status: textLiner(
        Theme.color.red,
        "16px",
        "Expired",
        "rgba(220, 40, 69, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            onClick={() => {
              setCreteFinchForm(true);
            }}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <RiDeleteBinLine color={Theme.color.white} />
          </IconBtn>
        </Stack>
      ),
      active: (
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
      finchSwitch: (
        <Checkbox
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            // color: Theme.color.yellow,
            "&.Mui-checked": {
              color: Theme.color.yellow,
            },
          }}
        />
      ),
    },
    {
      title: textLiner(Theme.color.text, "18px", "Test 22-06-2023"),
      startDate: textLiner(Theme.color.text, "18px", "Jul-19-2023"),
      endDate: textLiner(Theme.color.text, "18px", "Jul-25-2023"),
      type: textLiner(
        Theme.color.golden,
        "16px",
        "Goldfinch",
        "rgba(255, 220, 68, 0.23)"
      ),
      status: textLiner(
        Theme.color.darkGreen,
        "16px",
        "Active",
        "rgba(28, 188, 60, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            onClick={() => {
              setCreteFinchForm(true);
            }}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <RiDeleteBinLine color={Theme.color.white} />
          </IconBtn>
        </Stack>
      ),
      active: (
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      ),
      finchSwitch: (
        <Checkbox
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            // color: Theme.color.yellow,
            "&.Mui-checked": {
              color: Theme.color.yellow,
            },
          }}
        />
      ),
    },
  ];

  //static pagination
  let paginatedData = {
    count: 20,
    next: 2,
    previous: null,
    results: userData,
  };
  const [users, setUsers] = useState(paginatedData.results);
  const [currentPage, setCurrentPage] = useState(1);

  const getUsers = (page) => {
    if (page === 1) {
      setUsers(paginatedData.results);
      setCurrentPage(1);
    } else if (page === 2) {
      setUsers(paginatedData.results);
      setCurrentPage(2);
    } else {
    }
  };

  let options = [
    "Package Type",
    "Period Start",
    "Period End",
    "Interval",
    "Quantity",
    "Price",
    "Currency",
    "Total Price",
    "Actions",
  ];

  return (
    <Box sx={{ p: 1, height: "auto" }}>
      <Box sx={{ my: 3, mt: 4 }}>
        <SimpleSelect
          size="small"
          options={options}
          // fullWidth
          label={"Search Partners"}
          style={{
            px: 10,
            backgroundColor: "#FFFFFF",
            border: "1px solid #E7EAEE",
          }}
        />
      </Box>
      {!isSmallScreen ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 1, sm: 2, md: 5 },
            }}
          >
            <Typography
              style={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}
            >
              Campaigns
            </Typography>
            <ColumnButton
              onClick={(e) => {
                handleMenuClick(e, "column");
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            {/* <Button
              variant="outlined"
              style={{
                color: "#88949E",
                borderColor: "#E7EAEE",
                borderRadius: 5,
                padding: 10,
              }}
            >
              <BsSearch style={{ fontSize: 20 }} />
            </Button> */}
            <ExpandableSearchBar />
          </Box>
        </Box>
      ) : (
        <Paper sx={{ px: 1, py: 2, my: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 2,
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 1, sm: 2, md: 5 },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "32px",
                  // fontSize: { xs: "24px", sm: "24px", md: "34px" },
                }}
              >
                Campaigns
              </Typography>
              {/* <img src={IconFeather_IMG} alt="" /> */}
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button
                variant="outlined"
                sx={{
                  color: "#88949E",
                  borderColor: "#E7EAEE",
                  // borderRadius: 5,
                  px: 1,
                  py: 1,
                }}
              >
                <BsSearch style={{ fontSize: 20 }} />
              </Button>
            </Box> */}
          </Box>
        </Paper>
      )}
      <Box sx={{ my: 2 }}>
        <StickyHeadTable
          columns={columns}
          rows={paginatedData.results}
          count={paginatedData?.count || 0}
          next={
            paginatedData?.next
              ? (callback) => getUsers(users?.next, callback)
              : null
          }
          previous={
            paginatedData?.previous
              ? (callback) => getUsers(users?.previous, callback)
              : null
          }
          loading={loading}
        />
      </Box>
      <SideBar
        open={CreateFinchForm}
        onClose={() => setCreteFinchForm(false)}
        title={selectedId ? "Edit Finch" : "Create Finch"}
        form={
          <CreateFinch
            id={selectedId}
            // onEdit={getAllClients}
            onClose={() => setCreteFinchForm(false)}
          />
        }
      />
      <SimpleMenu
        handleClose={handleClose1}
        open={menuOpen1}
        anchorEl={anchorEl1}
        data={
          <SubscriptionFilter
          // selectedId={selectedId}
          // handleOptionClick={handleMenuClick}
          // options={options}
          />
        }
        // options={options}
      />
      <SimpleMenu
        handleClose={handleClose2}
        open={menuOpen2}
        anchorEl={anchorEl2}
        data={
          <ColumnMenu
            selectedId={selectedId}
            handleOptionClick={handleMenuClick}
            options={options}
          />
        }
      />
      <SideBar
        open={CreateGoldFinchForm}
        onClose={() => setCreteGoldFinchForm(false)}
        title={selectedId ? "Edit GoldFinch" : "Create GoldFinch"}
        form={
          <CreateGoldFinch
            id={selectedId}
            // onEdit={getAllClients}
            onClose={() => setCreteGoldFinchForm(false)}
          />
        }
      />
      {/* Delete Modal */}
      <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<DeleteComp />}
      />
    </Box>
  );
};

export default ActiveCampaigns;
