import React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconButton, InputLabel, Tooltip } from "@mui/material";
import { Theme } from "../../Theme";
import { AiFillExclamationCircle } from "react-icons/ai";
import TimePickerInput from "./TimePickerInput";

const DatePickerInput = ({
  label,
  value,
  disabled,
  required,
  simple,
  tooltipTitle,
  ...props
}) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DemoContainer components={["DatePicker"]}> */}
        {/* <DemoItem
          label={label}
          sx={{
            margin: 0, // Remove margin
            padding: 0, // Remove padding
          }}
        > */}
        {label ? (
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
          >
            {label}
            {required ? (
              <span style={{ color: Theme.color.red }}>*</span>
            ) : simple ? null : (
              <Tooltip
                title={tooltipTitle}
                componentsProps={{
                  tooltip: {
                    sx: {
                      // bgcolor: "common.black",
                      // "& .MuiTooltip-arrow": {
                      //   color: "common.black",
                      // },
                    },
                  },
                }}
              >
                <IconButton>
                  <AiFillExclamationCircle size={15} color="#0088FF" />
                </IconButton>
              </Tooltip>
            )}
          </InputLabel>
        ) : null}
        <TimePickerInput label={label} {...props} />
      </LocalizationProvider>
    </>
  );
};

export default DatePickerInput;
