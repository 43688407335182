import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, storage } from "../Constants/Firebase";
import useFetch from "../Utils/useFetch";
import { EDIT_PROFILE_API, GET_PARTNER_DATA_API } from "../Constants/APIs";
import { ADD_PARTNER_DATA, ADD_USER } from "../Redux/types";
import { toast } from "react-toastify";
import getMimeType from "../Utils/getMimeType";

const useProfile = () => {
  const { request } = useFetch();

  const partner = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const editProfile = async (data, selectedFile) => {
    try {
      // console.log(selectedFile, "LOGOURL_UPLOAD");
      setLoading(true);
      if (selectedFile?.type) {
        const storageRef = storage.ref(
          `restaurants/${partner.uid}/logo.${getMimeType(selectedFile.type)}`
        );
        await storageRef.put(selectedFile);
        const logoUrl = await storageRef.getDownloadURL();
        // console.log(logoUrl, "LOGOURL_UPLOAD");
        data = {
          ...data,
          logoUrl,
          imageContentType: selectedFile.type,
        };
      }

      const req = await request(EDIT_PROFILE_API, "POST", data);

      getPartnerData();
      toast.success("Profile edited successfully");
    } catch (e) {
      // console.log(e.message, "LOGOURL_UPLOAD");
    } finally {
      setLoading(false);
    }
  };
  const getPartnerData = async () => {
    try {
      const req = await request(GET_PARTNER_DATA_API, "GET");
      console.log(req?.data?.data, "req Data for Data");
      dispatch({
        type: ADD_PARTNER_DATA,
        payload: req?.data?.data,
        // payload: req?.data
      });
      dispatch({
        type: ADD_USER,
        payload: req?.data?.data,
        // payload: req?.data
      });
    } catch (err) {
      console.log(err, "err.message");
    }
  };
  return { editProfile, getPartnerData, loading };
};

export default useProfile;
