import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import "../../App.css";
import { Theme } from "../../Theme";
import { Box, IconButton, Paper, TextField, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useAuth from "../../ApiHooks/useAuth";
import BasicModal from "../../Components/Modal";
import ChangePassComp from "../../Components/Modal/ChangePassComp";
import ResetPassword from "../ResetPassword";
import ToggleInput from "../../Components/Inputs/ToggleInput";
// import Modals from "../../Components/Modals/Modals";
const inputStyle = {
  width: "40px",
  height: "40px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  boxShadow: "10px 20px 30px #00000029",
};

const VerifyOtp = () => {
  const navigate = useNavigate();
  const { loading, errors, verifyOtp, resetPassword } = useAuth();
  const location = useLocation();
  const email = location?.search?.replace("?email=", "");
  const user = useSelector((state) => state.userReducer.user);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = React.useState(false);

  const [isCodeValid, setCodeValid] = useState(false);

  useEffect(() => {
    if (user && user !== null) {
      navigate("/");
    }
  }, []);

  const handleVerifyOtp = () => {
    console.log("password rest");
    verifyOtp({ email, code: otp }, () => {
      setCodeValid(true);
    });
  };

  const handleResetPassword = () => {
    resetPassword({ email, code: otp, password, confirmPassword }, () => {
      setOpen(true);
    });
  };

  console.log(location, "params");

  return (
    <Box sx={{ px: 4, py: 2 }}>
      {/* <Box> */}
      <Link to={"/"}>
        <Paper
          sx={{
            width: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "10px 10px 30px #00000029",
            borderRadius: "15px",
            py: 0.5,
          }}
          // onClick={() => setSwapform(true)}
        >
          <IconButton>
            <ArrowBackIosNewIcon sx={{ color: Theme.color.basic }} />
          </IconButton>
        </Paper>
      </Link>
      {/* </Box> */}
      {isCodeValid ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
              gap: 3,
              // px: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                // width: "600px",
                gap: 3,
                px: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "25px", sm: "30px" },
                  textAlign: { xs: "left", sm: "left", md: "center" },
                  fontFamily: "Urbanist-Bold",
                  mb: 4,
                  width: { xs: "280px", sm: "450px", md: "550px" },
                }}
              >
                Create New Password
              </Typography>
              <Box
                sx={{
                  width: { xs: "280px", sm: "450px", md: "550px" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <ToggleInput
                  label="New Password"
                  simple
                  placeholder={"New Password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={Boolean(errors?.password)}
                  helperText={errors?.password}
                />
                <ToggleInput
                  label="Confirm Password"
                  simple
                  placeholder={"Confirm Password"}
                  error={Boolean(errors?.confirmPassword)}
                  helperText={errors?.confirmPassword}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Box>
              <Box sx={{ width: { xs: "200px", sm: "350px", md: "400px" } }}>
                <PrimaryButton
                  text={"Update Password"}
                  onClick={handleResetPassword}
                  fullWidth
                  sx={{
                    // minWidth: "200px",
                    color: Theme.color.basic,
                    backgroundColor: Theme.bg.yellow,
                    borderRadius: "8px",
                    py: { xs: 1, sm: 2 },
                    my: 4,
                    // width: "400px",
                    fontSize: "18px",
                    "&.MuiButton-root": {
                      "&:hover": {
                        backgroundColor: Theme.bg.yellow,
                        borderColor: Theme.bg.yellow,
                        boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                      },
                    },
                    boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                    fontFamily: "Poppins-Medium",
                  }}
                  loading={loading}
                />
              </Box>
            </Box>
          </Box>
          <BasicModal
            open={open}
            handleClose={() => setOpen(false)}
            data={<ChangePassComp />}
          />
        </>
      ) : (
        <Box className="flex flex-col justify-center items-center h-[100vh]">
          <Typography
            sx={{ fontFamily: "Urbanist-Bold", mb: "20px", fontSize: "30px" }}
          >
            Reset password
          </Typography>
          <Typography
            sx={{
              color: "rgba(32, 32, 32, 0.61)",
              fontFamily: "Poppins-Regular",
              textAlign: "center",
              fontSize: "20px",
              mb: "20px",
              maxWidth: "60%",
            }}
          >
            Please enter the 4 digits code send to your registered email address{" "}
            {email}
          </Typography>
          <OtpInput
            placeholder={"******"}
            inputStyle={inputStyle}
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>ㅤㅤ</span>}
            renderInput={(props) => <input {...props} />}
          />
          <PrimaryButton
            text={"NEXT"}
            onClick={handleVerifyOtp}
            loading={loading}
            // fullWidth
            sx={{
              color: Theme.color.basic,
              backgroundColor: Theme.bg.yellow,
              borderRadius: "8px",
              my: 4,
              width: "400px",
              fontSize: "18px",
              "&.MuiButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.yellow,
                  borderColor: Theme.bg.yellow,
                  boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                },
              },
              boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              fontFamily: "Urbanist-Bold",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default VerifyOtp;
