import React, { useRef, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import SimpleInput from "../Inputs/SimpleInput";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";

const CategoriesForm = () => {
  return (
    <Box sx={{ p: 3, pl: 5, pr: 5, pt: 2 }}>
      <Grid container spacing={2} sx={{ mb: 2, height: "80vh" }}>
        <Grid item xs={12} sm={12} md={12}>
          <SimpleInput
            label="Category Name"
            fullWidth
            required
            placeholder={"Input your name"}
            // defaultValue="Input your name"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ my: 2 }}>
        <Grid item xs={12}>
          <PrimaryButton
            text={"SAVE"}
            elevation={3}
            size="medium"
            fullWidth
            sx={{
              color: Theme.color.basic,
              backgroundColor: Theme.bg.yellow,
              boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              fontFamily: "Urbanist-Bold",
              "&.MuiButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.yellow,
                  borderColor: Theme.bg.yellow,
                  boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CategoriesForm;
