import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper, Circle } from "google-maps-react";
import { GOOGLE_KEY } from "../../Constants/APIs";
import { setKey, fromLatLng } from "react-geocode";
export class MapContainer extends Component {
  state = {
    range: this.props.range,
    lat: this.props.latitude,
    lng: this.props.longitude,
    markers: [
      {
        name: "Current position",
        position: {
          lat: this.props.latitude,
          lng: this.props.longitude,
        },
      },
    ],
  };

  componentDidMount() {
    setKey(GOOGLE_KEY);
    this.getAddressFromLetLng(this.state.lat, this.state.lng);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.range !== this.props.range) {
      this.setState({ range: this.props.range });
    }
  }

  onMarkerClick = () => {};
  onInfoWindowClose = () => {};

  onMarkerDragEnd = (coord, index) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.getAddressFromLetLng(lat, lng);

    this.setState({ lat, lng });
  };

  getAddressFromLetLng = (lat, lng) => {
    fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        this.props.getAddress(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  render() {
    const { lat, lng, markers, style } = this.props;
    return (
      <Map
        google={this.props.google}
        gestureHandling="none"
        initialCenter={{
          lat: lat || "45.5018795",
          lng: lng || "-73.6085913",
        }}
        zoom={11.5}
        style={style || { height: 250, width: 500 }}
        center={{
          lat: lat || "45.5018795",
          lng: lng || "-73.6085913",
        }}
        containerStyle={style || { height: 250, width: 500 }}
      >
        <Marker
          onClick={this.onMarkerClick}
          name={"Current location"}
          draggable={false}
          onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
          position={{
            lat,
            lng,
          }}
        />
        {lat && lng && (
          <Circle
            center={{
              lat: lat || "45.5018795",
              lng: lng || "-73.6085913",
            }}
            radius={this.state.range}
            strokeColor="#FF0000"
            strokeWeight={2}
            fillColor="#FF0000"
          />
        )}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_KEY,
})(MapContainer);
