import { IconButton, Paper } from "@mui/material";
import React from "react";
import { FiColumns } from "react-icons/fi";
import { Theme } from "../../Theme";

const ColumnButton = ({...props}) => {
  return (
    <Paper
      {...props}
      elevation={0}
    >
      <IconButton sx={{p:0.5}}>
        <FiColumns color="#8C97A1" size={30} />
      </IconButton>
    </Paper>
  );
};

export default ColumnButton;
