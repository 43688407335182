import React, { useState } from "react";
import DashLogo from "../../Assets/SVG/Dashlogo.svg";
import CreateCompain from "../../Assets/SVG/CreateCampaign.svg";
import SavedGoldFinches from "../../Assets/SVG/SavedGoldFinches.svg";
import AddFranchise from "../../Assets/SVG/AddFranchise.svg";
import Analytics from "../../Assets/SVG/Analytics.svg";
import BuyPackages from "../../Assets/SVG/BuyPackages.svg";
import Logout from "../../Assets/SVG/Logout.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { LuFilter } from "react-icons/lu";
import {
  Box,
  Stack,
  IconButton as IconBtn,
  Typography,
  Grid,
  Button,
  IconButton,
  useMediaQuery,
  Paper,
} from "@mui/material";
import IconFeather_IMG from "../../Assets/SVG/Iconfeather.svg";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StickyHeadTable from "../../Components/Table/index.jsx";
import { styled } from "@mui/material/styles";
import { VscEdit } from "react-icons/vsc";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiAddToQueue } from "react-icons/bi";
import { HiOutlineArchiveBox } from "react-icons/hi2";
import { RiArrowDropUpFill } from "react-icons/ri";
import { RiArrowDropDownFill } from "react-icons/ri";
import { Theme } from "../../Theme";
import GradientBox from "../../Components/GradientBox";
import SideBar from "../../Components/Sidebar";
import CreateFinch from "../../Components/Forms/CreateFinch";
import CreateGoldFinch from "../../Components/Forms/CreateGoldFinch";
import { BsSearch } from "react-icons/bs";
import BasicModal from "../../Components/Modal";
import DeleteComp from "../../Components/Modal/DeleteComp";
import DateChangeComp from "../../Components/Modal/DateChangeComp";
import CancelSubsComp from "../../Components/Modal/CancelSubsComp";
import "../../App.css";
import SimpleSelect from "../../Components/Inputs/SimpleSelect";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import FilterButton from "../../Components/Buttons/FilterButton";
import ColumnButton from "../../Components/Buttons/ColumnButton";
import SimpleMenu from "../../Components/Menu";
import FilterMenu from "../../Components/Menu/DashboardFilter";
import ColumnMenu from "../../Components/Menu/ColumnMenu";
import SubscriptionFilter from "../../Components/Menu/SubscriptionFilter";
import ExpandableSearchBar from "../../Components/Inputs/ExpandableSearchBar";

const Subscription = () => {
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignSidebar, setCampaignSidebar] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [CreateFinchForm, setCreteFinchForm] = useState(false);
  const [CreateGoldFinchForm, setCreteGoldFinchForm] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const menuOpen1 = Boolean(anchorEl1);
  const menuOpen2 = Boolean(anchorEl2);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // const [count, setCount] = useState(user?.count || 0);
  // const [nextPage, setNextPage] = useState(userData?.next);
  // const [previousPage, setPreviousPage] = useState(userData?.previous);

  const titleLiner = (title) => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
        {title}
      </Typography>
      {/* <Stack sx={{ m: 0, p: 0 }}>
        <RiArrowDropUpFill style={{ marginBottom: -5 }} />
        <RiArrowDropDownFill style={{ marginTop: -5 }} />
      </Stack> */}
    </Box>
  );

  const textLiner = (color, fontSize, text, bgColor = null) => {
    return (
      <Typography
        sx={{
          color: color,
          fontSize: fontSize,
          backgroundColor: bgColor,
          borderRadius: "4px",
          px: 1,
          fontFamily: "Urbanist-Semibold",
        }}
      >
        {text}
      </Typography>
    );
  };

  let franchises = ["Calgary", "Line Chest", "Acura", "My Home"];

  const handleClick = (event, id) => {
    setSelectedId(id);
    if (event === "cancel") {
      handleModal(id);
    }
  };
  const handleMenuClick = (event, state) => {
    if (state === "filter") {
      setAnchorEl1(event.currentTarget);
    } else if (state === "column") {
      setAnchorEl2(event.currentTarget);
    }
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleModal = (id) => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const columns = [
    {
      id: "package",
      label: titleLiner("Package"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "periodStart",
      label: titleLiner("Period Start"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "periodEnd",
      label: titleLiner("Period End"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "interval",
      label: titleLiner("Interval"),
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "quantity",
      label: titleLiner("Quantity"),
      minWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "price",
      label: titleLiner("Price"),
      minWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "currency",
      label: titleLiner("Currency"),
      minWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "totalPrice",
      label: titleLiner("Total Price"),
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "action",
      label: titleLiner("Action"),
      minWidth: 300,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
  ];
  let userData = [
    {
      package: textLiner(Theme.color.text, "18px", "Chicken McMuffin BLT"),
      periodStart: textLiner(Theme.color.text, "18px", "Jul 13, 2023 4:25 PM"),
      periodEnd: textLiner(Theme.color.text, "18px", "Aug 13, 2023 4:25 PM"),
      interval: textLiner(Theme.color.text, "18px", "Month"),
      quantity: textLiner(Theme.color.text, "18px", "$49.00"),
      price: textLiner(Theme.color.text, "18px", "cad"),
      currency: textLiner(Theme.color.text, "18px", "$49.00"),
      totalPrice: textLiner(Theme.color.text, "18px", "cad"),
      action: (
        <PrimaryButton
          text={"Cancelled"}
          // onClick={()=>{handleClick("cancel")}}
          size="small"
          sx={{
            color: Theme.color.white,
            backgroundColor: "#B1B1B1",
            borderRadius: "8px",
            px: 4,
            fontSize: "16px",
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: "#B1B1B1",
                borderColor: "#B1B1B1",
                // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              },
            },
            fontFamily: "Urbanist-Bold",
            // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
          }}
        />
      ),
    },
    {
      package: textLiner(Theme.color.text, "18px", "Chicken McMuffin BLT"),
      periodStart: textLiner(Theme.color.text, "18px", "Jul 13, 2023 4:25 PM"),
      periodEnd: textLiner(Theme.color.text, "18px", "Aug 13, 2023 4:25 PM"),
      interval: textLiner(Theme.color.text, "18px", "Month"),
      quantity: textLiner(Theme.color.text, "18px", "$49.00"),
      price: textLiner(Theme.color.text, "18px", "cad"),
      currency: textLiner(Theme.color.text, "18px", "$49.00"),
      totalPrice: textLiner(Theme.color.text, "18px", "cad"),
      action: (
        <PrimaryButton
          text={"Cancel Subscription"}
          // onClick={handleSignUp}
          size="small"
          onClick={() => {
            handleClick("cancel");
          }}
          sx={{
            color: Theme.color.white,
            backgroundColor: Theme.bg.redBtn,
            borderRadius: "8px",
            px: isSmallScreen ? 1 : 4,
            fontSize: "16px",
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: Theme.bg.redBtn,
                borderColor: Theme.bg.redBtn,
                // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              },
            },
            fontFamily: "Urbanist-Bold",
            // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
          }}
        />
      ),
    },
    {
      package: textLiner(Theme.color.text, "18px", "Chicken McMuffin BLT"),
      periodStart: textLiner(Theme.color.text, "18px", "Jul 13, 2023 4:25 PM"),
      periodEnd: textLiner(Theme.color.text, "18px", "Aug 13, 2023 4:25 PM"),
      interval: textLiner(Theme.color.text, "18px", "Month"),
      quantity: textLiner(Theme.color.text, "18px", "$49.00"),
      price: textLiner(Theme.color.text, "18px", "cad"),
      currency: textLiner(Theme.color.text, "18px", "$49.00"),
      totalPrice: textLiner(Theme.color.text, "18px", "cad"),
      action: (
        <PrimaryButton
          text={"Cancelled"}
          // onClick={handleSignUp}
          size="small"
          sx={{
            color: Theme.color.white,
            backgroundColor: "#B1B1B1",
            borderRadius: "8px",
            px: 4,
            fontSize: "16px",
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: "#B1B1B1",
                borderColor: "#B1B1B1",
                // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              },
            },
            fontFamily: "Urbanist-Bold",
            // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
          }}
        />
      ),
    },
    {
      package: textLiner(Theme.color.text, "18px", "Chicken McMuffin BLT"),
      periodStart: textLiner(Theme.color.text, "18px", "Jul 13, 2023 4:25 PM"),
      periodEnd: textLiner(Theme.color.text, "18px", "Aug 13, 2023 4:25 PM"),
      interval: textLiner(Theme.color.text, "18px", "Month"),
      quantity: textLiner(Theme.color.text, "18px", "$49.00"),
      price: textLiner(Theme.color.text, "18px", "cad"),
      currency: textLiner(Theme.color.text, "18px", "$49.00"),
      totalPrice: textLiner(Theme.color.text, "18px", "cad"),
      action: (
        <PrimaryButton
          text={"Cancel Subscription"}
          // onClick={handleSignUp}
          size="small"
          sx={{
            color: Theme.color.white,
            backgroundColor: Theme.bg.redBtn,
            borderRadius: "8px",
            px: isSmallScreen ? 1 : 4,
            fontSize: "16px",
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: Theme.bg.redBtn,
                borderColor: Theme.bg.redBtn,
                // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              },
            },
            fontFamily: "Urbanist-Bold",
            // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
          }}
        />
      ),
    },
  ];

  //static pagination
  let paginatedData = {
    count: 20,
    next: 2,
    previous: null,
    results: userData,
  };
  const [users, setUsers] = useState(paginatedData.results);
  const [currentPage, setCurrentPage] = useState(1);

  const getUsers = (page) => {
    if (page === 1) {
      setUsers(paginatedData.results);
      setCurrentPage(1);
    } else if (page === 2) {
      setUsers(paginatedData.results);
      setCurrentPage(2);
    } else {
    }
  };

  let options = [
    "Package Type",
    "Period Start",
    "Period End",
    "Interval",
    "Quantity",
    "Price",
    "Currency",
    "Total Price",
    "Actions",
  ];

  return (
    <Box sx={{ p: 1 }}>
      <Grid container sx={{ display: "flex", my: 2 }} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <GradientBox
            text={"Total Customers"}
            count={28}
            fontFamily="Urbanist-Bold"
            fontSize={"24px"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <GradientBox
            text={"Total Income"}
            count={"$27,747"}
            fontFamily="Urbanist-Bold"
            fontSize={"24px"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <GradientBox
            text={"Active Subscriptions"}
            count={44}
            fontFamily="Urbanist-Bold"
            fontSize={"24px"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
          <GradientBox
            text={"Total Assigned"}
            count={0}
            fontFamily="Urbanist-Bold"
            fontSize={"24px"}
          />
        </Grid>
      </Grid>
      {!isSmallScreen ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 2, sm: 2, md: 5 },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Urbanist-Bold",
                fontSize: "32px",
              }}
            >
              Campaigns
            </Typography>
            {/* <Button
              variant="outlined"
              style={{
                color: "#88949E",
                borderColor: "#E7EAEE",
                backgroundColor: "rgba(255, 255, 255, 1)",
              }}
              startIcon={<LuFilter />}
              size="large"
              sx={{ px: { xs: 2, sm: 2, md: 3 } }}
              onClick={(e) => handleMenuClick(e, "filter")}
            >
              Filter
            </Button> */}
            <FilterButton
              onClick={(e) => {
                handleMenuClick(e, "filter");
              }}
            />
            <ColumnButton
              onClick={(e) => {
                handleMenuClick(e, "column");
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 2, sm: 2, md: 5 },
            }}
          >
            {/* <Button
              variant="outlined"
              style={{
                color: "#88949E",
                borderColor: "#E7EAEE",
                borderRadius: 5,
                padding: 10,
              }}
            >
              <BsSearch style={{ fontSize: 20 }} />
            </Button> */}
            <ExpandableSearchBar />
            <SimpleSelect
              size="small"
              options={options}
              fullWidth
              label={"Search Partners"}
              style={{
                px: 10,
                backgroundColor: "#FFFFFF",
                border: "1px solid #E7EAEE",
              }}
            />
          </Box>
        </Box>
      ) : (
        <Paper sx={{ px: 1, py: 2 }}>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 1, sm: 2, md: 5 },
              }}
            >
              <Typography
                // variant="h4"
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: isSmallScreen ? "30px" : "32px",
                }}
              >
                Campaigns
              </Typography>
              {/* <Button
                variant="outlined"
                style={{ color: "#88949E", borderColor: "#E7EAEE" }}
                startIcon={<LuFilter />}
                size="large"
                sx={{ px: { xs: 2, sm: 2, md: 3 } }}
              >
                Filter
              </Button> */}
              {/* <img src={IconFeather_IMG} alt="" /> */}
            </Grid>
            <Grid
              item
              xs={12}
              // sx={{
              //   display: "flex",
              //   alignItems: "center",
              //   gap: { xs: 1, sm: 2, md: 5 },
              //   justifyContent: {
              //     xs: "space-between",
              //     sm: "space-between",
              //     md: "none",
              //   },
              //   width: "100%",
              // }}
            >
              {/* <Button
                variant="outlined"
                sx={{
                  color: "#88949E",
                  borderColor: "#E7EAEE",
                  // borderRadius: 5,
                  px: 1,
                  py: 1,
                }}
              >
                <BsSearch style={{ fontSize: 20 }} />
              </Button> */}
              <SimpleSelect
                size="small"
                options={options}
                fullWidth
                label={"Search Partners"}
                sx={{
                  px: { xs: 8, sm: 8, md: 10 },
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #E7EAEE",
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
      <Box sx={{ my: 2 }}>
        <StickyHeadTable
          columns={columns}
          rows={paginatedData.results}
          count={paginatedData?.count || 0}
          next={
            paginatedData?.next
              ? (callback) => getUsers(users?.next, callback)
              : null
          }
          previous={
            paginatedData?.previous
              ? (callback) => getUsers(users?.previous, callback)
              : null
          }
          loading={loading}
        />
      </Box>
      <SimpleMenu
        handleClose={handleClose1}
        open={menuOpen1}
        anchorEl={anchorEl1}
        data={
          <SubscriptionFilter
          // selectedId={selectedId}
          // handleOptionClick={handleMenuClick}
          // options={options}
          />
        }
        // options={options}
      />
      <SimpleMenu
        handleClose={handleClose2}
        open={menuOpen2}
        anchorEl={anchorEl2}
        data={
          <ColumnMenu
            selectedId={selectedId}
            handleOptionClick={handleMenuClick}
            options={options}
          />
        }
      />
      <SideBar
        open={CreateFinchForm}
        onClose={() => setCreteFinchForm(false)}
        title={selectedId ? "Edit Client" : "CreateFinch"}
        form={
          <CreateFinch
            id={selectedId}
            // onEdit={getAllClients}
            onClose={() => setCreteFinchForm(false)}
          />
        }
      />
      <SideBar
        open={CreateGoldFinchForm}
        onClose={() => setCreteGoldFinchForm(false)}
        title={selectedId ? "Edit Client" : "CreateGoldFinch"}
        form={
          <CreateGoldFinch
            id={selectedId}
            // onEdit={getAllClients}
            onClose={() => setCreteGoldFinchForm(false)}
          />
        }
      />
      {/* Delete Modal */}
      {/* <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<DeleteComp />}
      /> */}

      {/* Date Change Modal */}
      {/* <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<DateChangeComp />}
      /> */}

      {/* Cancel Subcription Modal */}
      <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<CancelSubsComp />}
      />
    </Box>
  );
};

export default Subscription;
