import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBar({
  placeholder = "",
  id,
  onSubmit = () => {},
  onChange = () => {},
  minWidth,
  borderRadius,
  value,
}) {
  const [shouldFocus, setShouldFocus] = React.useState(false);

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        minWidth: minWidth,
        borderRadius: borderRadius,
      }}
    >
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={onSubmit}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ "aria-label": { placeholder } }}
        onChange={onChange}
        onFocus={() => setShouldFocus(true)}
        onBlur={() => setShouldFocus(false)}
        value={value}
      />
    </Paper>
  );
}
