import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export default function TimePickerInput({ label, ...props }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={["TimePicker"]}>
        <TimePicker label={label} {...props} ampm={false} />
      </DemoContainer>
    </LocalizationProvider>
  );
}
