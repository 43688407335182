import { Box, Grid, InputLabel, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { Theme } from "../../Theme";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import SimpleSelect from "../../Components/Inputs/SimpleSelect";
import "../../Screens/PrivacyPolicy/index.css";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import "../../App.css";

const SuspReasonComp = () => {
  const [editorState, setEditorState] = useState(EditorState?.createEmpty());
  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ mb: 3 }}>
        <Typography style={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}>
          Add Reason
        </Typography>
      </Box>
      <Box>
        <Editor
          editorState={editorState}
          spellCheck={true}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="my-editor-className"
          onEditorStateChange={setEditorState}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end",gap:2,mt:4 }}>
          <PrimaryButton
            text={"Close"}
            //   onClick={handleResetPassword}
            // fullWidth
            variant="outlined"
            sx={{
              color: Theme.color.basic,
              borderColor: "#292929",
              borderRadius: "4px",
              px:5,
              // width: "600px",
              fontSize: "18px",
              "&.MuiButton-root": {
                "&:hover": {
                  borderColor: "#292929",
                },
              },
              fontFamily: "Urbanist-Bold",
            }}
          />
          <PrimaryButton
            text={"Submit"}
            //   onClick={handleResetPassword}
            // fullWidth
            sx={{
              color: Theme.color.basic,
              backgroundColor: Theme.bg.yellow,
              borderRadius: "4px",
              px:5,
              // width: "600px",
              fontSize: "18px",
              "&.MuiButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.yellow,
                  borderColor: Theme.bg.yellow,
                  boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                },
              },
              boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              fontFamily: "Urbanist-Bold",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SuspReasonComp;
