import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../Utils/useFetch";
import {
  ACTIVATE_DRAFT_API,
  CHANGE_FINCH_STATUS_API,
  CREATE_DRAFT_FINCH_API,
  CREATE_FINCH_API,
  CREATE_GOLDFINCH_API,
  DELETE_FINCH_API,
  EDIT_DRAFT_FINCH_API,
  EDIT_FINCH_API,
  EDIT_GOLDFINCH_API,
  GET_ALL_PARTNER_FINCHES_API,
} from "../Constants/APIs";
import { ADD_PARTNER_FINCHES } from "../Redux/types";
import { toast } from "react-toastify";
import { storage } from "../Constants/Firebase";
import getMimeType from "../Utils/getMimeType";
import { Theme } from "../Theme";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import {
  RiArrowDropDownFill,
  RiArrowDropUpFill,
  RiDeleteBinLine,
} from "react-icons/ri";
import { VscEdit } from "react-icons/vsc";
import useFranchises from "./useFranchise";
import useCategories from "./useCategories";
import { calculateTimeLeft, calculateTimeSpent } from "../Utils/dateHelper";
import percentToOpacity from "../Utils/percentToOpacity";
import InventoryIcon from "@mui/icons-material/Inventory";
import { BiDuplicate } from "react-icons/bi";
import moment from "moment";
import getFinchConfig from "../Utils/getFinchConfig";

const titleLiner = (title) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
      {title}
    </Typography>
    {/* <Stack sx={{ m: 0, p: 0 }}>
      <RiArrowDropUpFill style={{ marginBottom: -5 }} />
      <RiArrowDropDownFill style={{ marginTop: -5 }} />
    </Stack> */}
  </Box>
);

const textLiner = (color, fontSize, text, bgColor = null, date = false) => {
  const formattedDate = formatDate(text);
  return (
    <Typography
      sx={{
        color: color,
        fontSize: fontSize,
        backgroundColor: bgColor,
        borderRadius: "4px",
        px: 1,
        fontFamily: "Urbanist-Semibold",
      }}
    >
      {date ? formattedDate : text}
    </Typography>
  );
};

function formatDate(unixTimestamp) {
  const timestamp = unixTimestamp * 1000; // Convert to milliseconds
  const date = new Date(timestamp);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();

  return `${month}-${day}-${year}`;
}
const columns = [
  {
    id: "title",
    stringLabel: "Title",
    label: titleLiner("Title"),
    minWidth: 170,
    fontSize: "18px",
    fontWeight: 600,
  },

  {
    id: "franchise",
    stringLabel: "Franchise",
    label: titleLiner("Franchise"),
    minWidth: 270,
    fontSize: "18px",
    fontWeight: 600,
  },
  {
    stringLabel: "Saved By Users",
    id: "savedByUsers",
    label: titleLiner("Saved By Users"),
    minWidth: 70,
    fontSize: "18px",
    fontWeight: 600,
  },
  {
    id: "startDate",
    stringLabel: "Start Date",
    label: titleLiner("Start Date"),
    minWidth: 170,
    fontSize: "18px",
    fontWeight: 600,
  },
  {
    id: "endDate",
    stringLabel: "End Date",
    label: titleLiner("End Date"),
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
    fontSize: "18px",
    fontWeight: 600,
  },
  {
    id: "type",
    stringLabel: "Type",
    label: titleLiner("Type"),
    minWidth: 10,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
    fontSize: "18px",
    fontWeight: 600,
  },
  {
    id: "status",
    stringLabel: "Status",
    label: titleLiner("Status"),
    minWidth: 10,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
    fontSize: "18px",
    fontWeight: 600,
  },
  {
    id: "actions",
    stringLabel: "Actions",
    label: titleLiner("Actions"),
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
    fontSize: "18px",
    fontWeight: 600,
  },
  {
    id: "active",
    stringLabel: "Active/Inactive",
    label: "Active/Inactive",
    minWidth: 10,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
    fontSize: "18px",
    fontWeight: 600,
  },
];

const options = [
  "Title",
  "Franchise",
  "Saved By Users",
  "Start Date",
  "End Date",
  "Type",
  "Status",
  "Actions",
  "Active/Inactive",
];

const useFinch = (finchLoading) => {
  console.log(finchLoading, "finchLoading");
  const { getFranchises } = useFranchises();
  const { getCategories } = useCategories();
  const { request } = useFetch();
  const partnerFinches = useSelector(
    (state) => state.partnerFinchesReducer.partnerFinches
  );

  const [optionsSelected, setOptionSelected] = useState(options);
  const dispatch = useDispatch();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [finchTableData, setFinchTableData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);

  const [filterType, setFilterType] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [filterFranchise, setFilterFranchise] = useState(null);
  const partnerFranchises = useSelector(
    (state) => state.partnerFranchisesReducer.partnerFranchises
  );
  const getAllPartnerFinches = async () => {
    try {
      setFetchLoading(true);
      const req = await request(GET_ALL_PARTNER_FINCHES_API, "GET");
      console.log(req?.data?.data, "req Data for Finches");

      dispatch({
        type: ADD_PARTNER_FINCHES,
        payload: [...(req?.data?.data || [])],
        // payload: req?.data
      });
      handleFinchTable(req?.data?.data, {
        status: filterStatus,
        type: filterType,
        franchise: filterFranchise,
      });
    } catch (err) {
      console.log(err, "err.message");
    } finally {
      setFetchLoading(false);
    }
  };

  let franchise = (franchises) => {
    console.log(franchises, "getting franchises");
    const franchData = franchises.map((item) => {
      const matchingFranchise = partnerFranchises?.find(
        (franchise) => franchise.franchiseId === item.franchiseId
      );
      return matchingFranchise ? matchingFranchise.name : undefined;
    });
    return (
      <Grid container spacing={2}>
        {franchData?.map((v, i) => {
          return (
            <Grid item key={i}>
              <Typography
                sx={{
                  color: Theme.color.basic,
                  backgroundColor: "rgba(151, 255, 175, 1)",
                  fontSize: "16px",
                  px: 2,
                  borderRadius: "5px",
                  fontWeight: "bold",
                  // height: "20px",
                }}
              >
                {v}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const uploadImage = async (image, data) => {
    console.log(image, "UPLOAD:");
    if (image?.type) {
      const imageRef = storage.ref(
        `/goldfinch/${new Date().getTime()}/thumbnail.${getMimeType(
          image?.type
        )}`
      );
      await imageRef.put(image);

      const url = await imageRef.getDownloadURL();
      const final = {
        imageContentType: image?.type,
        image: url,
      };

      return final;
    }

    return image;
  };

  const uploadImages = async (images, data) => {
    try {
      const imagePromises = Array.from(images, (image) => uploadImage(image));

      const imageRes = await Promise.all(imagePromises, data);
      return imageRes;
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleGoldFinchCreation = async (req, callback) => {
    try {
      let reqBody = req;
      const errorsObj = {};
      if (!reqBody.title) {
        errorsObj.title = "Name is a Required";
      }
      if (!reqBody.discountPercentage) {
        errorsObj.discountPercentage = "Discount Percentage is Required";
      }
      if (!reqBody.drenchTimeStart) {
        errorsObj.drenchTimeStart = "Start Time is Required";
      }
      if (!reqBody.drenchTimeEnd) {
        errorsObj.drenchTimeEnd = "End Time is Required";
      }
      if (!reqBody.drenchDescription) {
        errorsObj.drenchDescription = "Description is Required";
      }
      if (!reqBody.notificationMessage) {
        errorsObj.notificationMessage = "Notification Message is Required";
      }

      if (!reqBody.categoriesID || reqBody.categoriesID.length === 0) {
        errorsObj.categoriesID = "Please select Categories";
      }
      if (!reqBody.franchises || reqBody.franchises.length === 0) {
        errorsObj.franchises = "Please select Franchise";
      }
      if (!reqBody.images || reqBody.images.length === 0) {
        errorsObj.images = "At least 1 image is required";
      }
      const current = moment();
      console.log(
        createStatus(reqBody) == "Awaiting",
        !reqBody.finchId,
        'createStatus(reqBody) == "Awaiting" || !reqBody.id'
      );
      if (createStatus(reqBody) == "Awaiting" || !reqBody.finchId) {
        if (
          reqBody.drenchTimeStart &&
          moment(reqBody.drenchTimeStart) < current
        ) {
          errorsObj.drenchTimeStart = "Start date should be after current date";
        }
        if (reqBody.drenchTimeEnd && moment(reqBody.drenchTimeEnd) < current) {
          errorsObj.drenchTimeEnd = "End date should be after current date";
        } else {
          if (reqBody.drenchTimeStart && reqBody.drenchTimeEnd) {
            const diff = moment(reqBody.drenchTimeEnd).diff(
              reqBody.drenchTimeStart,
              "hours"
            );
            if (
              moment(reqBody.drenchTimeEnd).isBefore(
                moment(reqBody.drenchTimeStart)
              )
            ) {
              errorsObj.drenchTimeEnd = "End date should be after start date";
              toast.error("Invalid form data!");
            }
            if (diff > getFinchConfig().hours) {
              // reqBody.drenchTimeEndError = `Total duration should be equal or lower than ${GoldfinchConfig.goldfinch_hours_text}!`;
              errorsObj.drenchTimeEnd = `Total duration should be equal or lower than ${
                getFinchConfig().goldfinch_duration_text
              }!`;
              toast.error("Invalid form data!");
            }
          }
        }
      }

      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }
      setLoading(true);

      if (reqBody.images?.length) {
        const images = await uploadImages(reqBody.images);
        reqBody.images = images;
      }

      setErrors({});

      const createReq = await request(
        reqBody?.finchId ? EDIT_GOLDFINCH_API : CREATE_GOLDFINCH_API,
        "POST",
        reqBody
      );
      toast.success(
        reqBody?.finchId
          ? "GoldFinch edited successfully"
          : "GoldFinch added successfully"
      );

      callback();
      console.log(createReq);
    } catch (error) {
      console.log(error, "error");
      if (error?.error?.message) {
        toast.error(error?.error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDraftCreation = async (req, callback) => {
    try {
      let reqBody = req;
      const errorsObj = {};
      if (!reqBody.title) {
        errorsObj.title = "Name is a Required";
      }

      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }
      setLoading(true);

      if (reqBody.images?.[0]?.type) {
        const images = await uploadImages(reqBody.images);
        reqBody.images = images;
      }

      setErrors({});

      const createReq = await request(
        reqBody?.finchId ? EDIT_DRAFT_FINCH_API : CREATE_DRAFT_FINCH_API,
        "POST",
        reqBody
      );
      toast.success("GoldFinch added as Draft successfully");

      callback();
      console.log(createReq);
    } catch (error) {
      console.log(error, "error");
      if (error?.error?.message) {
        toast.error(error?.error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFinchCreation = async (req, callback) => {
    try {
      let reqBody = req;
      const errorsObj = {};
      if (!reqBody.title) {
        errorsObj.title = "Name is a Required";
      }

      if (!reqBody.discountPercentage) {
        errorsObj.discountPercentage = "Discount Percentage is Required";
      }
      if (!reqBody.drenchTimeStart) {
        errorsObj.drenchTimeStart = "Start Time is Required";
      }
      if (!reqBody.drenchTimeEnd) {
        errorsObj.drenchTimeEnd = "End Time is Required";
      }
      if (!reqBody.drenchDescription) {
        errorsObj.drenchDescription = "Description is Required";
      }
      if (!reqBody.categoriesID || reqBody.categoriesID.length === 0) {
        errorsObj.categoriesID = "Please select Categories";
      }
      if (!reqBody.franchises || reqBody.franchises.length === 0) {
        errorsObj.franchises = "Please select Franchise";
      }
      if (!reqBody.images || reqBody.images.length === 0) {
        errorsObj.images = "At least 1 image is required";
      }
      const current = moment();

      if (createStatus(reqBody) == "Awaiting" || !reqBody.finchId) {
        if (
          reqBody.drenchTimeStart &&
          moment(reqBody.drenchTimeStart) < current
        ) {
          errorsObj.drenchTimeStart = "Start date should be after current date";
        }
        if (reqBody.drenchTimeEnd && moment(reqBody.drenchTimeEnd) < current) {
          errorsObj.drenchTimeEnd = "End date should be after current date";
        } else {
          if (reqBody.drenchTimeStart && reqBody.drenchTimeEnd) {
            const diff = moment(reqBody.drenchTimeEnd).diff(
              reqBody.drenchTimeStart,
              "hours"
            );
            if (
              moment(reqBody.drenchTimeEnd).isBefore(
                moment(reqBody.drenchTimeStart)
              )
            ) {
              errorsObj.drenchTimeEnd = "End date should be after start date";
              toast.error("Invalid form data!");
            }
            if (diff > getFinchConfig().hours) {
              // reqBody.drenchTimeEndError = `Total duration should be equal or lower than ${GoldfinchConfig.goldfinch_hours_text}!`;
              errorsObj.drenchTimeEnd = `Total duration should be equal or lower than ${
                getFinchConfig().goldfinch_duration_text
              }!`;
              toast.error("Invalid form data!");
            }
          }
        }
      }

      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }
      setLoading(true);
      setErrors({});
      if (reqBody.images?.[0].type) {
        const images = await uploadImages(reqBody.images);
        reqBody.images = images;
      }

      const createReq = await request(
        reqBody?.finchId ? EDIT_FINCH_API : CREATE_FINCH_API,
        "POST",
        reqBody
      );

      toast.success(
        reqBody?.finchId
          ? "Finch edited successfully"
          : "Finch added successfully"
      );
    } catch (error) {
      console.log(error.error.message, "error");

      if (error?.error?.message) {
        toast.error(error?.error?.message);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleFinchStatusChange = async (req) => {
    try {
      let reqBody = req;
      const errorsObj = {};

      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }
      setLoading(true);

      const createReq = await request(CHANGE_FINCH_STATUS_API, "POST", reqBody);

      toast.success(
        reqBody?.finchId
          ? "Status Changed successfully"
          : "Status Changed successfully"
      );

      getAllPartnerFinches();
    } catch (error) {
      if (error?.error?.message) {
        toast.error(error?.error?.message);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleFinchDelete = async (req) => {
    try {
      let reqBody = req;
      const errorsObj = {};

      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }
      setLoading(true);

      const createReq = await request(DELETE_FINCH_API, "DELETE", reqBody);

      toast.success(
        reqBody?.finchId
          ? "Finch Deleted successfully"
          : "Finch Deleted successfully"
      );

      setDeleteModal(null);

      getAllPartnerFinches();
    } catch (error) {
      if (error?.error?.message) {
        toast.error(error?.error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  function isCurrentTimeBefore(targetDateString) {
    const currentTime = new Date();
    const targetDate = new Date(targetDateString);
    return currentTime > targetDate;
  }

  const createStatus = (goldfinch) => {
    const timeSpent = calculateTimeSpent(
      new Date(
        (goldfinch?.drenchTimeStart?.seconds ||
          goldfinch?.drenchTimeStart?._seconds) * 1000
      )
    );

    const dateEnd = new Date(
      (goldfinch?.drenchTimeStart?.seconds ||
        goldfinch?.drenchTimeStart?._seconds) * 1000
    );

    const endDate1 = new Date(
      (goldfinch?.drenchTimeEnd?.seconds ||
        goldfinch?.drenchTimeEnd?._seconds) * 1000
    );
    const timeLeft = calculateTimeLeft(
      (goldfinch?.drenchTimeEnd?.seconds ||
        goldfinch?.drenchTimeEnd?._seconds) * 1000
    );
    // console.log(timeSpent, "TIME_SPENT");
    const drenchQuantity = goldfinch?.quantity || 0;
    const drenchQuantityNum = parseInt(drenchQuantity) || 0;
    const totalClaimedLength = goldfinch?.usersWhoHaveClaimed?.length || 0;

    console.log(isCurrentTimeBefore(endDate1), endDate1, goldfinch?.title);

    let text;

    if (!goldfinch) {
      return "Awaiting";
    }

    if (goldfinch?.currentStatus === "Draft") {
      return "Draft";
    }
    if (isCurrentTimeBefore(endDate1)) {
      text = "Expired";
    } else if (!goldfinch?.active) {
      text = "Disabled";
    } else if (
      !(
        drenchQuantity === "UNLIMITED" ||
        drenchQuantity === "" ||
        drenchQuantityNum > totalClaimedLength
      )
    ) {
      //Claimed Qty Exceeded
      text = "Claimed All";
    } else if (timeSpent.asMilliseconds() < 0) {
      text = "Awaiting";
    } else if (timeLeft.asMilliseconds() > 0 && timeLeft.asHours() < 5) {
      text = "Dying Up";
    } else if (timeLeft.asMilliseconds() > 0) {
      text = "Active";
    } else {
      text = "Expired";
    }
    return text;
  };

  const colorStatus = (status) => {
    switch (status) {
      case "Claimed All":
        return "#1B5E20";
      case "Awaiting":
        return "#11A4C2";
      case "Dying Up":
        return "#4950BA";
      case "Active":
        return "#1CBC3C";
      case "Draft":
        return "#4F5C6A";
      default:
        return "#DC2845";
    }
  };

  const handleFinchTable = (data = [], filterObj = {}) => {
    setFinchTableData([
      ...data
        ?.filter((v) =>
          filterObj.status ? filterObj.status === createStatus(v) : true
        )
        ?.filter((v) =>
          filterObj.franchise
            ? v.franchises.find((v) => v.franchiseId === filterObj.franchise)
            : true
        )
        ?.filter((v) =>
          filterObj.type
            ? filterObj.type ===
              (v?.type === "drenches" ? "GoldFinch" : "Finch")
            : true
        )
        ?.map((v, i) => {
          return {
            title: textLiner(Theme.color.text, "18px", v?.title),
            franchise: franchise(v.franchises),
            savedByUsers: textLiner(
              Theme.color.text,
              "18px",
              v?.usersWhoHaveClaimed ? v?.usersWhoHaveClaimed.length : 0
            ),
            startDate: v?.drenchTimeStart?._seconds
              ? textLiner(
                  Theme.color.text,
                  "18px",
                  v?.drenchTimeStart?._seconds,
                  "",
                  true
                )
              : "",
            endDate: v?.drenchTimeEnd?._seconds
              ? textLiner(
                  Theme.color.text,
                  "18px",
                  v?.drenchTimeEnd?._seconds,
                  "",
                  true
                )
              : "",
            type: textLiner(
              v?.type === "drenches" ? Theme.color.golden : "#00C3EB",
              "16px",
              v?.type === "drenches" ? "Goldfinch" : "Finches",
              percentToOpacity(
                25,
                v?.type === "drenches" ? Theme.color.golden : "#00C3EB"
              )
            ),
            status: textLiner(
              colorStatus(createStatus(v)),
              "16px",
              createStatus(v),
              percentToOpacity(25, colorStatus(createStatus(v)))
            ),
            actions: (
              <Stack direction="row" spacing={1} justifyContent="center">
                <IconButton
                  aria-label="flag"
                  disableElevation={true}
                  disableRipple
                  sx={{
                    backgroundColor:
                      v.currentStatus === "Draft"
                        ? "#4F5C6A"
                        : createStatus(v) === "Expired"
                        ? "#009BEB"
                        : Theme.bg.lightGreen,
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setSelectedId(v.id);

                    if (v.currentStatus === "Draft") {
                      setSidebarOpen("Draft");
                      return;
                    }

                    if (createStatus(v) === "Expired") {
                      setSidebarOpen("Duplicate");
                      return;
                    }

                    if (v?.type === "drenches") {
                      setSidebarOpen("GoldFinch");
                    } else {
                      setSidebarOpen("Finch");
                    }
                  }}
                >
                  {v.currentStatus === "Draft" ? (
                    <InventoryIcon sx={{ color: Theme.color.white }} />
                  ) : createStatus(v) === "Expired" ? (
                    <BiDuplicate color={Theme.color.white} />
                  ) : (
                    <VscEdit color={Theme.color.white} />
                  )}
                </IconButton>
                <IconButton
                  aria-label="delete"
                  disableElevation={true}
                  disableRipple
                  sx={{
                    backgroundColor: Theme.bg.lightRed,
                    borderRadius: "5px",
                  }}
                  onClick={() => setDeleteModal(v.id)}
                >
                  <RiDeleteBinLine color={Theme.color.white} />
                </IconButton>
              </Stack>
            ),
            active: (
              <Switch
                checked={v?.active}
                onChange={() => {
                  handleFinchStatusChange({
                    finchId: v.id,
                    active: !v?.active,
                  });
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            ),
          };
        }),
    ]);
  };

  useEffect(() => {
    handleFinchTable(partnerFinches || [], {
      status: filterStatus,
      type: filterType,
      franchise: filterFranchise,
    });
  }, [
    filterType,
    filterStatus,
    filterFranchise,
    partnerFinches?.length,
    finchLoading,
  ]);

  useEffect(() => {
    getCategories();
    getFranchises();
  }, []);

  useEffect(() => {
    getAllPartnerFinches();
  }, [sidebarOpen, deleteModal]);

  const handleColumnSelected = (column, isChecked) => {
    if (isChecked) {
      // Add column to visibleColumns if checked
      setOptionSelected((prevVisibleColumns) => [
        ...prevVisibleColumns,
        column,
      ]);
    } else {
      // Remove column from visibleColumns if unchecked
      setOptionSelected((prevVisibleColumns) =>
        prevVisibleColumns.filter((item) => item !== column)
      );
    }
  };

  const handleDraftStatus = async (req) => {
    try {
      let reqBody = req;
      const errorsObj = {};
      if (!reqBody.title) {
        errorsObj.title = "Name is a Required";
      }
      if (!reqBody.discountPercentage) {
        errorsObj.discountPercentage = "Discount Percentage is Required";
      }
      if (!reqBody.drenchTimeStart) {
        errorsObj.drenchTimeStart = "Start Time is Required";
      }
      if (!reqBody.drenchTimeEnd) {
        errorsObj.drenchTimeEnd = "End Time is Required";
      }
      if (!reqBody.drenchDescription) {
        errorsObj.drenchDescription = "Description is Required";
      }
      if (reqBody.type == "drenches") {
        if (!reqBody.notificationMessage) {
          errorsObj.notificationMessage = "Notification Message is Required";
        }
      }

      if (!reqBody.categoriesID || reqBody.categoriesID.length === 0) {
        errorsObj.categoriesID = "Please select Categories";
      }
      if (!reqBody.franchises || reqBody.franchises.length === 0) {
        errorsObj.franchises = "Please select Franchise";
      }
      if (!reqBody.images || reqBody.images.length === 0) {
        errorsObj.images = "At least 1 image is required";
      }

      const current = moment();

      if (createStatus(reqBody) == "Awaiting" || !reqBody.finchId) {
        if (
          reqBody.drenchTimeStart &&
          moment(reqBody.drenchTimeStart) < current
        ) {
          errorsObj.drenchTimeStart = "Start date should be after current date";
        }
        if (reqBody.drenchTimeEnd && moment(reqBody.drenchTimeEnd) < current) {
          errorsObj.drenchTimeEnd = "End date should be after current date";
        } else {
          if (reqBody.drenchTimeStart && reqBody.drenchTimeEnd) {
            const diff = moment(reqBody.drenchTimeEnd).diff(
              reqBody.drenchTimeStart,
              "hours"
            );
            if (
              moment(reqBody.drenchTimeEnd).isBefore(
                moment(reqBody.drenchTimeStart)
              )
            ) {
              errorsObj.drenchTimeEnd = "End date should be after start date";
              toast.error("Invalid form data!");
            }
            if (diff > getFinchConfig().hours) {
              // reqBody.drenchTimeEndError = `Total duration should be equal or lower than ${GoldfinchConfig.goldfinch_hours_text}!`;
              errorsObj.drenchTimeEnd = `Total duration should be equal or lower than ${
                getFinchConfig().goldfinch_duration_text
              }!`;
              toast.error("Invalid form data!");
            }
          }
        }
      }

      console.log(errorsObj, "errorsObj", reqBody.type);

      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }
      setLoading(true);

      setErrors({});

      if (reqBody.images?.[0]?.type) {
        const images = await uploadImages(reqBody.images);
        reqBody.images = images;
      }

      const createReq = await request(ACTIVATE_DRAFT_API, "POST", reqBody);

      toast.success("Finch Scheduled successfully");

      getAllPartnerFinches();
    } catch (error) {
      console.log(error);
      if (error?.error?.message) {
        toast.error(error?.error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    getAllPartnerFinches,
    uploadImages,
    handleGoldFinchCreation,
    errors,
    loading,
    handleFinchCreation,
    finchData: finchTableData,
    columns,
    options,
    selectedId,
    setSelectedId,
    sidebarOpen,
    setSidebarOpen,
    deleteModal,
    setDeleteModal,
    handleFinchDelete,
    handleColumnSelected,
    optionsSelected,
    setFilterType,
    setFilterStatus,
    setFilterFranchise,
    filterType,
    filterStatus,
    filterFranchise,
    partnerFinches,
    handleDraftCreation,
    handleDraftStatus,
    fetchLoading,
    createStatus,
    colorStatus,
  };
};

export default useFinch;
