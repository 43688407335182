import { Box, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { Theme } from "../../Theme";
import FranchiseCard from "../Cards/FranchiseCard";
import Tick_IMG from "../../Assets/Image/Tick.png";
import CampaignCard from "../Cards/CampaignsCard";
import SideBar from "../../Components/Sidebar";
import { useSelector } from "react-redux";

const PackagesComp = ({ onFranchise, onCampaign }) => {
  const packages = useSelector((state) => state.paymentReducer.packages);
  const paymentMethods = useSelector(
    (state) => state.paymentReducer.paymentMethods
  );

  console.log(paymentMethods, "paymentMethods");

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <Box sx={{ backgroundColor: "#fff", p: 2, borderRadius: "8px" }}>
      {isSmallScreen ? (
        <Paper sx={{ borderRadius: "8px", p: 1 }}>
          <Box sx={{ my: 2 }}>
            <Typography
              sx={{
                fontFamily: "Urbanist-Bold",
                fontSize: "22px",
                mb: 2,
                backgroundColor: "#32C28C",
                color: "#fff",
                width: "auto",
                py: 1,
                px: 1,
                borderRadius: "8px",
              }}
            >
              Franchise Packages
            </Typography>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-evenly", gap: 2 }}
            >
              {packages?.franchise?.map((v, i) => {
                return (
                  <FranchiseCard
                    key={i}
                    data={v}
                    index={i}
                    onClose={onFranchise}
                  />
                );
              })}
            </Grid>
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography
              sx={{
                fontFamily: "Urbanist-Bold",
                fontSize: "22px",
                mb: 2,
                backgroundColor: "#32C28C",
                color: "#fff",
                width: "auto",
                py: 1,
                px: 1,
                borderRadius: "8px",
              }}
            >
              Campaign Packages
            </Typography>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-evenly", gap: 2 }}
            >
              <CampaignCard
                data={{
                  head: "Packages",
                  plan: "",
                  package: "",
                  items: [
                    { item: "Number of GoldFinches" },
                    { item: "Number of Finches" },
                    { item: "Unlimited Notifications" },
                    { item: "Analytics" },
                  ],
                  button: false,
                }}
              />
              {packages?.campaigns
                ?.sort((a, b) => a.price - b.price)
                ?.map((v, i) => {
                  return (
                    <CampaignCard
                      data={{
                        head: v?.name,
                        plan: v?.name,
                        package: v?.type,
                        price: v?.price,
                        items: [
                          {
                            item: v.numberOfGoldFinches,
                          },
                          {
                            item: v.numberOfFinches,
                          },
                          {
                            item: v.unlimitedNotifications ? "Yes" : "No",
                          },
                          {
                            item: v.analytics ? "Yes" : "No",
                          },
                        ],
                        button: true,
                        details: v,
                      }}
                      key={i}
                      index={i}
                      onClose={onCampaign}
                    />
                  );
                })}
            </Grid>
          </Box>
        </Paper>
      ) : (
        <Box
          sx={{
            border: "2px solid #FFD831",
            borderRadius: "8px",
            py: 2,
            px: 6,
          }}
        >
          <Box sx={{ my: 4 }}>
            <Typography
              sx={{ fontFamily: "Urbanist-Bold", fontSize: "22px", mb: 2 }}
            >
              Franchise Packages
            </Typography>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-evenly" }}
            >
              {packages?.franchise?.map((v, i) => {
                return (
                  <FranchiseCard
                    key={i}
                    data={v}
                    index={i}
                    onClose={onFranchise}
                  />
                );
              })}
            </Grid>
          </Box>
          <Box sx={{ my: 4 }}>
            <Typography
              sx={{ fontFamily: "Urbanist-Bold", fontSize: "22px", mb: 2 }}
            >
              Campaigns Packages
            </Typography>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <CampaignCard
                data={{
                  head: "Packages",
                  plan: "",
                  package: "",
                  items: [
                    { item: "Number of GoldFinches" },
                    { item: "Number of Finches" },
                    { item: "Unlimited Notifications" },
                    { item: "Analytics" },
                  ],
                  button: false,
                }}
              />
              {packages?.campaigns
                ?.sort((a, b) => a.price - b.price)
                ?.map((v, i) => {
                  return (
                    <CampaignCard
                      data={{
                        head: v?.name,
                        plan: v?.name,
                        package: v?.type,
                        price: v?.price,
                        items: [
                          {
                            item: v.numberOfGoldFinches,
                          },
                          {
                            item: v.numberOfFinches,
                          },
                          {
                            item: v.unlimitedNotifications ? "Yes" : "No",
                          },
                          {
                            item: v.analytics ? "Yes" : "No",
                          },
                        ],
                        button: true,
                        details: v,
                      }}
                      key={i}
                      index={i}
                      onClose={onCampaign}
                    />
                  );
                })}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PackagesComp;
