import React, { useState } from "react";
import DashLogo from "../../Assets/SVG/Dashlogo.svg";
import CreateCompain from "../../Assets/SVG/CreateCampaign.svg";
import SavedGoldFinches from "../../Assets/SVG/SavedGoldFinches.svg";
import AddFranchise from "../../Assets/SVG/AddFranchise.svg";
import Analytics from "../../Assets/SVG/Analytics.svg";
import BuyPackages from "../../Assets/SVG/BuyPackages.svg";
import Logout from "../../Assets/SVG/Logout.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { RiDeleteBinLine } from "react-icons/ri";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Box,
  Stack,
  IconButton as IconBtn,
  Typography,
  Grid,
  Button,
  IconButton,
  Checkbox,
  Paper,
  useMediaQuery,
} from "@mui/material";
import IconFeather_IMG from "../../Assets/SVG/Iconfeather.svg";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StickyHeadTable from "../../Components/Table/index.jsx";
import { styled } from "@mui/material/styles";
import { VscEdit } from "react-icons/vsc";
import { BiAddToQueue } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { RiArrowDropUpFill } from "react-icons/ri";
import { RiArrowDropDownFill } from "react-icons/ri";
import { Theme } from "../../Theme";
import GradientBox from "../../Components/GradientBox";
import SideBar from "../../Components/Sidebar";
import CreateFinch from "../../Components/Forms/CreateFinch";
import CreateGoldFinch from "../../Components/Forms/CreateGoldFinch";
import { BsSearch } from "react-icons/bs";
import BasicModal from "../../Components/Modal";
import DeleteComp from "../../Components/Modal/DeleteComp";
import DateChangeComp from "../../Components/Modal/DateChangeComp";
import CancelSubsComp from "../../Components/Modal/CancelSubsComp";
import "../../App.css";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import ExpandableSearchBar from "../../Components/Inputs/ExpandableSearchBar";
import CategoriesForm from "../../Components/Forms/CategoriesForm";
import SimpleMenu from "../../Components/Menu";
import TableMenu from "../../Components/Menu/TableMenu";

const CategoriesList = () => {
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignSidebar, setCampaignSidebar] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [categoriesForm, setCategoriesForm] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const menuOpen1 = Boolean(anchorEl1);
  // const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // const [count, setCount] = useState(user?.count || 0);
  // const [nextPage, setNextPage] = useState(userData?.next);
  // const [previousPage, setPreviousPage] = useState(userData?.previous);

  const titleLiner = (title) => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
        {title}
      </Typography>
      {/* <Stack sx={{ m: 0, p: 0 }}>
        <RiArrowDropUpFill style={{ marginBottom: -5 }} />
        <RiArrowDropDownFill style={{ marginTop: -5 }} />
      </Stack> */}
    </Box>
  );

  const textLiner = (color, fontSize, text, bgColor = null) => {
    return (
      <Typography
        sx={{
          color: color,
          fontSize: fontSize,
          backgroundColor: bgColor,
          borderRadius: "4px",
          //   px: 1,
          fontFamily: "Urbanist-Semibold",
        }}
      >
        {text}
      </Typography>
    );
  };

  const handleMenuClick = (event, state, id) => {
    if (state === "table") {
      setAnchorEl1(event.currentTarget);
    }
    // else if (state === "column") {
    //   // setAnchorEl2(event.currentTarget);
    // } else if (state === "table") {
    //   // setAnchorEl3(event.currentTarget);
    // } else if (state === "Suspend") {
    //   setOpen(true)
    // }
  };

  const handleClick = (event, id) => {
    setSelectedId(id);
    if (event === "delete") {
      handleModal(id);
    }
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleModal = (id) => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //   const handleCheckboxChange = (index) => {
  //     // Create a copy of the userData array
  //     const updatedUserData = [...userData];

  //     // Toggle the checked property of the specific object
  //     updatedUserData[index].checked = !updatedUserData[index].checked;

  //     // Update the state with the new userData
  //     setUserData(updatedUserData);
  //   };

  let franchises = ["Calgary", "Line Chest", "Acura", "My Home"];
  let userData = [
    {
      categoriesName: textLiner(Theme.color.text, "18px", "Automobile"),
      status: textLiner(
        Theme.color.red,
        "16px",
        "Inactive",
        "rgba(220, 40, 69, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            onClick={(e) => setCategoriesForm(true)}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <RiDeleteBinLine color={Theme.color.white} />
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleMenuClick(e, "table")}
            // sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <MoreVertOutlinedIcon sx={{ color: Theme.color.basic }} />
          </IconBtn>
        </Stack>
      ),
    },
    {
      categoriesName: textLiner(Theme.color.text, "18px", "Computer"),
      status: textLiner(
        Theme.color.red,
        "16px",
        "Inactive",
        "rgba(220, 40, 69, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <RiDeleteBinLine color={Theme.color.white} />
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            // sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <MoreVertOutlinedIcon sx={{ color: Theme.color.basic }} />
          </IconBtn>
        </Stack>
      ),
    },
    {
      categoriesName: textLiner(Theme.color.text, "18px", "Electronics"),
      status: textLiner(
        Theme.color.red,
        "16px",
        "Inactive",
        "rgba(220, 40, 69, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <RiDeleteBinLine color={Theme.color.white} />
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            // sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <MoreVertOutlinedIcon sx={{ color: Theme.color.basic }} />
          </IconBtn>
        </Stack>
      ),
    },
    {
      categoriesName: textLiner(Theme.color.text, "18px", "Home Accessories"),
      status: textLiner(
        Theme.color.darkGreen,
        "16px",
        "Active",
        "rgba(28, 188, 60, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <RiDeleteBinLine color={Theme.color.white} />
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            // sx={{ backgroundColor: Theme.bg.lightRed, borderRadius: "5px" }}
          >
            {/* <AiOutlineEye color={Theme.color.white} /> */}
            <MoreVertOutlinedIcon sx={{ color: Theme.color.basic }} />
          </IconBtn>
        </Stack>
      ),
    },
  ];
  //static pagination
  let paginatedData = {
    count: 20,
    next: 2,
    previous: null,
    results: userData,
  };
  const [users, setUsers] = useState(paginatedData.results);
  const [currentPage, setCurrentPage] = useState(1);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const getUsers = (page) => {
    if (page === 1) {
      setUsers(paginatedData.results);
      setCurrentPage(1);
    } else if (page === 2) {
      setUsers(paginatedData.results);
      setCurrentPage(2);
    } else {
    }
  };
  const columns = [
    {
      id: "categoriesName",
      label: titleLiner("Title"),
      minWidth: isSmallScreen ? 200 : 600,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "status",
      label: titleLiner("Status"),
      minWidth: 100,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "actions",
      label: titleLiner("Actions"),
      minWidth: 10,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
  ];

  let options = ["Activate", "Deactivate"];

  return (
    <Box sx={{ p: 1, height: "auto" }}>
      {!isSmallScreen ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 3,
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <Typography
              sx={{
                fontFamily: "Urbanist-Bold",
                fontSize: "32px",
                // fontSize: { xs: "24px", sm: "24px", md: "34px" },
              }}
            >
              App Users
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            {/* <Button
              variant="outlined"
              style={{
                color: "#88949E",
                borderColor: "#E7EAEE",
                borderRadius: 5,
                padding: 10,
              }}
            >
              <BsSearch style={{ fontSize: 20 }} />
            </Button> */}
            <ExpandableSearchBar />
            <PrimaryButton
              text={"Add Categories"}
              // onClick={handleSignUp}
              onClick={(e) => setCategoriesForm(true)}
              size="small"
              sx={{
                color: Theme.color.basic,
                backgroundColor: Theme.bg.yellowBtn,
                borderRadius: "4px",
                px: 4,
                fontSize: "16px",
                "&.MuiButton-root": {
                  "&:hover": {
                    backgroundColor: Theme.bg.yellowBtn,
                    borderColor: Theme.bg.yellowBtn,
                    // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                  },
                },
                fontFamily: "Urbanist-Bold",
                // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              }}
            />
          </Box>
        </Box>
      ) : (
        <Paper sx={{ px: 1, py: 2, my: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // my: 2,
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
              <Typography
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "32px",
                  // fontSize: { xs: "24px", sm: "24px", md: "34px" },
                }}
              >
                App Users
              </Typography>
              {/* <img src={IconFeather_IMG} alt="" /> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                width: "100%",
                justifyContent: {
                  xs: "space-between",
                  sm: "space-between",
                  md: "none",
                },
              }}
            >
              {/* <Button
                variant="outlined"
                style={{
                  color: "#88949E",
                  borderColor: "#E7EAEE",
                  borderRadius: 5,
                  padding: 10,
                }}
              >
                <BsSearch style={{ fontSize: 20 }} />
              </Button> */}
              <PrimaryButton
                text={"Add Categories"}
                // onClick={handleSignUp}
                onClick={(e) => setCategoriesForm(true)}
                size="small"
                sx={{
                  color: Theme.color.basic,
                  backgroundColor: Theme.bg.yellowBtn,
                  borderRadius: "4px",
                  px: { xs: 2, sm: 2, md: 4 },
                  width: "100%",
                  fontSize: "16px",
                  "&.MuiButton-root": {
                    "&:hover": {
                      backgroundColor: Theme.bg.yellowBtn,
                      borderColor: Theme.bg.yellowBtn,
                      // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                    },
                  },
                  fontFamily: "Urbanist-Bold",
                  // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                }}
              />
            </Box>
          </Box>
        </Paper>
      )}
      <Box sx={{ my: 2 }}>
        <StickyHeadTable
          columns={columns}
          rows={paginatedData.results}
          count={paginatedData?.count || 0}
          next={
            paginatedData?.next
              ? (callback) => getUsers(users?.next, callback)
              : null
          }
          previous={
            paginatedData?.previous
              ? (callback) => getUsers(users?.previous, callback)
              : null
          }
          loading={loading}
        />
      </Box>
      <SimpleMenu
        handleClose={handleClose1}
        open={menuOpen1}
        anchorEl={anchorEl1}
        data={
          <TableMenu
            handleClose={handleClose1}
            selectedId={selectedId}
            handleOptionClick={handleMenuClick}
            options={options}
          />
        }
      />
      <SideBar
        open={categoriesForm}
        onClose={() => setCategoriesForm(false)}
        title={selectedId ? "Edit Category" : "Create Category"}
        form={
          <CategoriesForm
            id={selectedId}
            // onEdit={getAllClients}
            onClose={() => setCategoriesForm(false)}
          />
        }
      />
      {/* Delete Modal */}
      <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<DeleteComp />}
      />

      {/* Date Change Modal */}
      {/* <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<DateChangeComp />}
      /> */}

      {/* Cancel Subcription Modal */}
      {/* <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<CancelSubsComp />}
      /> */}
    </Box>
  );
};

export default CategoriesList;
