import {
  DASHBOARD_PATH,
  SAVED_GOLD_FINCHES_PATH,
  ADD_FRANCHISE_PATH,
  ANALYTICS_PATH,
  SUBSCRIPTION_PATH,
  ACCOUNT_REQUEST_PATH,
  ACTIVE_CAMPAIGNS_PATH,
  USERS_FEEDBACK_PATH,
  CATEGORIES_LIST_PATH,
  APP_USERS_PATH,
  FREE_REWARDS_PATH,
  EMAIL_BROADCAST_PATH,
  PRIVACY_POLICY_PATH,
} from "../Constants/Paths";

import CREATE_CAMPAIGN_IMG from "../Assets/Image/CreateCampaign.png";
import SAVED_GOLDFINCHES_IMG from "../Assets/Image/SavedGoldFinches.png";
import ADD_FRANCHISE_IMG from "../Assets/Image/AddFranchise.png";
import ANALYTICS_IMG from "../Assets/Image/Analytics.png";
import SUBSCRIPTION_IMG from "../Assets/Image/Subscription.png";
import ACCOUNT_REQUEST_IMG from "../Assets/Image/AccountRequests.png";
import FREE_REWARDS_IMG from "../Assets/Image/FreeRewards.png";
import ACTIVE_CAMPAIGNS_IMG from "../Assets/Image/ActiveCampaigns.png";
import EMAIL_BROADCAST_IMG from "../Assets/Image/EmailBroadcast.png";
import APP_USERS_IMG from "../Assets/Image/AppUsers.png";
import USERS_FEEDBACK_IMG from "../Assets/Image/UsersFeedback.png";
import CATEGORIES_LIST_IMG from "../Assets/Image/CategoriesList.png";
import PRIVACY_POLICY_IMG from "../Assets/Image/PrivacyPolicy.png";

//black images
import CREATE_CAMPAIGN_BLACK_IMG from "../Assets/Image/CreateCampaignBlack.png";
import SAVED_GOLDFINCHES_BLACK_IMG from "../Assets/Image/SavedGoldFinchesBlack.png";
import ADD_FRANCHISE_BLACK_IMG from "../Assets/Image/AddFranchiseBlack.png";
import ANALYTICS_BLACK_IMG from "../Assets/Image/AnalyticsBlack.png";
import SUBSCRIPTION_BLACK_IMG from "../Assets/Image/SubscriptionBlack.png";
import ACCOUNT_REQUEST_BLACK_IMG from "../Assets/Image/AccountRequestsBlack.png";
import FREE_REWARDS_BLACK_IMG from "../Assets/Image/FreeRewardsBlack.png";
import ACTIVE_CAMPAIGNS_BLACK_IMG from "../Assets/Image/ActiveCampaignsBlack.png";
import EMAIL_BROADCAST_BLACK_IMG from "../Assets/Image/EmailBroadcastBlack.png";
import APP_USERS_BLACK_IMG from "../Assets/Image/AppUsersBlack.png";
import USERS_FEEDBACK_BLACK_IMG from "../Assets/Image/UsersFeedbackBlack.png";
import CATEGORIES_LIST_BLACK_IMG from "../Assets/Image/CategoriesListBlack.png";
import PRIVACY_POLIC_BLACK_IMG from "../Assets/Image/PrivacyPolicyBlack.png";

const ROUTES = [
  {
    title: "Subscription",
    icon: SUBSCRIPTION_IMG,
    iconBlack: SUBSCRIPTION_BLACK_IMG,
    sidebar: true,
    path: SUBSCRIPTION_PATH,
    userType: "Admin",
  },
  {
    title: "Account Requests",
    icon: ACCOUNT_REQUEST_IMG,
    iconBlack: ACCOUNT_REQUEST_BLACK_IMG,
    sidebar: true,
    path: ACCOUNT_REQUEST_PATH,
    userType: "Admin",
  },
  {
    title: "Free Rewards",
    icon: FREE_REWARDS_IMG,
    iconBlack: FREE_REWARDS_BLACK_IMG,
    sidebar: true,
    path: FREE_REWARDS_PATH,
    userType: "Admin",
  },
  {
    title: "Active Campaigns",
    icon: ACTIVE_CAMPAIGNS_IMG,
    iconBlack: ACTIVE_CAMPAIGNS_BLACK_IMG,
    sidebar: true,
    path: ACTIVE_CAMPAIGNS_PATH,
    userType: "Admin",
  },
  {
    title: "Email Broadcast",
    icon: EMAIL_BROADCAST_IMG,
    iconBlack: EMAIL_BROADCAST_BLACK_IMG,
    sidebar: true,
    path: EMAIL_BROADCAST_PATH,
    userType: "Admin",
  },
  {
    title: "Analytics",
    icon: ANALYTICS_IMG,
    iconBlack: ANALYTICS_BLACK_IMG,
    sidebar: true,
    path: ANALYTICS_PATH,
    userType: "Admin",
  },
  {
    title: "App Users",
    icon: APP_USERS_IMG,
    iconBlack: APP_USERS_BLACK_IMG,
    sidebar: true,
    path: APP_USERS_PATH,
    userType: "Admin",
  },
  {
    title: "Users Feedback",
    icon: USERS_FEEDBACK_IMG,
    iconBlack: USERS_FEEDBACK_BLACK_IMG,
    sidebar: true,
    path: USERS_FEEDBACK_PATH,
    userType: "Admin",
  },
  {
    title: "Categories List",
    icon: CATEGORIES_LIST_IMG,
    iconBlack: CATEGORIES_LIST_BLACK_IMG,
    sidebar: true,
    path: CATEGORIES_LIST_PATH,
    userType: "Admin",
  },
  {
    title: "Privacy Policy",
    icon: PRIVACY_POLICY_IMG,
    iconBlack: PRIVACY_POLIC_BLACK_IMG,
    sidebar: true,
    path: PRIVACY_POLICY_PATH,
    userType: "Admin",
  },
  {
    title: "Create Campaigns",
    icon: CREATE_CAMPAIGN_IMG,
    iconBlack: CREATE_CAMPAIGN_BLACK_IMG,
    sidebar: true,
    path: DASHBOARD_PATH,
    userType: "Partner",
  },
  // {
  //   title: "Saved GoldFinches",
  //   icon: SAVED_GOLDFINCHES_IMG,
  //   iconBlack: SAVED_GOLDFINCHES_BLACK_IMG,
  //   sidebar: true,
  //   path: SAVED_GOLD_FINCHES_PATH,
  //   userType: "Partner",
  // },
  {
    title: "Add Franchise",
    icon: ADD_FRANCHISE_IMG,
    iconBlack: ADD_FRANCHISE_BLACK_IMG,
    sidebar: true,
    path: ADD_FRANCHISE_PATH,
    userType: "Partner",
  },
  {
    title: "Analytics",
    icon: ANALYTICS_IMG,
    iconBlack: ANALYTICS_BLACK_IMG,
    sidebar: true,
    path: ANALYTICS_PATH,
    userType: "Partner",
  },
];
export default ROUTES;
