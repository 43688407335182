import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import SimpleSelect from "../Inputs/SimpleSelect";
import { Theme } from "../../Theme";
import "../../App.css";

export default function ColumnMenu({
  handleOptionClick = () => {},
  options,
  optionsSelected,
}) {
  const handleReset = () => {
    options.map((v) => handleOptionClick(v, true));
  };
  return (
    <Box sx={{ mx: 3, py: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontFamily: "Urbanist-Bold" }}>Filter</Typography>
        <Button variant="text" onClick={handleReset}>
          RESET
        </Button>
      </Box>
      <Box>
        <FormGroup>
          {options.map((v, i) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      // color: Theme.color.yellow,
                      "&.Mui-checked": {
                        color: Theme.color.yellow,
                      },
                    }}
                    onChange={(e) => handleOptionClick(v, e.target.checked)}
                    checked={optionsSelected.includes(v)}
                  />
                }
                label={v}
              />
            );
          })}
        </FormGroup>
      </Box>
    </Box>
  );
}
