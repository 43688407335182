import { Box, Typography } from "@mui/material";
import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";
import { RiDeleteBinLine } from "react-icons/ri";

const DeleteComp = ({ onClose, onSubmit, loading }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {/* <Lottie /> */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            background:
              "transparent linear-gradient(39deg, #FDEEF2 0%, #FCC2CE 100%) 0% 0% no-repeat padding-box",
            p: 2,
            borderRadius: "50%",
          }}
        >
          <RiDeleteBinLine
            style={{ color: Theme.color.darkRed, margin: "0 auto" }}
            size={24}
          />
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: "28px",
          color: Theme.color.darkRed,
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        Delete Goldfinch
      </Typography>
      <Typography
        sx={{ fontSize: "20px", color: "#8F8F8F", textAlign: "center" }}
      >
        Are you sure you want to delete this Goldfinch? This action can not be
        undone.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
        <PrimaryButton
          text={"Cancel"}
          elevation={3}
          size="large"
          //   fullWidth
          sx={{
            width: "100px",
            color: Theme.color.white,
            backgroundColor: "#636363",
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: "#636363",
                borderColor: "#636363",
              },
            },
          }}
          onClick={onClose}
        />
        <PrimaryButton
          text={"Delete"}
          elevation={3}
          size="large"
          // fullWidth
          sx={{
            width: "100px",
            color: Theme.color.white,
            backgroundColor: Theme.bg.redBtn,
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: Theme.bg.redBtn,
                borderColor: Theme.bg.redBtn,
              },
            },
          }}
          onClick={onSubmit}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default DeleteComp;
