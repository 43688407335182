import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchBar from "./SearchBar";
import { Theme } from "../../Theme";
import { BsSearch } from "react-icons/bs";

function ExpandableSearchBar(props) {
  const [query, setQuery] = useState("");
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(true);
  };

  const handleCollapseClick = () => {
    setExpanded(false);
    setQuery("");
  };

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: "5px",
        display: "flex",
        marginLeft: 0,
      }}
    >
      {expanded ? <SearchBar {...props} /> : null}
      {expanded ? (
        <IconButton
          onClick={handleCollapseClick}
          style={{
            color: "#88949E",
            borderColor: "#E7EAEE",
            borderRadius: 5,
            padding: 10,
          }}
        >
          <CloseIcon style={{ color: Theme.color.basic }} />
        </IconButton>
      ) : (
        <IconButton
          onClick={handleExpandClick}
          style={{
            color: "#88949E",
            borderColor: "#E7EAEE",
            borderRadius: 5,
            padding: 10,
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <BsSearch style={{ fontSize: 20 }} />
        </IconButton>
      )}
    </Box>
  );
}

export default ExpandableSearchBar;
