import { Box, Grid, InputLabel, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { Theme } from "../../Theme";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import SimpleSelect from "../../Components/Inputs/SimpleSelect";
import "./index.css";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import '../../App.css'

const EmailBroadcast = () => {
  const [editorState, setEditorState] = useState(EditorState?.createEmpty());
  return (
    <Box sx={{ p: 1, backgroundColor: Theme.bg.bg }}>
      <Box sx={{ my: 3 }}>
        <Typography style={{ fontFamily: "Urbanist-Bold",fontSize:"32px" }}>
          Email Broadcast
        </Typography>
      </Box>
      <Box>
        <Paper sx={{ py: 4, px: 2, }}>
          <Box sx={{ mb: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  htmlFor="bootstrap-input"
                  sx={{ fontFamily: "Urbanist-Bold", color: Theme.color.basic }}
                >
                  Type
                </InputLabel>
                <SimpleSelect
                  size="small"
                  // options={options}
                  fullWidth
                  label={"User"}
                  style={{
                    px: 10,
                    backgroundColor: Theme.bg.bg,
                    border: "1px solid #E7EAEE",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  htmlFor="bootstrap-input"
                  sx={{ fontFamily: "Urbanist-Bold", color: Theme.color.basic }}
                >
                  Title
                </InputLabel>
                <SimpleSelect
                  size="small"
                  // options={options}
                  fullWidth
                  label={"Input Title"}
                  style={{
                    px: 10,
                    backgroundColor: Theme.bg.bg,
                    border: "1px solid #E7EAEE",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Editor
            editorState={editorState}
            spellCheck={true}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="my-editor-className"
            onEditorStateChange={setEditorState}
          />
          <Box sx={{display:"flex",justifyContent:'center'}}>
            <PrimaryButton
              text={"SEND"}
            //   onClick={handleResetPassword}
              // fullWidth
              sx={{
                color: Theme.color.basic,
                backgroundColor: Theme.bg.yellow,
                borderRadius: "8px",
                // my: 6,
                mt: 4,
                width: "600px",
                fontSize: "18px",
                "&.MuiButton-root": {
                  "&:hover": {
                    backgroundColor: Theme.bg.yellow,
                    borderColor: Theme.bg.yellow,
                    boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                  },
                },
                boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                fontFamily: "Urbanist-Bold",
              }}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default EmailBroadcast;
