import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Grid, InputLabel, Typography } from "@mui/material";
import SimpleSelect from "../Inputs/SimpleSelect";
import { Theme } from "../../Theme";
import "../../App.css";

export default function SubscriptionFilter({
  handleClose,
  handleOptionClick = () => {},
  open,
  anchorEl,
  selectedId,
  options,
}) {
  return (
    <Box sx={{ px: 1, py: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontFamily: "Urbanist-Bold" }}>Filter</Typography>
        <Button variant="text">RESET</Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Grid container spacing={1}>
          <Grid item>
            <InputLabel>Package Type</InputLabel>
            <SimpleSelect
              size="small"
              // options={options}
              //   fullWidth
              label={"All"}
              style={{
                px: 10,
                backgroundColor: Theme.bg.bg,
                border: "1px solid #E7EAEE",
              }}
            />
          </Grid>
          <Grid item>
            <InputLabel>Period Start</InputLabel>
            <SimpleSelect
              size="small"
              // options={options}
              //   fullWidth
              label={"All"}
              style={{
                px: 10,
                backgroundColor: Theme.bg.bg,
                border: "1px solid #E7EAEE",
              }}
            />
          </Grid>
          <Grid item>
            <InputLabel>Period End</InputLabel>
            <SimpleSelect
              size="small"
              // options={options}
              //   fullWidth
              label={"All"}
              style={{
                px: 10,
                backgroundColor: Theme.bg.bg,
                border: "1px solid #E7EAEE",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item>
            <InputLabel>Interval</InputLabel>
            <SimpleSelect
              size="small"
              // options={options}
              //   fullWidth
              label={"All"}
              style={{
                px: 10,
                backgroundColor: Theme.bg.bg,
                border: "1px solid #E7EAEE",
              }}
            />
          </Grid>
          <Grid item>
            <InputLabel>Quantity</InputLabel>
            <SimpleSelect
              size="small"
              // options={options}
              //   fullWidth
              label={"All"}
              style={{
                px: 10,
                backgroundColor: Theme.bg.bg,
                border: "1px solid #E7EAEE",
              }}
            />
          </Grid>
          <Grid item>
            <InputLabel>Price</InputLabel>
            <SimpleSelect
              size="small"
              // options={options}
              //   fullWidth
              label={"All"}
              style={{
                px: 10,
                backgroundColor: Theme.bg.bg,
                border: "1px solid #E7EAEE",
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item>
            <InputLabel>Currency</InputLabel>
            <SimpleSelect
              size="small"
              // options={options}
              //   fullWidth
              label={"All"}
              style={{
                px: 10,
                backgroundColor: Theme.bg.bg,
                border: "1px solid #E7EAEE",
              }}
            />
          </Grid>
          <Grid item>
            <InputLabel>Total Price</InputLabel>
            <SimpleSelect
              size="small"
              // options={options}
              //   fullWidth
              label={"All"}
              style={{
                px: 10,
                backgroundColor: Theme.bg.bg,
                border: "1px solid #E7EAEE",
              }}
            />
          </Grid>
          <Grid item>
            <InputLabel>Actions</InputLabel>
            <SimpleSelect
              size="small"
              // options={options}
              //   fullWidth
              label={"All"}
              style={{
                px: 10,
                backgroundColor: Theme.bg.bg,
                border: "1px solid #E7EAEE",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
