import React from "react";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Theme } from "../../Theme";
import "../../App.css";
const Header = ({ title, onClose }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box sx={{ px: 3, py: 5, borderBottom: ` 4px solid ${Theme.bg.yellow}` }}>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={10}>
          {/* <Typography variant="h4" style={{ fontWeight: "500" }}>
            {title}
          </Typography> */}
          <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: "30px" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            disableRipple
            sx={{
              backgroundColor: Theme.bg.greenBtn,
              ".MuiIconButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.greenBtn,
                  borderColor: Theme.bg.greenBtn,
                },
              },
            }}
          >
            <CloseIcon fontSize="medium" style={{ color: "#fff" }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Header;
