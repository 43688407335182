import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { IconButton, InputLabel, Tooltip } from "@mui/material";
import { AiFillExclamationCircle } from "react-icons/ai";
import SliderInput from "../Inputs/SliderInput";
const marks = [
  {
    value: 1,
    label: "1km",
  },
  {
    value: 2,
    label: "2km",
  },
  {
    value: 3,
    label: "3km",
  },
  {
    value: 4,
    label: "4km",
  },
  {
    value: 5,
    label: "5km",
  },
];

function valuetext(value) {
  return `${value}km`;
}
const SliderComp = ({ label, tooltipTitle, ...props }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <InputLabel
        htmlFor="outlined-adornment-password"
        sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
      >
        {label}{" "}
        <Tooltip title={tooltipTitle}>
          <IconButton>
            <AiFillExclamationCircle size={15} color="#0088FF" />
          </IconButton>
        </Tooltip>
      </InputLabel>
      <SliderInput
        aria-label="pretto slider"
        min={1}
        max={5}
        defaultValue={3000}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        marks={marks}
        {...props}
      />
    </Box>
  );
};
export default SliderComp;
