import React, { useEffect, useState } from "react";
import DashLogo from "../../Assets/SVG/Dashlogo.svg";
import CreateCompain from "../../Assets/SVG/CreateCampaign.svg";
// import SavedGoldFinches from "../../Assets/SVG/SavedGoldFinches.svg";
import AddFranchise from "../../Assets/SVG/AddFranchise.svg";
import Analytics from "../../Assets/SVG/Analytics.svg";
import BuyPackages from "../../Assets/SVG/BuyPackages.svg";
import Logout from "../../Assets/SVG/Logout.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  Box,
  Stack,
  IconButton as IconBtn,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StickyHeadTable from "../../Components/Table/index.jsx";
import { styled } from "@mui/material/styles";
import { VscEdit } from "react-icons/vsc";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiAddToQueue } from "react-icons/bi";
import { HiOutlineArchiveBox } from "react-icons/hi2";
import { RiArrowDropUpFill } from "react-icons/ri";
import { RiArrowDropDownFill } from "react-icons/ri";
import { Theme } from "../../Theme";
import "../../App.css";
import useFinch from "../../ApiHooks/useFinch";
import { useSelector } from "react-redux";
import percentToOpacity from "../../Utils/percentToOpacity.js";

const SavedGoldFinches = () => {
  const partnerFinches = useSelector(
    (state) => state.partnerFinchesReducer.partnerFinches
  );

  const { createStatus, colorStatus, fetchLoading } = useFinch();

  const [tableData, setTableData] = useState([]);

  const titleLiner = (title) => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
        {title}
      </Typography>
      {/* <Stack sx={{ m: 0, p: 0 }}>
        <RiArrowDropUpFill style={{ marginBottom: -5 }} />
        <RiArrowDropDownFill style={{ marginTop: -5 }} />
      </Stack> */}
    </Box>
  );
  function formatDate(unixTimestamp) {
    const timestamp = unixTimestamp * 1000; // Convert to milliseconds
    const date = new Date(timestamp);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();

    return `${month}-${day}-${year}`;
  }
  const textLiner = (color, fontSize, text, bgColor = null, date = false) => {
    const formattedDate = formatDate(text);
    return (
      <Typography
        sx={{
          color: color,
          fontSize: fontSize,
          backgroundColor: bgColor,
          borderRadius: "4px",
          px: 1,
          fontFamily: "Urbanist-Semibold",
          width: 120,
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {date ? formattedDate : text}
      </Typography>
    );
  };
  const columns = [
    {
      id: "title",
      label: titleLiner("Title"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "savedByUsers",
      label: titleLiner("Saved By Users"),
      minWidth: 200,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "currentStatus",
      label: titleLiner("Current Status"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "type",
      label: titleLiner("Type"),
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
  ];

  useEffect(() => {
    let finchesTable = [
      ...partnerFinches.map((finch) => {
        return {
          title: textLiner(Theme.color.text, "18px", finch?.title),
          savedByUsers: textLiner(
            Theme.color.text,
            "18px",
            finch?.usersWhoHaveClaimed ? finch?.usersWhoHaveClaimed.length : 0
          ),
          currentStatus: textLiner(
            colorStatus(createStatus(finch)),
            "16px",
            createStatus(finch),
            percentToOpacity(25, colorStatus(createStatus(finch)))
          ),
          type: textLiner(
            finch?.type === "drenches" ? Theme.color.golden : "#00C3EB",
            "16px",
            finch?.type === "drenches" ? "Goldfinch" : "Finches",
            percentToOpacity(
              25,
              finch?.type === "drenches" ? Theme.color.golden : "#00C3EB"
            )
          ),
        };
      }),
    ];

    setTableData(finchesTable);
  }, []);

  console.log(tableData, partnerFinches, "TABLE_DATA");

  return (
    <Box sx={{ p: 1, height: "auto" }}>
      <Box sx={{ my: 3 }}>
        <Typography style={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}>
          Campaigns
        </Typography>
      </Box>
      {fetchLoading && !tableData?.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ my: 2 }}>
          <StickyHeadTable
            columns={columns}
            rows={tableData}
            count={tableData?.length || 0}
          />
        </Box>
      )}
    </Box>
  );
};

export default SavedGoldFinches;
