import { Box, Typography } from "@mui/material";
import React from "react";
import '../../App.css'

const GradientBox = ({text,count,fontFamily,fontSize}) => {
  return (
    <Box
      sx={{
        // width: 790,
        height: 96,
        background: "linear-gradient(to right, #CFEDDC, #CFEDDD,#CAF0EE)",
        borderRadius: "4px",
        // marginBottom: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        px: {xs:2,sm:3,md:5}
      }}
    >
      <Typography sx={{fontFamily:fontFamily,fontSize:{xs:"24px",sm:"24px",fontSize}}}> {text}:</Typography>
      <Box
        sx={{
          // height: 73,
          width: "auto",
          borderRadius: "9px",
          backgroundColor: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py:1,
          px:2
        }}
      >
        <Typography sx={{fontFamily:fontFamily,fontSize:{xs:"24px",sm:"24px",fontSize}}}>{count}</Typography>
      </Box>
    </Box>
  );
};

export default GradientBox;
