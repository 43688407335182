import React, { useState, useEffect } from "react";

import { Button, Grid, Modal, Paper } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import { addDays, subDays, format } from "date-fns";
import { SlCalender } from "react-icons/sl";
import { Theme } from "../../Theme";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
const DateRangeModal = ({ value, onChange, getData = () => {} }) => {
  const [open, setOpen] = useState(false);
  var startDate = format(new Date(value?.[0]?.startDate), "dd/MM/yyyy");
  var endDate = format(new Date(value?.[0]?.endDate), "dd/MM/yyyy");
  useEffect(() => {
    if (!open) {
      getData({
        startDate: new Date(value?.[0]?.startDate),
        endDate: new Date(value?.[0]?.endDate),
      });
    }
  }, [open]);
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        color="primary"
        startIcon={<SlCalender />}
        sx={{
          gap: 1,
          borderRadius: 1,
          backgroundColor: Theme.color.basic,
          "&.MuiButton-root": {
            "&:hover": {
              backgroundColor: Theme.color.basic,
              borderColor: Theme.color.basic,
            },
          },
        }}
      >
        {startDate} - {endDate}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Grid
          container
          spacing={5}
          style={{ minHeight: "100vh" }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Paper style={{ padding: 10 }}>
              <Grid container>
                <Grid item xs={12} align="center">
                  <DateRangePicker
                    ranges={value}
                    onChange={(item) => onChange([item.selection])}
                    displayMode="dateRange"
                    direction="vertical"
                  />
                </Grid>
                <Grid item xs={12} align="right">
                  <Button
                    onClick={() => setOpen(false)}
                    variant="contained"
                    color="primary"
                  >
                    Done
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default DateRangeModal;
