import React, { useState } from "react";
import DashLogo from "../../Assets/SVG/Dashlogo.svg";
import CreateCompain from "../../Assets/SVG/CreateCampaign.svg";
import SavedGoldFinches from "../../Assets/SVG/SavedGoldFinches.svg";
import AddFranchise from "../../Assets/SVG/AddFranchise.svg";
import Analytics from "../../Assets/SVG/Analytics.svg";
import BuyPackages from "../../Assets/SVG/BuyPackages.svg";
import Logout from "../../Assets/SVG/Logout.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { RiDeleteBinLine } from "react-icons/ri";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { LuFilter } from "react-icons/lu";
import {
  Box,
  Stack,
  IconButton as IconBtn,
  Typography,
  Grid,
  Button,
  IconButton,
  Checkbox,
  useMediaQuery,
  Paper,
} from "@mui/material";
import IconFeather_IMG from "../../Assets/SVG/Iconfeather.svg";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StickyHeadTable from "../../Components/Table/index.jsx";
import { styled } from "@mui/material/styles";
import { VscEdit } from "react-icons/vsc";
import { BiAddToQueue } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { RiArrowDropUpFill } from "react-icons/ri";
import { RiArrowDropDownFill } from "react-icons/ri";
import { Theme } from "../../Theme";
import GradientBox from "../../Components/GradientBox";
import SideBar from "../../Components/Sidebar";
import CreateFinch from "../../Components/Forms/CreateFinch";
import CreateGoldFinch from "../../Components/Forms/CreateGoldFinch";
import { BsSearch } from "react-icons/bs";
import BasicModal from "../../Components/Modal";
import DeleteComp from "../../Components/Modal/DeleteComp";
import DateChangeComp from "../../Components/Modal/DateChangeComp";
import CancelSubsComp from "../../Components/Modal/CancelSubsComp";
import "../../App.css";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import FilterButton from "../../Components/Buttons/FilterButton";
import ExpandableSearchBar from "../../Components/Inputs/ExpandableSearchBar";
import SimpleMenu from "../../Components/Menu";
import SubscriptionFilter from "../../Components/Menu/SubscriptionFilter";
import AppUserFilter from "../../Components/Menu/AppUserFilter";

const AppUsers = () => {
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignSidebar, setCampaignSidebar] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [CreateFinchForm, setCreteFinchForm] = useState(false);
  const [CreateGoldFinchForm, setCreteGoldFinchForm] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const menuOpen1 = Boolean(anchorEl1);
  const menuOpen2 = Boolean(anchorEl2);

  // const [count, setCount] = useState(user?.count || 0);
  // const [nextPage, setNextPage] = useState(userData?.next);
  // const [previousPage, setPreviousPage] = useState(userData?.previous);

  const titleLiner = (title) => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
        {title}
      </Typography>
      {/* <Stack sx={{ m: 0, p: 0 }}>
        <RiArrowDropUpFill style={{ marginBottom: -5 }} />
        <RiArrowDropDownFill style={{ marginTop: -5 }} />
      </Stack> */}
    </Box>
  );

  const textLiner = (color, fontSize, text, bgColor = null) => {
    return (
      <Typography
        sx={{
          color: color,
          fontSize: fontSize,
          backgroundColor: bgColor,
          borderRadius: "4px",
          //   px: 1,
          fontFamily: "Urbanist-Semibold",
        }}
      >
        {text}
      </Typography>
    );
  };

  const columns = [
    {
      id: "id",
      label: titleLiner("ID"),
      minWidth: 10,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "fullName",
      label: titleLiner("Full Name"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "email",
      label: titleLiner("Email"),
      minWidth: 100,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "contact",
      label: titleLiner("Contact"),
      minWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "gender",
      label: titleLiner("Gender"),
      minWidth: 10,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "action",
      label: titleLiner("Action"),
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "signUp",
      label: titleLiner("Sign Up"),
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "status",
      label: titleLiner("Status"),
      minWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
  ];

  const handleClick = (event, id) => {
    setSelectedId(id);
    if (event === "delete") {
      handleModal(id);
    }
  };
  const handleMenuClick = (event, state) => {
    if (state === "filter") {
      setAnchorEl1(event.currentTarget);
    } else if (state === "column") {
      setAnchorEl2(event.currentTarget);
    }
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleModal = (id) => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //   const handleCheckboxChange = (index) => {
  //     // Create a copy of the userData array
  //     const updatedUserData = [...userData];

  //     // Toggle the checked property of the specific object
  //     updatedUserData[index].checked = !updatedUserData[index].checked;

  //     // Update the state with the new userData
  //     setUserData(updatedUserData);
  //   };

  let franchises = ["Calgary", "Line Chest", "Acura", "My Home"];

  let userData = [
    {
      id: textLiner(Theme.color.text, "18px", "0C0jatCKaVRdUrt6LrmBRiepkdD2"),
      fullName: textLiner(Theme.color.text, "18px", "Alex Barker"),
      email: textLiner(Theme.color.text, "18px", "google@gmail.com"),
      contact: textLiner(Theme.color.text, "16px", "+923353854999"),
      gender: textLiner(Theme.color.text, "16px", "Male"),
      action: (
        <PrimaryButton
          text={"Block"}
          // onClick={handleSignUp}
          size="small"
          sx={{
            color: Theme.color.white,
            backgroundColor: Theme.bg.redBtn,
            borderRadius: "4px",
            px: 4,
            fontSize: "16px",
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: Theme.bg.redBtn,
                borderColor: Theme.bg.redBtn,
                // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              },
            },
            fontFamily: "Urbanist-Bold",
            // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
          }}
        />
      ),
      signUp: textLiner(Theme.color.text, "16px", "Male"),
      status: textLiner(
        Theme.color.darkGreen,
        "16px",
        "Active",
        "rgba(202, 240, 210, 1)"
      ),
    },
    {
      id: textLiner(Theme.color.text, "18px", "0C0jatCKaVRdUrt6LrmBRiepkdD2"),
      fullName: textLiner(Theme.color.text, "18px", "Alex Barker"),
      email: textLiner(Theme.color.text, "18px", "google@gmail.com"),
      contact: textLiner(Theme.color.text, "16px", "+923353854999"),
      gender: textLiner(Theme.color.text, "16px", "Male"),
      action: (
        <PrimaryButton
          text={"Block"}
          // onClick={handleSignUp}
          size="small"
          sx={{
            color: Theme.color.white,
            backgroundColor: Theme.bg.redBtn,
            borderRadius: "4px",
            px: 4,
            fontSize: "16px",
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: Theme.bg.redBtn,
                borderColor: Theme.bg.redBtn,
                // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              },
            },
            fontFamily: "Urbanist-Bold",
            // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
          }}
        />
      ),
      signUp: textLiner(Theme.color.text, "16px", "Male"),
      status: textLiner(
        Theme.color.darkGreen,
        "16px",
        "Active",
        "rgba(202, 240, 210, 1)"
      ),
    },
    {
      id: textLiner(Theme.color.text, "18px", "0C0jatCKaVRdUrt6LrmBRiepkdD2"),
      fullName: textLiner(Theme.color.text, "18px", "Alex Barker"),
      email: textLiner(Theme.color.text, "18px", "google@gmail.com"),
      contact: textLiner(Theme.color.text, "16px", "+923353854999"),
      gender: textLiner(Theme.color.text, "16px", "Male"),
      action: (
        <PrimaryButton
          text={"Block"}
          // onClick={handleSignUp}
          size="small"
          sx={{
            color: Theme.color.white,
            backgroundColor: Theme.bg.darkGreen,
            borderRadius: "4px",
            px: 4,
            fontSize: "16px",
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: Theme.bg.darkGreen,
                borderColor: Theme.bg.darkGreen,
                // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              },
            },
            fontFamily: "Urbanist-Bold",
            // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
          }}
        />
      ),
      signUp: textLiner(Theme.color.text, "16px", "Male"),
      status: textLiner(
        Theme.color.darkGreen,
        "16px",
        "Active",
        "rgba(202, 240, 210, 1)"
      ),
    },
    {
      id: textLiner(Theme.color.text, "18px", "0C0jatCKaVRdUrt6LrmBRiepkdD2"),
      fullName: textLiner(Theme.color.text, "18px", "Alex Barker"),
      email: textLiner(Theme.color.text, "18px", "google@gmail.com"),
      contact: textLiner(Theme.color.text, "16px", "+923353854999"),
      gender: textLiner(Theme.color.text, "16px", "Male"),
      action: (
        <PrimaryButton
          text={"Block"}
          // onClick={handleSignUp}
          size="small"
          sx={{
            color: Theme.color.white,
            backgroundColor: Theme.bg.redBtn,
            borderRadius: "4px",
            px: 4,
            fontSize: "16px",
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: Theme.bg.redBtn,
                borderColor: Theme.bg.redBtn,
                // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              },
            },
            fontFamily: "Urbanist-Bold",
            // boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
          }}
        />
      ),
      signUp: textLiner(Theme.color.text, "16px", "Male"),
      status: textLiner(
        Theme.color.darkGreen,
        "16px",
        "Active",
        "rgba(202, 240, 210, 1)"
      ),
    },
  ];

  //static pagination
  let paginatedData = {
    count: 20,
    next: 2,
    previous: null,
    results: userData,
  };
  const [users, setUsers] = useState(paginatedData.results);
  const [currentPage, setCurrentPage] = useState(1);

  const getUsers = (page) => {
    if (page === 1) {
      setUsers(paginatedData.results);
      setCurrentPage(1);
    } else if (page === 2) {
      setUsers(paginatedData.results);
      setCurrentPage(2);
    } else {
    }
  };

  return (
    <Box sx={{ p: 1, height: "auto" }}>
      {!isSmallScreen ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 4,
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 1, sm: 2, md: 5 },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Urbanist-Bold",
                fontSize: { xs: "24px", sm: "24px", md: "32px" },
              }}
            >
              App Users
            </Typography>
            {/* <Button
              variant="outlined"
              style={{ color: "#88949E", borderColor: "#E7EAEE" }}
              startIcon={<LuFilter />}
              size="large"
              sx={{ px: { xs: 2, sm: 2, md: 3 } }}
            >
              Filter
            </Button> */}
            <FilterButton
              onClick={(e) => {
                handleMenuClick(e, "filter");
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            {/* <Button
              variant="outlined"
              style={{
                color: "#88949E",
                borderColor: "#E7EAEE",
                borderRadius: 5,
                padding: 10,
              }}
            >
              <BsSearch style={{ fontSize: 20 }} />
            </Button> */}
            <ExpandableSearchBar />
          </Box>
        </Box>
      ) : (
        <Paper sx={{ px: 1, py: 2, my: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 2,
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 1, sm: 2, md: 5 },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "32px",
                  // fontSize: { xs: "24px", sm: "24px", md: "34px" },
                }}
              >
                App Users
              </Typography>
              {/* <img src={IconFeather_IMG} alt="" /> */}
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button
                variant="outlined"
                sx={{
                  color: "#88949E",
                  borderColor: "#E7EAEE",
                  // borderRadius: 5,
                  px: 1,
                  py: 1,
                }}
              >
                <BsSearch style={{ fontSize: 20 }} />
              </Button>
            </Box> */}
          </Box>
        </Paper>
      )}
      <Box sx={{ my: 2 }}>
        <StickyHeadTable
          columns={columns}
          rows={paginatedData.results}
          count={paginatedData?.count || 0}
          next={
            paginatedData?.next
              ? (callback) => getUsers(users?.next, callback)
              : null
          }
          previous={
            paginatedData?.previous
              ? (callback) => getUsers(users?.previous, callback)
              : null
          }
          loading={loading}
        />
      </Box>
      <SimpleMenu
        handleClose={handleClose1}
        open={menuOpen1}
        anchorEl={anchorEl1}
        data={
          <AppUserFilter
          // selectedId={selectedId}
          // handleOptionClick={handleMenuClick}
          // options={options}
          />
        }
        // options={options}
      />
      <SideBar
        open={CreateFinchForm}
        onClose={() => setCreteFinchForm(false)}
        title={selectedId ? "Edit Client" : "CreateFinch"}
        form={
          <CreateFinch
            id={selectedId}
            // onEdit={getAllClients}
            onClose={() => setCreteFinchForm(false)}
          />
        }
      />
      <SideBar
        open={CreateGoldFinchForm}
        onClose={() => setCreteGoldFinchForm(false)}
        title={selectedId ? "Edit Client" : "CreateGoldFinch"}
        form={
          <CreateGoldFinch
            id={selectedId}
            // onEdit={getAllClients}
            onClose={() => setCreteGoldFinchForm(false)}
          />
        }
      />
      {/* Delete Modal */}
      <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<DeleteComp />}
      />

      {/* Date Change Modal */}
      {/* <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<DateChangeComp />}
      /> */}

      {/* Cancel Subcription Modal */}
      {/* <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<CancelSubsComp />}
      /> */}
    </Box>
  );
};

export default AppUsers;
