import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../Utils/useFetch";
import {
  ADD_FRANCHISE_API,
  EDIT_FRANCHISE_API,
  EDIT_FRANCHISE_STATUS_API,
  EDIT_PROFILE_API,
  GET_ALL_PARTNER_FRANCHISES_API,
} from "../Constants/APIs";
import { ADD_PARTNER_FRANCHISES } from "../Redux/types";
import { toast } from "react-toastify";
import moment from "moment";

const useFranchises = () => {
  const { request } = useFetch();
  const partnerFranchises = useSelector(
    (state) => state.partnerFranchisesReducer.partnerFranchises
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const editFranchise = async (data) => {
    try {
      let errorObj = {};

      if (!data.name) {
        errorObj.name = "Franchise Name is Required";
      }

      if (!data.address) {
        errorObj.location = "Franchise Address is Required";
      }

      if (!data.longitude) {
        errorObj.location = "Franchise Address is Required";
      }

      if (!data.latitude) {
        errorObj.location = "Franchise Address is Required";
      }

      if (!Object.keys(data.openingHours)?.length) {
        errorObj.openingHours = "Opening & Closing hours is Required";
      } else {
        const openingErrors = validateOpeningHours(data?.openingHours);
        if (
          Object.values(openingErrors).every(
            (dayErrors) => dayErrors.length === 0
          )
        ) {
          console.log("Opening hours are valid for all days.");
        } else {
          errorObj.openingHours = openingErrors;
          console.log("Opening hours validation failed for one or more days.");
        }
      }

      if (Object.keys(errorObj).length) {
        setErrors(errorObj);
        return;
      }
      setErrors({});
      setLoading(true);
      const req = await request(EDIT_FRANCHISE_API, "POST", data);

      getFranchises();
      toast.success("Franchise edited successfully");
    } catch (e) {
      // console.log(e.message, "LOGOURL_UPLOAD");
    } finally {
      setLoading(false);
    }
  };
  const editFranchiseStatus = async (data) => {
    try {
      let errorObj = {};

      if (!data.franchiseId) {
        errorObj.franchiseId = "Franchise Id is Required";
      }

      if (Object.keys(errorObj).length) {
        setErrors(errorObj);
        return;
      }
      setErrors({});
      const req = await request(EDIT_FRANCHISE_STATUS_API, "POST", data);

      getFranchises();
      toast.success("Franchise edited successfully");
    } catch (e) {
      // console.log(e.message, "LOGOURL_UPLOAD");
    } finally {
    }
  };

  const validateOpeningHours = (openingHoursObj) => {
    const isValidTime = (hour, minute) => {
      return hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59;
    };

    const errors = {};

    for (const day in openingHoursObj) {
      const { openingHour, openingMinute, closingHour, closingMinute } =
        openingHoursObj[day];

      errors[day] = [];

      if (
        !isValidTime(openingHour, openingMinute) ||
        !isValidTime(closingHour, closingMinute)
      ) {
        errors[day].push(`Invalid opening/closing hours`);
      }
      console.log(
        moment({ hour: openingHour, minute: openingMinute }).isSameOrAfter({
          hour: closingHour,
          minute: closingMinute,
        }),
        "moment:"
      );
      if (
        moment({ hour: openingHour, minute: openingMinute }).isSameOrAfter({
          hour: closingHour,
          minute: closingMinute,
        })
      ) {
        errors[day].push(`Closing time should be after opening time`);
      }
    }

    return errors;
  };

  const addFranchise = async (data, callback) => {
    try {
      let errorObj = {};

      if (!data.name) {
        errorObj.name = "Franchise Name is Required";
      }

      if (!data.address) {
        errorObj.location = "Franchise Address is Required";
      }

      if (!data.longitude) {
        errorObj.location = "Franchise Address is Required";
      }

      if (!data.latitude) {
        errorObj.location = "Franchise Address is Required";
      }
      if (!Object.keys(data.openingHours)?.length) {
        errorObj.openingHours = "Opening & Closing hours is Required";
      } else {
        const openingErrors = validateOpeningHours(data?.openingHours);
        if (
          Object.values(openingErrors).every(
            (dayErrors) => dayErrors.length === 0
          )
        ) {
          console.log("Opening hours are valid for all days.");
        } else {
          errorObj.openingHours = openingErrors;
          console.log("Opening hours validation failed for one or more days.");
        }
      }

      if (Object.keys(errorObj).length) {
        setErrors(errorObj);
        return;
      }
      setErrors({});
      setLoading(true);
      const req = await request(ADD_FRANCHISE_API, "POST", data);

      getFranchises();
      toast.success("Franchise added successfully");
      callback();
    } catch (e) {
      // console.log(e.message, "LOGOURL_UPLOAD");
      toast.error(
        e?.error?.message || "Something went wrong, please try again"
      );
    } finally {
      setLoading(false);
    }
  };

  const getFranchises = async () => {
    try {
      setLoading(true);
      const req = await request(GET_ALL_PARTNER_FRANCHISES_API, "GET");
      dispatch({
        type: ADD_PARTNER_FRANCHISES,
        payload: req?.data?.data,
      });
    } catch (err) {
      console.log(err, "err.message");
    } finally {
      setLoading(false);
    }
  };
  return {
    editFranchise,
    getFranchises,
    loading,
    errors,
    addFranchise,
    editFranchiseStatus,
  };
};

export default useFranchises;
