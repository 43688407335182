import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab"

const PrimaryButton = ({ text, loading, variant = "contained", ...props }) => {
  return (
    <LoadingButton
      loading={loading}
      variant={variant}
      {...props}
    >
      {text}
    </LoadingButton>
  );
};
export default PrimaryButton;

// <button
//   className={`bg-PrimaryBtn px-8 py-2 md:py-3 w-full text-primary font-bold rounded-lg`}
//   {...props}
// >
//   {text}
// </button>
