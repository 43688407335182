import { Box, Typography } from "@mui/material";
import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";
import Lottie from "lottie-react";
import animationData from "../../Assets/LottieFiles/tickMark.json";
import { SIGN_IN_PATH } from "../../Constants/Paths";
import { useNavigate } from "react-router-dom";

const ChangePassComp = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Lottie
        animationData={animationData}
        style={{ width: "200px", height: "200px" }}
      />
      <Typography sx={{ my: 2 }}>Password Change Successfully</Typography>
      <PrimaryButton
        text={"Continue"}
        onClick={() => navigate(SIGN_IN_PATH)}
        sx={{
          color: Theme.color.basic,
          backgroundColor: Theme.bg.yellow,
          borderRadius: "8px",
          fontSize: "18px",
          "&.MuiButton-root": {
            "&:hover": {
              backgroundColor: Theme.bg.yellow,
              borderColor: Theme.bg.yellow,
              boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
            },
          },
          fontFamily: "Urbanist-Bold",
          boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
        }}
      />
    </Box>
  );
};

export default ChangePassComp;
