import {
  Grid,
  Switch,
  Tooltip,
  InputLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { FormGroup as InputGroup, Input, Label } from "reactstrap";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import TimePickerInput from "./TimePickerInput";
import SwitchInput from "./SwitchInput";
import { AiFillExclamationCircle } from "react-icons/ai";
import "../../App.css";

const DaySelect = ({
  label,
  dayStatus,
  switchStatus,
  startTime,
  endTime,
  onStart,
  onEnd,
  error,
}) => {
  return (
    <Grid container spacing={1} alignItems={"center"} sx={{ mt: 0.5 }}>
      <Grid item xs={12} lg={4} sx={{ mt: 4 }}>
        <InputLabel
          check
          sx={{
            mt: 0.7,
            color: "#363636",
            fontFamily: "Urbanist-Bold",
          }}
        >
          <SwitchInput
            color={"primary"}
            aria-label="checkedF"
            onClick={() => switchStatus(dayStatus)}
            on={dayStatus}
            checked={dayStatus}
            sx={{ mr: 1 }}
          />
          {label}
        </InputLabel>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Grid container spacing={1}>
          <Grid item xs={6} lg={6}>
            <InputLabel
              sx={{ mb: 0.5, color: "#363636", fontFamily: "Urbanist-Bold" }}
            >
              Start Time:
            </InputLabel>

            <TimePickerInput
              value={
                moment.isMoment(moment(startTime))
                  ? startTime
                  : moment(startTime)
              }
              disabled={!dayStatus}
              onChange={(value) => onStart(value)}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={6} lg={6}>
            <InputLabel
              sx={{ mb: 0.5, color: "#363636", fontFamily: "Urbanist-Bold" }}
            >
              End Time:
            </InputLabel>

            <TimePickerInput
              value={
                moment.isMoment(moment(endTime)) ? endTime : moment(endTime)
              }
              disabled={!dayStatus}
              onChange={(value) => onEnd(value)}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Grid>
      {Boolean(error?.[0]) && (
        <Grid item xs={12}>
          <Typography variant="body2" color="error">
            {error?.[0]}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export const OpeningHours = ({
  showAllDays = false,
  setOpeningHoursObj,
  openingHoursObj,
  previousSavedData: singleDrenchData = null,
  error,
}) => {
  const [key, setKey] = useState(false);
  const [startTimeModay, setStartTimeMonday] = useState(null);
  const [endTimeModay, setEndTimeMonday] = useState(null);
  const [saturday, onSelectSaturday] = useState(false);
  const [startTimeSaturday, setStartTimeSaturday] = useState(null);
  const [endTimeSaturday, setEndTimeSaturday] = useState(null);
  const [tuesday, onSelectTuesday] = useState(false);
  const [startTimeTuesday, setStartTimeTuesday] = useState(null);
  const [endTimeTuesday, setEndTimeTuesday] = useState(null);
  const [wednesday, onSelectWednesday] = useState(false);
  const [startTimeWednesday, setStartTimeWednesday] = useState(null);
  const [endTimeWednesday, setEndTimeWednesday] = useState(null);
  const [thursday, onSelectThursday] = useState(false);
  const [startTimeThursday, setStartTimeThursday] = useState(null);
  const [endTimeThursday, setEndTimeThursday] = useState(null);
  const [friday, onSelectFriday] = useState(false);
  const [startTimeFriday, setStartTimeFriday] = useState(null);
  const [endTimeFriday, setEndTimeFriday] = useState(null);
  const [sunday, onSelectSunday] = useState(false);
  const [startTimeSunday, setStartTimeSunday] = useState(null);
  const [endTimeSunday, setEndTimeSunday] = useState(null);
  const [selectAll, onSelectAll] = useState(false);
  const [startTimeAll, setStartTimeAll] = useState(null);
  const [endTimeAll, setEndTimeAll] = useState(null);
  const [monday, onSelectMonday] = useState(false);

  const toggleSwitchAll = (key) => {
    setKey(!key);
    onSelectAll(!key);
    onSelectMonday(!key);
    onSelectTuesday(!key);
    onSelectWednesday(!key);
    onSelectThursday(!key);
    onSelectFriday(!key);
    onSelectSaturday(!key);
    onSelectSunday(!key);
    setStartTimeMonday(startTimeAll);
    setEndTimeMonday(endTimeAll);
    setEndTimeTuesday(endTimeAll);
    setStartTimeTuesday(startTimeAll);
    setStartTimeWednesday(startTimeAll);
    setEndTimeWednesday(endTimeAll);
    setStartTimeThursday(startTimeAll);
    setEndTimeThursday(endTimeAll);
    setStartTimeFriday(startTimeAll);
    setEndTimeFriday(endTimeAll);
    setStartTimeSaturday(startTimeAll);
    setEndTimeSaturday(endTimeAll);
    setStartTimeSunday(startTimeAll);
    setEndTimeSunday(endTimeAll);
  };

  const onChangeAll = (e) => {
    console.log(e, "p");
    setStartTimeMonday(e);
    setStartTimeTuesday(e);
    setStartTimeWednesday(e);
    setStartTimeThursday(e);
    setStartTimeFriday(e);
    setStartTimeSaturday(e);
    setStartTimeSunday(e);
    //  setStartTimeMonday(startTimeAll)
    //  setEndTimeMonday(endTimeAll)
    //  setEndTimeTuesday(endTimeAll)
    //  setEndTimeWednesday(endTimeAll)
    //  setEndTimeFriday(endTimeAll)
    //  setEndTimeSaturday(endTimeAll)
    //  setEndTimeSunday(endTimeAll)
    //  setEndTimeMonday(e)
  };
  const onChangeAllEnd = (e) => {
    setEndTimeMonday(e);
    setEndTimeTuesday(e);
    setEndTimeWednesday(e);
    setEndTimeThursday(e);
    setEndTimeFriday(e);
    setEndTimeSaturday(e);
    setEndTimeSunday(e);
  };

  const toggleSwitch = (monday) => {
    onSelectMonday(!monday);

    setStartTimeMonday(null);
    setEndTimeMonday(null);
  };

  const toggleSwitchThusday = (tuesday) => {
    onSelectTuesday(!tuesday);
    setEndTimeTuesday(null);
    setStartTimeTuesday(null);
  };

  const toggleSwitchWednesday = (wednesday) => {
    onSelectWednesday(!wednesday);
    setStartTimeWednesday(null);
    setEndTimeWednesday(null);
  };

  const toggleSwitchThurday = (thursday) => {
    onSelectThursday(!thursday);
    setStartTimeThursday(null);
    setEndTimeThursday(null);
  };

  const toggleSwitchFriday = (friday) => {
    onSelectFriday(!friday);
    setStartTimeFriday(null);
    setEndTimeFriday(null);
  };

  const toggleSwitchSturday = (saturday) => {
    onSelectSaturday(!saturday);
    setStartTimeSaturday(null);
    setEndTimeSaturday(null);
  };

  const toggleSwitchSunday = (sunday) => {
    onSelectSunday(!sunday);
    setStartTimeSunday(null);
    setEndTimeSunday(null);
  };

  const formatTime = ({ day, dayName, start, end }) => {
    let temp = {};

    const startTime = moment.isMoment(moment(new Date(start)))
      ? moment(new Date(start)).format("HH:mm")?.split(":")
      : null;
    const endTime = moment.isMoment(moment(new Date(end)))
      ? moment(new Date(end)).format("HH:mm")?.split(":")
      : null;
    console.log(start, end, "startTime");
    if (start) {
      temp = {
        ...temp,
        [dayName]: {
          openingHour: parseInt(startTime?.[0]),
          openingMinute: parseInt(startTime?.[1]),
          closingHour: parseInt(endTime?.[0]),
          closingMinute: parseInt(endTime?.[1]),
        },
      };
    }
    if (end) {
      temp = {
        ...temp,
        [dayName]: {
          closingHour: parseInt(endTime?.[0]),
          closingMinute: parseInt(endTime?.[1]),
          openingHour: parseInt(startTime?.[0]),
          openingMinute: parseInt(startTime?.[1]),
        },
      };
    }
    return temp;
  };

  const formatData = () => {
    let openingHours = { ...openingHoursObj };
    const arr = [
      {
        day: monday,
        dayName: "monday",
        start: startTimeModay,
        end: endTimeModay,
      },
      {
        day: tuesday,
        dayName: "tuesday",
        start: startTimeTuesday,
        end: endTimeTuesday,
      },
      {
        day: wednesday,
        dayName: "wednesday",
        start: startTimeWednesday,
        end: endTimeWednesday,
      },
      {
        day: thursday,
        dayName: "thursday",
        start: startTimeThursday,
        end: endTimeThursday,
      },
      {
        day: friday,
        dayName: "friday",
        start: startTimeFriday,
        end: endTimeFriday,
      },
      {
        day: saturday,
        dayName: "saturday",
        start: startTimeSaturday,
        end: endTimeSaturday,
      },
      {
        day: sunday,
        dayName: "sunday",
        start: startTimeSunday,
        end: endTimeSunday,
      },
    ];
    arr.forEach((data) => {
      if (data.day === false) {
        return delete openingHours[data.dayName];
      }
      openingHours = { ...openingHours, ...formatTime(data) };
    });
    return openingHours;
  };

  useEffect(() => {
    if (singleDrenchData && singleDrenchData.openingHours) {
      const today = new Date();
      if (singleDrenchData.openingHours.monday) {
        let dayStr = singleDrenchData.openingHours.monday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );

        onSelectMonday(true);
        setStartTimeMonday(moment(startTimeday));
        setEndTimeMonday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.tuesday) {
        let dayStr = singleDrenchData.openingHours.tuesday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectTuesday(true);
        setStartTimeTuesday(moment(startTimeday));
        setEndTimeTuesday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.wednesday) {
        let dayStr = singleDrenchData.openingHours.wednesday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectWednesday(true);
        setStartTimeWednesday(moment(startTimeday));
        setEndTimeWednesday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.thursday) {
        let dayStr = singleDrenchData.openingHours.thursday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectThursday(true);
        setStartTimeThursday(moment(startTimeday));
        setEndTimeThursday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.friday) {
        let dayStr = singleDrenchData.openingHours.friday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectFriday(true);
        setStartTimeFriday(moment(startTimeday));
        setEndTimeFriday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.saturday) {
        let dayStr = singleDrenchData.openingHours.saturday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectSaturday(true);
        setStartTimeSaturday(moment(startTimeday));
        setEndTimeSaturday(moment(endTimeday));
      }
      if (singleDrenchData.openingHours.sunday) {
        let dayStr = singleDrenchData.openingHours.sunday;
        let startTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.openingHour,
          dayStr.openingMinute
        );
        let endTimeday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          dayStr.closingHour,
          dayStr.closingMinute
        );
        onSelectSunday(true);
        setStartTimeSunday(moment(startTimeday));
        setEndTimeSunday(moment(endTimeday));
      }
    }
  }, [singleDrenchData]);

  useEffect(() => {
    setOpeningHoursObj(formatData());
  }, [
    key,
    startTimeModay,
    endTimeModay,
    saturday,
    startTimeSaturday,
    endTimeSaturday,
    tuesday,
    startTimeTuesday,
    endTimeTuesday,
    wednesday,
    startTimeWednesday,
    endTimeWednesday,
    thursday,
    startTimeThursday,
    endTimeThursday,
    friday,
    startTimeFriday,
    endTimeFriday,
    sunday,
    startTimeSunday,
    endTimeSunday,
    selectAll,
    startTimeAll,
    endTimeAll,
    monday,
  ]);

  console.log(error, "formatData()");

  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "2rem",
        overflowX: "scroll",
      }}
      className="customScroll"
    >
      <Grid container style={{ marginTop: "2rem" }}>
        <Grid item md={12} sm={12}>
          <div className={"store-timing-container"}>
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{ mb: 0.7, color: "#363636", fontFamily: "Urbanist-Bold" }}
            >
              Set Store Hours
              <Tooltip title="Choose your store's opening and closing hours. These hours will be displayed in the campaign details section for your customers' information.">
                <IconButton>
                  <AiFillExclamationCircle size={15} color="#0088FF" />
                </IconButton>
              </Tooltip>
            </InputLabel>

            {showAllDays && (
              <Grid
                container
                spacing={1}
                alignItems={"center"}
                alignContent={"center"}
                sx={{ mt: 0.5, p2: 1 }}
              >
                <Grid item xs={4} sx={{ mt: 4 }}>
                  <InputLabel
                    check
                    sx={{
                      mt: 0.7,
                      color: "#363636",
                      fontFamily: "Urbanist-Bold",
                    }}
                  >
                    <SwitchInput
                      color={"primary"}
                      aria-label="checkedF"
                      onClick={() => toggleSwitchAll(key)}
                      on={selectAll}
                      sx={{ mr: 1 }}
                    />
                    Select All Days
                  </InputLabel>
                </Grid>

                <Grid item xs={4}>
                  <InputLabel
                    sx={{
                      mb: 0.5,
                      color: "#363636",
                      fontFamily: "Urbanist-Bold",
                    }}
                  >
                    Start Time:
                  </InputLabel>

                  <TimePickerInput
                    disabled={!selectAll}
                    onChange={(value) => {
                      onChangeAll(value);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <InputLabel
                    sx={{
                      mb: 0.5,
                      color: "#363636",
                      fontFamily: "Urbanist-Bold",
                    }}
                  >
                    End Time:
                  </InputLabel>

                  <TimePickerInput
                    disabled={!selectAll}
                    onChange={(value) => onChangeAllEnd(value)}
                  />
                </Grid>
              </Grid>
            )}
            <div>
              <DaySelect
                label={"Monday"}
                dayStatus={monday}
                switchStatus={toggleSwitch}
                startTime={startTimeModay}
                endTime={endTimeModay}
                onStart={setStartTimeMonday}
                onEnd={setEndTimeMonday}
                error={error?.monday}
              />
              <DaySelect
                label={"Tuesday"}
                dayStatus={tuesday}
                switchStatus={toggleSwitchThusday}
                startTime={startTimeTuesday}
                endTime={endTimeTuesday}
                onStart={setStartTimeTuesday}
                onEnd={setEndTimeTuesday}
                error={error?.tuesday}
              />
              <DaySelect
                label={"Wednesday"}
                dayStatus={wednesday}
                switchStatus={toggleSwitchWednesday}
                startTime={startTimeWednesday}
                endTime={endTimeWednesday}
                onStart={setStartTimeWednesday}
                onEnd={setEndTimeWednesday}
                error={error?.wednesday}
              />
              <DaySelect
                label={"Thursday"}
                dayStatus={thursday}
                switchStatus={toggleSwitchThurday}
                startTime={startTimeThursday}
                endTime={endTimeThursday}
                onStart={setStartTimeThursday}
                onEnd={setEndTimeThursday}
                error={error?.thursday}
              />
              <DaySelect
                label={"Friday"}
                dayStatus={friday}
                switchStatus={toggleSwitchFriday}
                startTime={startTimeFriday}
                endTime={endTimeFriday}
                onStart={setStartTimeFriday}
                onEnd={setEndTimeFriday}
                error={error?.friday}
              />

              <DaySelect
                label={"Saturday"}
                dayStatus={saturday}
                switchStatus={toggleSwitchSturday}
                startTime={startTimeSaturday}
                endTime={endTimeSaturday}
                onStart={setStartTimeSaturday}
                onEnd={setEndTimeSaturday}
                error={error?.saturday}
              />

              <DaySelect
                label={"Sunday"}
                dayStatus={sunday}
                switchStatus={toggleSwitchSunday}
                startTime={startTimeSunday}
                endTime={endTimeSunday}
                onStart={setStartTimeSunday}
                onEnd={setEndTimeSunday}
                error={error?.sunday}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12}>
          {typeof error === "string" && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
        </Grid>
        <div
          className={"theme-line-breaker"}
          style={{ position: "absolute", left: 0, bottom: 0 }}
        />
      </Grid>
    </div>
  );
};
