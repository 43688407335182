import React, { useState } from "react";
import DashLogo from "../../Assets/SVG/Dashlogo.svg";
import CreateCompain from "../../Assets/SVG/CreateCampaign.svg";
import SavedGoldFinches from "../../Assets/SVG/SavedGoldFinches.svg";
import AddFranchise from "../../Assets/SVG/AddFranchise.svg";
import Analytics from "../../Assets/SVG/Analytics.svg";
import BuyPackages from "../../Assets/SVG/BuyPackages.svg";
import Logout from "../../Assets/SVG/Logout.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { LuFilter } from "react-icons/lu";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Box,
  Stack,
  IconButton as IconBtn,
  Typography,
  Grid,
  Button,
  IconButton,
  useMediaQuery,
  Paper,
} from "@mui/material";
import IconFeather_IMG from "../../Assets/SVG/Iconfeather.svg";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import StickyHeadTable from "../../Components/Table/index.jsx";
import { styled } from "@mui/material/styles";
import { VscEdit } from "react-icons/vsc";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiAddToQueue } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { RiArrowDropUpFill } from "react-icons/ri";
import { RiArrowDropDownFill } from "react-icons/ri";
import { Theme } from "../../Theme";
import GradientBox from "../../Components/GradientBox";
import SideBar from "../../Components/Sidebar";
import CreateFinch from "../../Components/Forms/CreateFinch";
import CreateGoldFinch from "../../Components/Forms/CreateGoldFinch";
import { BsSearch } from "react-icons/bs";
import BasicModal from "../../Components/Modal";
import DeleteComp from "../../Components/Modal/DeleteComp";
import DateChangeComp from "../../Components/Modal/DateChangeComp";
import CancelSubsComp from "../../Components/Modal/CancelSubsComp";
import "../../App.css";
import MyProfile from "../../Components/Forms/MyProfile";
import { useNavigate } from "react-router-dom";
import { VIEW_PROFILE_PATH } from "../../Constants/Paths";
import ColumnButton from "../../Components/Buttons/ColumnButton";
import SimpleMenu from "../../Components/Menu";
import ColumnMenu from "../../Components/Menu/ColumnMenu";
import ExpandableSearchBar from "../../Components/Inputs/ExpandableSearchBar";
import TableMenu from "../../Components/Menu/TableMenu";
import SuspReasonComp from "../../Components/Modal/SuspReasonComp";

const AccountRequests = () => {
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignSidebar, setCampaignSidebar] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [CreateFinchForm, setCreteFinchForm] = useState(false);
  const [CreateGoldFinchForm, setCreteGoldFinchForm] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const menuOpen1 = Boolean(anchorEl1);
  const menuOpen2 = Boolean(anchorEl2);
  const menuOpen3 = Boolean(anchorEl3);
  // const [count, setCount] = useState(user?.count || 0);
  // const [nextPage, setNextPage] = useState(userData?.next);
  // const [previousPage, setPreviousPage] = useState(userData?.previous);

  const titleLiner = (title) => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
        {title}
      </Typography>
      {/* <Stack sx={{ m: 0, p: 0 }}>
        <RiArrowDropUpFill style={{ marginBottom: -5 }} />
        <RiArrowDropDownFill style={{ marginTop: -5 }} />
      </Stack> */}
    </Box>
  );

  const textLiner = (color, fontSize, text, bgColor = null) => {
    return (
      <Typography
        sx={{
          color: color,
          fontSize: fontSize,
          backgroundColor: bgColor,
          borderRadius: "4px",
          //   px: 1,
          fontFamily: "Urbanist-Semibold",
        }}
      >
        {text}
      </Typography>
    );
  };

  const columns = [
    {
      id: "partnerId",
      label: titleLiner("Partner ID"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "date",
      label: titleLiner("Date"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "partnerName",
      label: titleLiner("Partner Name"),
      minWidth: 170,
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "email",
      label: titleLiner("Email"),
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "contactNumber",
      label: titleLiner("Contact Number"),
      minWidth: 270,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "status",
      label: titleLiner("Status"),
      minWidth: 10,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
    {
      id: "actions",
      label: titleLiner("Actions"),
      minWidth: 10,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
      fontSize: "18px",
      fontWeight: 600,
    },
  ];

  const handleClick = (event, id) => {
    setSelectedId(id);
    if (event === "delete") {
      handleModal(id);
    }
  };
  const handleMenuClick = (event, state, id) => {
    if (state === "filter") {
      setAnchorEl1(event.currentTarget);
    } else if (state === "column") {
      setAnchorEl2(event.currentTarget);
    } else if (state === "table") {
      setAnchorEl3(event.currentTarget);
    } else if (state === "Suspend") {
      setOpen(true);
    }
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const handleModal = (id) => {
    setOpen(true);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  let franchises = ["Calgary", "Line Chest", "Acura", "My Home"];

  let userData = [
    {
      partnerId: textLiner(Theme.color.text, "18px", "001"),
      date: textLiner(Theme.color.text, "18px", "Aug-08-2023"),
      partnerName: textLiner(Theme.color.text, "18px", "Amjad Dhaba"),
      email: textLiner(Theme.color.text, "18px", "amjadali@gmail.com"),
      contactNumber: textLiner(Theme.color.text, "18px", "+923353854999"),
      status: textLiner(
        Theme.color.red,
        "16px",
        "Rejected",
        "rgba(220, 40, 69, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            onClick={() => {
              setCampaignSidebar(true);
            }}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => navigate(VIEW_PROFILE_PATH)}
            sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <AiOutlineEye color={Theme.color.white} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleMenuClick(e, "table")}
            // sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <MoreVertOutlinedIcon sx={{ color: Theme.color.basic }} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
        </Stack>
      ),
    },
    {
      partnerId: textLiner(Theme.color.text, "18px", "002"),
      date: textLiner(Theme.color.text, "18px", "Aug-08-2023"),
      partnerName: textLiner(Theme.color.text, "18px", "Amjad Dhaba"),
      email: textLiner(Theme.color.text, "18px", "amjadali@gmail.com"),
      contactNumber: textLiner(Theme.color.text, "18px", "+923353854999"),
      status: textLiner(
        Theme.color.golden,
        "16px",
        "Suspended",
        "rgba(255, 220, 68, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => navigate(VIEW_PROFILE_PATH)}
            sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <AiOutlineEye color={Theme.color.white} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            // sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <MoreVertOutlinedIcon sx={{ color: Theme.color.basic }} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
        </Stack>
      ),
    },
    {
      partnerId: textLiner(Theme.color.text, "18px", "003"),
      date: textLiner(Theme.color.text, "18px", "Aug-08-2023"),
      partnerName: textLiner(Theme.color.text, "18px", "Amjad Dhaba"),
      email: textLiner(Theme.color.text, "18px", "amjadali@gmail.com"),
      contactNumber: textLiner(Theme.color.text, "18px", "+923353854999"),
      status: textLiner(
        "#17C282",
        "16px",
        "Approved",
        "rgba(91, 206, 163, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => navigate(VIEW_PROFILE_PATH)}
            sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <AiOutlineEye color={Theme.color.white} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            // sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <MoreVertOutlinedIcon sx={{ color: Theme.color.basic }} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
        </Stack>
      ),
    },
    {
      partnerId: textLiner(Theme.color.text, "18px", "004"),
      date: textLiner(Theme.color.text, "18px", "Aug-08-2023"),
      partnerName: textLiner(Theme.color.text, "18px", "Amjad Dhaba"),
      email: textLiner(Theme.color.text, "18px", "amjadali@gmail.com"),
      contactNumber: textLiner(Theme.color.text, "18px", "+923353854999"),
      status: textLiner(
        Theme.color.red,
        "16px",
        "Rejected",
        "rgba(220, 40, 69, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => navigate(VIEW_PROFILE_PATH)}
            sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <AiOutlineEye color={Theme.color.white} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            // sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <MoreVertOutlinedIcon sx={{ color: Theme.color.basic }} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
        </Stack>
      ),
    },
    {
      partnerId: textLiner(Theme.color.text, "18px", "003"),
      date: textLiner(Theme.color.text, "18px", "Aug-08-2023"),
      partnerName: textLiner(Theme.color.text, "18px", "Amjad Dhaba"),
      email: textLiner(Theme.color.text, "18px", "amjadali@gmail.com"),
      contactNumber: textLiner(Theme.color.text, "18px", "+923353854999"),
      status: textLiner(
        "#17C282",
        "16px",
        "Approved",
        "rgba(91, 206, 163, 0.23)"
      ),
      actions: (
        <Stack direction="row" spacing={1} justifyContent="flex-start">
          <IconBtn
            aria-label="flag"
            // sx={
            //   {
            //     // backgroundColor: v.is_active
            //     //   ? Theme.bg.orange
            //     //   : Theme.bg.lightGrey,
            //   }
            // }
            disableElevation={true}
            disableRipple
            sx={{ backgroundColor: Theme.bg.lightGreen, borderRadius: "5px" }}
            // onClick={(e) => (v.is_active ? handleClick("flag", v.id) : null)}
            // disabled={!v.is_active}
          >
            <VscEdit color={Theme.color.white} />
            {/* <OutlinedFlagIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => navigate(VIEW_PROFILE_PATH)}
            sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <AiOutlineEye color={Theme.color.white} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
          <IconBtn
            aria-label="delete"
            // sx={{ backgroundColor: Theme.bg.maroon }}
            disableElevation={true}
            disableRipple
            onClick={(e) => handleClick("delete")}
            // sx={{ backgroundColor: Theme.bg.lightSky, borderRadius: "5px" }}
          >
            <MoreVertOutlinedIcon sx={{ color: Theme.color.basic }} />
            {/* <DeleteOutlineIcon style={{ color: Theme.color.txt2 }} /> */}
          </IconBtn>
        </Stack>
      ),
    },
  ];
  let options = [
    "Partner ID",
    "Date",
    "Partner Name",
    "Email",
    "Contact Number",
    "Status",
    "Actions",
  ];
  let option2 = ["Approved", "Suspend", "Request"];

  //static pagination
  let paginatedData = {
    count: 20,
    next: 2,
    previous: null,
    results: userData,
  };
  const [users, setUsers] = useState(paginatedData.results);
  const [currentPage, setCurrentPage] = useState(1);

  const getUsers = (page) => {
    if (page === 1) {
      setUsers(paginatedData.results);
      setCurrentPage(1);
    } else if (page === 2) {
      setUsers(paginatedData.results);
      setCurrentPage(2);
    } else {
    }
  };

  return (
    <Box sx={{ p: 1, height: "auto" }}>
      {!isSmallScreen ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: 1, sm: 2, md: 5 },
            }}
          >
            <Typography
              style={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}
            >
              All Partners
            </Typography>
            <ColumnButton
              onClick={(e) => {
                handleMenuClick(e, "column");
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            {/* <Button
              variant="outlined"
              style={{
                color: "#88949E",
                borderColor: "#E7EAEE",
                borderRadius: 5,
                padding: 10,
              }}
            >
              <BsSearch style={{ fontSize: 20 }} />
            </Button> */}
            <ExpandableSearchBar />
          </Box>
        </Box>
      ) : (
        <Paper sx={{ px: 1, py: 2, my: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              my: 2,
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 1, sm: 2, md: 5 },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "32px",
                  // fontSize: { xs: "24px", sm: "24px", md: "34px" },
                }}
              >
                All Partners
              </Typography>
              {/* <img src={IconFeather_IMG} alt="" /> */}
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button
                variant="outlined"
                sx={{
                  color: "#88949E",
                  borderColor: "#E7EAEE",
                  // borderRadius: 5,
                  px: 1,
                  py: 1,
                }}
              >
                <BsSearch style={{ fontSize: 20 }} />
              </Button>
            </Box> */}
          </Box>
        </Paper>
      )}
      <Box sx={{ my: 2 }}>
        <StickyHeadTable
          columns={columns}
          rows={paginatedData.results}
          count={paginatedData?.count || 0}
          next={
            paginatedData?.next
              ? (callback) => getUsers(users?.next, callback)
              : null
          }
          previous={
            paginatedData?.previous
              ? (callback) => getUsers(users?.previous, callback)
              : null
          }
          loading={loading}
        />
      </Box>
      <SimpleMenu
        handleClose={handleClose2}
        open={menuOpen2}
        anchorEl={anchorEl2}
        data={
          <ColumnMenu
            selectedId={selectedId}
            handleOptionClick={handleMenuClick}
            options={options}
          />
        }
      />
      <SimpleMenu
        handleClose={handleClose3}
        open={menuOpen3}
        anchorEl={anchorEl3}
        data={
          <TableMenu
            handleClose={handleClose3}
            selectedId={selectedId}
            handleOptionClick={handleMenuClick}
            options={option2}
          />
        }
      />
      <SideBar
        open={campaignSidebar}
        onClose={() => setCampaignSidebar(false)}
        title={selectedId ? "Edit Client" : "Profile Details"}
        form={
          <MyProfile
            id={selectedId}
            // onEdit={getAllClients}
            onClose={() => setCampaignSidebar(false)}
          />
        }
      />

      <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        styles={{ px: 4, width: "900", height: "500px" }}
        data={<SuspReasonComp />}
      />
    </Box>
  );
};

export default AccountRequests;
