import React, { useState, useEffect } from "react";
import useFetch from "../Utils/useFetch";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_CUSTOMER_SUBSCRIPTION,
  ADD_PACKAGE,
  ADD_PACKAGE_DETAILS,
  ADD_PAYMENT_METHOD,
  ADD_USER,
} from "../Redux/types";
import {
  CHANGE_DEFAULT_PAYMENT_METHOD_API,
  CREATE_CARD_API,
  CREATE_SUBSCRIPTION,
  GET_CUSTOMER_SUBSCRIPTION,
  GET_PACKAGES_API,
  GET_PACKAGE_DETAILS_API,
  GET_PAYMENT_METHOD_API,
  REMOVE_PAYMENT_METHOD_API,
  TOGGLE_SUBSCRIPTION_API,
} from "../Constants/APIs";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";

const usePayment = (paymentData, onClose = () => null) => {
  const confirm = useConfirm();
  const { request } = useFetch();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const subscriptions = useSelector(
    (state) => state.paymentReducer.subscriptions
  );
  const partnerFinches = useSelector(
    (state) => state.partnerFinchesReducer.partnerFinches
  );

  const partnerFranchises = useSelector(
    (state) => state.partnerFranchisesReducer.partnerFranchises
  );
  const [paymentMethod, setPaymentMethod] = useState("");

  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [rpmLoading, setRPMLoading] = useState(false);
  const [defaultLoading, setDefaultLoading] = useState(null);

  useEffect(() => {
    getPaymentMethods();
    getPackages();
  }, []);

  useEffect(() => {
    getPackageDetails();
  }, [partnerFinches?.length, partnerFranchises?.length]);

  const getPaymentMethods = async () => {
    try {
      const req = await request(GET_PAYMENT_METHOD_API, "GET");
      // console.log(req?.data, "GET_PAYMENT_METHOD_API");
      dispatch({
        type: ADD_PAYMENT_METHOD,
        payload: req?.data?.paymentMethods,
      });
    } catch (err) {
      console.log(err, "GET_PAYMENT_METHOD_API");
    }
  };
  const getPackages = async () => {
    try {
      const req = await request(GET_PACKAGES_API, "GET");
      dispatch({
        type: ADD_PACKAGE,
        payload: req?.data?.data,
      });
    } catch (err) {
      console.log(err, "GET_PACKAGES_API");
    }
  };

  const buyPackage = async (event, paymentMethodId = paymentMethod) => {
    try {
      if (!paymentMethodId) {
        document.getElementById("card-form-submit").click();
        return;
      } else if (paymentMethodId) {
        setLoading(true);
      }

      const req = await request(CREATE_SUBSCRIPTION, "POST", {
        customer: user.stripeId,
        packageType: paymentData?.packageType,
        itemId: paymentData?.stripePriceId,
        quantity: paymentData?.quantity,
        paymentMethodId,
        customerUid: user.uid,
      });

      let successMessage = "Package Subscribed Successfully. ";
      if (req?.data?.isFree) {
        successMessage +=
          "30 days free trial started, you'll be charged after free trail.";
      }

      toast.success(successMessage);
      onClose();
      setLoading(false);
      getCustomerSubscription();
    } catch (err) {
      console.log(err, "error response");
      if (err) toast.error(err?.message);

      setLoading(false);
    }
    setPaymentMethod("");
  };

  const addNewCard = async (token, onlyCard = false) => {
    try {
      if (!token || !user?.stripeId) {
        return;
      }
      setLoading(true);

      const req = await request(CREATE_CARD_API, "POST", {
        token: token.id,
        customer: user?.stripeId,
      });

      if (req?.data?.paymentMethods?.alreadyExists) {
        toast.info("This Card Already Exists");

        setLoading(false);
        onClose();
        return;
      }

      getPaymentMethods();

      if (onlyCard) {
        setLoading(false);
        onClose();
        toast.success("New Card Added Successfully");
      } else {
        await buyPackage(null, req?.data?.paymentMethods?.id);
      }
    } catch (err) {
      console.log(err, "error response");
      toast.error(err?.message);
      setLoading(false);
    }
  };

  const getCustomerSubscription = async (token) => {
    try {
      setSubLoading(true);

      const req = await request(GET_CUSTOMER_SUBSCRIPTION);

      setSubLoading(false);
      dispatch({
        type: ADD_CUSTOMER_SUBSCRIPTION,
        payload: req?.data?.subscription?.sort((a, b) => b.created - a.created),
      });
    } catch (err) {
      console.log(err, "error response");
      toast.error(err?.message);
      setSubLoading(false);
    }
  };

  const getPackageDetails = async () => {
    try {
      const req = await request(GET_PACKAGE_DETAILS_API);

      dispatch({
        type: ADD_PACKAGE_DETAILS,
        payload: req?.data?.data,
      });
    } catch (err) {
      console.log(err, "error GET_PACKAGE_DETAILS_API");
    }
  };

  const removePaymentMethod = async (paymentMethodId) => {
    try {
      await confirm({
        title: "Are you sure you want to delete this card?",
        description:
          "All subscriptions associated with this card will be stopped.",
      });
      if (!paymentMethodId) return;
      setRPMLoading(paymentMethodId);
      const req = await request(REMOVE_PAYMENT_METHOD_API, "POST", {
        paymentMethodId,
        customer: user?.stripeId,
      });

      getPaymentMethods();

      setRPMLoading(null);
    } catch (err) {
      console.log(err, "error response");
      toast.error(err?.message);
      setRPMLoading(null);
    }
  };

  const changeDefaultPaymentMethod = async (paymentMethodId) => {
    try {
      if (!paymentMethodId) return;
      setDefaultLoading(paymentMethodId);
      const req = await request(CHANGE_DEFAULT_PAYMENT_METHOD_API, "POST", {
        paymentMethodId,
      });

      dispatch({ type: ADD_USER, payload: req?.data?.data });
      setDefaultLoading(null);
    } catch (err) {
      console.log(err, "error response");
      toast.error(err?.message);
      setDefaultLoading(null);
    }
  };
  const toggleSubscription = async (
    subscriptionId,
    type,
    toggleValue,
    onCallback = () => null
  ) => {
    confirm({
      title: `${toggleValue ? "Activate" : "Cancel"} Subscription`,
      description: `Are you sure you want to ${
        toggleValue ? "activate" : "cancel"
      } this subscription?`,
    })
      .then(async () => {
        if (!subscriptionId) return;
        setLoading(subscriptionId);

        const req = await request(TOGGLE_SUBSCRIPTION_API, "POST", {
          userId: user?.uid,
          subscriptionId,
          type,
          value: !toggleValue,
        });

        dispatch({
          type: ADD_CUSTOMER_SUBSCRIPTION,
          payload: subscriptions.map((sub) => {
            if (sub.subscriptionId === subscriptionId) {
              sub.cancelAtPeriodEnd = !sub.cancelAtPeriodEnd;
            }
            return sub;
          }),
        });
        setLoading(false);
        // onCallback(
        //   subscriptions.map((sub) => {
        //     if (sub.subscriptionId === subscriptionId) {
        //       sub.cancelAtPeriodEnd = !sub.cancelAtPeriodEnd;
        //     }
        //     return sub;
        //   })
        // );
      })
      .catch((err) => {
        console.log(err, "error response");
        toast.error(err?.message);
        setLoading(false);
      });
  };

  return {
    getPaymentMethods,
    loading,
    getPackages,
    addNewCard,
    paymentMethod,
    setPaymentMethod,
    buyPackage,
    getCustomerSubscription,
    removePaymentMethod,
    rpmLoading,
    changeDefaultPaymentMethod,
    defaultLoading,
    toggleSubscription,
    subLoading,
    setSubLoading,
    getPackageDetails,
  };
};

export default usePayment;
