import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, ButtonGroup, Grid } from "@mui/material";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";

const useStyles = makeStyles(() => ({
  buttonGroup: {
    marginTop: "0px",
    width: "100%!important",
  },
}));

const DayPicker = ({ value, onChange }) => {
  const classes = useStyles();

  const handleDayToggle = (day) => {
    if (value?.includes(day)) {
      onChange(value?.filter((d) => d !== day));
    } else {
      onChange([...value, day]);
    }
  };
  return (
    <ButtonGroup className={classes.buttonGroup}>
      <Grid container spacing={1} style={{ width: "100%" }}>
        {[0, 1, 2, 3, 4, 5, 6].map((day) => (
          <Grid item>
            <PrimaryButton
              text={
                [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ][day]
              }
              sx={[
                value?.includes(day)
                  ? {
                      color: Theme.color.white,
                      backgroundColor: "#E7404A",
                      borderColor: "#E7404A",
                      "&.MuiButton-root": {
                        "&:hover": {
                          backgroundColor: "#E7404A",
                          borderColor: "#E7404A",
                        },
                      },
                    }
                  : {
                      color: Theme.color.basic,
                      backgroundColor: "#32C28C",
                      borderColor: "#32C28C",
                      "&.MuiButton-root": {
                        "&:hover": {
                          backgroundColor: "#32C28C",
                          borderColor: "#32C28C",
                        },
                      },
                    },
              ]}
              key={day}
              variant={"outlined"}
              onClick={() => handleDayToggle(day)}
            />
          </Grid>
        ))}
      </Grid>
    </ButtonGroup>
  );
};

export default DayPicker;
