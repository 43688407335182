import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HiLink } from "react-icons/hi";
import { Theme } from "../../Theme";
import PrimaryButton from "../Buttons/PrimaryButton";
import "../../App.css";
import CancelSubsCard from "../Cards/CancelSubsCard";
import usePayment from "../../ApiHooks/usePayment";
import { useSelector } from "react-redux";
import moment from "moment";
import { capitalize, startCase } from "lodash";
import CancelFranchiseCard from "../Cards/CancelFrachiseCard";

const BillingComp = ({ onNewCard }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const {
    getCustomerSubscription,
    removePaymentMethod,
    rpmLoading,
    changeDefaultPaymentMethod,
    defaultLoading,
    subLoading,
    setSubLoading,
  } = usePayment();
  const subscriptions = useSelector(
    (state) => state.paymentReducer.subscriptions
  );
  const paymentMethods = useSelector(
    (state) => state.paymentReducer.paymentMethods
  );
  const user = useSelector((state) => state.userReducer.user);

  const [activePackages, setActivePackages] = useState({
    campaign: {},
    franchise: [],
  });

  useEffect(() => {
    getCustomerSubscription();
  }, []);

  const getActivePackages = (subscription = []) => {
    // console.log("getActivePackages", subscription);
    const tempObj = {
      campaign: {},
      franchise: [],
    };
    subscription?.forEach((item) => {
      if (item.isFree) return;
      if (
        item?.status !== "canceled" &&
        item?.packageType === "CAMPAIGN" &&
        moment().isBefore(moment(item?.currentPeriodEnd * 1000))
      ) {
        tempObj.campaign = item;
      }
      if (
        item?.status !== "canceled" &&
        item?.packageType === "FRANCHISE" &&
        moment().isBefore(moment(item?.currentPeriodEnd * 1000))
      ) {
        tempObj.franchise.push(item);
      }
    });
    return tempObj;
    // setActivePackages(tempObj);
  };

  return (
    <Box sx={{ backgroundColor: "#fff", p: 2, borderRadius: "8px" }}>
      {subLoading && (
        <Grid
          container
          alignItems={"center"}
          alignContent={"center"}
          spacing={1}
        >
          <Grid item>
            <CircularProgress size={20} />
          </Grid>
          <Grid item>Fetching latest Data</Grid>
        </Grid>
      )}
      {isSmallScreen ? (
        <Box sx={{ borderRadius: "8px", p: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Box sx={{}}>
                <Typography
                  sx={{ fontFamily: "Urbanist-Bold", fontSize: "22px", mb: 2 }}
                >
                  Payment History
                </Typography>
                <Paper
                  sx={{
                    mb: 2,
                    py: 2,
                    px: 1,
                    "& > :not(:last-child)": {
                      borderBottom: `1px solid ${Theme.color.borderColor}`,
                      mb: 1,
                    },
                  }}
                >
                  {subscriptions?.map((item) => {
                    return (
                      <Grid
                        container
                        sx={{ display: "flex", gap: 2 }}
                        key={item.id + "opop"}
                      >
                        <Grid container item>
                          <Grid item xs={6}>
                            <Typography sx={{ color: Theme.color.gray }}>
                              Created At
                            </Typography>
                            <Typography sx={{ fontFamily: "Urbanist-Bold" }}>
                              {moment(new Date(item?.created * 1000))?.format(
                                "ll"
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography sx={{ color: Theme.color.gray }}>
                              Next Invoice
                            </Typography>
                            <Typography sx={{ fontFamily: "Urbanist-Bold" }}>
                              {item?.status === "active" ? (
                                <span>
                                  {item?.cancelAtPeriodEnd && "End On "}
                                  {moment(item?.currentPeriodEnd * 1000).format(
                                    "ll"
                                  )}
                                </span>
                              ) : item?.isFree ? (
                                <span>Trial Ended</span>
                              ) : (
                                <span>
                                  Canceled, at{" "}
                                  {moment(item?.canceledAt * 1000).format(
                                    "lll"
                                  )}
                                </span>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Grid item xs={6}>
                            <Typography sx={{ color: Theme.color.gray }}>
                              Package Name
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Urbanist-Bold",
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.isFree
                                ? "Free"
                                : item?.productName?.toLowerCase()}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography sx={{ color: Theme.color.gray }}>
                              Package Type
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Urbanist-Bold",
                                textTransform: "capitalize",
                              }}
                            >
                              {item?.packageType?.toLowerCase()}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Grid item xs={6}>
                            <Typography sx={{ color: Theme.color.gray }}>
                              Card
                            </Typography>
                            <Typography sx={{ fontFamily: "Urbanist-Bold" }}>
                              {item?.cardLast4
                                ? "**** " + item?.cardLast4
                                : item?.isFree
                                ? "Trial"
                                : "-"}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography sx={{ color: Theme.color.gray }}>
                              Price
                            </Typography>
                            <Typography sx={{ fontFamily: "Urbanist-Bold" }}>
                              ${item?.price / 100}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Grid item xs={6}>
                            <Typography sx={{ color: Theme.color.gray }}>
                              Receipt URL
                            </Typography>
                            <IconButton
                              href={item?.receiptUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <HiLink color={Theme.color.basic} />
                            </IconButton>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography sx={{ color: Theme.color.gray }}>
                              Action
                            </Typography>
                            {item?.packageType === "CAMPAIGN" ? (
                              <CancelSubsCard
                                head={"Active Campaign Package"}
                                data={item}
                                onCallBack={getActivePackages}
                              />
                            ) : (
                              <CancelFranchiseCard
                                data={item}
                                onCallBack={getActivePackages}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Paper>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{ display: "flex", flexDirection: "column", gap: 1 }}
            >
              <Paper sx={{ my: 1, p: 1 }}>
                <Typography
                  sx={{
                    fontFamily: "Urbanist-Bold",
                    fontSize: "22px",
                    mb: 1,
                  }}
                >
                  Payment Method On File
                </Typography>
                <Grid container sx={{ alignItems: "center" }} spacing={2}>
                  {paymentMethods?.map((paymentMethod) => {
                    return (
                      <Grid item xs={12}>
                        <Grid
                          container
                          sx={{ alignItems: "center" }}
                          spacing={2}
                          key={paymentMethod.id}
                        >
                          <Grid item lg={6} xs={12}>
                            {startCase(paymentMethod?.cardBrand)} ending in{" "}
                            {paymentMethod?.cardLast4} expiring{" "}
                            {paymentMethod?.cardExpMonth <= 9
                              ? "0" + paymentMethod?.cardExpMonth
                              : paymentMethod?.cardExpMonth}
                            /{paymentMethod?.cardExpYear}
                          </Grid>
                          <Grid item lg={3} xs={12}>
                            <PrimaryButton
                              text={"Remove"}
                              elevation={3}
                              size="large"
                              fullWidth
                              sx={{
                                color: Theme.color.white,
                                backgroundColor: Theme.bg.redBtn,
                                fontFamily: "Urbanist-Bold",
                                "&.MuiButton-root": {
                                  "&:hover": {
                                    backgroundColor: Theme.bg.redBtn,
                                    borderColor: Theme.bg.redBtn,
                                  },
                                },
                                borderRadius: "8px",
                              }}
                              onClick={() =>
                                removePaymentMethod(paymentMethod.id)
                              }
                              loading={rpmLoading === paymentMethod.id}
                            />
                          </Grid>
                          <Grid item lg={3} xs={12}>
                            <PrimaryButton
                              text={"Default Payment Method"}
                              elevation={3}
                              size="large"
                              fullWidth
                              sx={{
                                color: Theme.color.basic,
                                backgroundColor: Theme.bg.yellow,
                                fontFamily: "Urbanist-Bold",
                                "&.MuiButton-root": {
                                  "&:hover": {
                                    backgroundColor: Theme.bg.yellow,
                                    borderColor: Theme.bg.yellow,
                                  },
                                },

                                borderRadius: "8px",
                              }}
                              disabled={
                                user?.defaultPaymentMethod === paymentMethod.id
                              }
                              onClick={() =>
                                changeDefaultPaymentMethod(paymentMethod.id)
                              }
                              loading={defaultLoading === paymentMethod.id}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Button
                  variant="text"
                  sx={{
                    color: "#2D9CFF",
                    fontSize: "22px",
                    fontFamily: "Urbanist-Bold",
                    my: 2,
                  }}
                  onClick={onNewCard}
                >
                  Add New Card
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            border: "2px solid #FFD831",
            borderRadius: "8px",
            py: 2,
            px: 6,
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box>
                <Typography
                  sx={{ fontFamily: "Urbanist-Bold", fontSize: "22px", mb: 2 }}
                >
                  Payment History
                </Typography>
                <Box>
                  <Grid container>
                    <Grid item xs={1.5} sx={{ fontFamily: "Urbanist-Medium" }}>
                      Created Date
                    </Grid>
                    <Grid item xs={1.5} sx={{ fontFamily: "Urbanist-Medium" }}>
                      Next Invoice
                    </Grid>
                    <Grid item xs={2} sx={{ fontFamily: "Urbanist-Medium" }}>
                      Package Name
                    </Grid>
                    <Grid item xs={2} sx={{ fontFamily: "Urbanist-Medium" }}>
                      Package Type
                    </Grid>
                    <Grid item xs={1} sx={{ fontFamily: "Urbanist-Medium" }}>
                      Card
                    </Grid>
                    <Grid item xs={1} sx={{ fontFamily: "Urbanist-Medium" }}>
                      Price
                    </Grid>
                    <Grid item xs={1} sx={{ fontFamily: "Urbanist-Medium" }}>
                      Receipt URL
                    </Grid>
                    <Grid item xs={2} sx={{ fontFamily: "Urbanist-Medium" }}>
                      Action
                    </Grid>
                  </Grid>
                  {subscriptions?.map((item) => {
                    console.log(item, ":SUB");
                    return (
                      <Grid
                        key={item.id}
                        container
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderBottom: "1px solid #AFAFAF",
                          my: 1,
                          py: 1,
                        }}
                      >
                        <Grid item xs={1.5}>
                          {moment(new Date(item?.created * 1000))?.format("ll")}
                        </Grid>
                        <Grid item xs={1.5}>
                          {item?.status === "active" ? (
                            <span>
                              {item?.cancelAtPeriodEnd && "End On "}
                              {moment(item?.currentPeriodEnd * 1000).format(
                                "ll"
                              )}
                            </span>
                          ) : item?.isFree ? (
                            <span>Trial Ended</span>
                          ) : (
                            <span>
                              Canceled, at{" "}
                              {moment(item?.canceledAt * 1000).format("lll")}
                            </span>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {item?.isFree
                            ? "Free"
                            : item?.productName?.toLowerCase()}
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {item?.packageType?.toLowerCase()}
                        </Grid>
                        <Grid item xs={1}>
                          {item?.cardLast4
                            ? "**** " + item?.cardLast4
                            : item?.isFree
                            ? "Trial"
                            : "-"}
                        </Grid>
                        <Grid item xs={1}>
                          ${item?.price / 100}
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            href={item?.receiptUrl}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <HiLink color={Theme.color.basic} />
                          </IconButton>
                        </Grid>

                        <Grid item xs={2}>
                          {item?.packageType === "CAMPAIGN" ? (
                            <CancelSubsCard
                              head={"Active Campaign Package"}
                              data={item}
                              onCallBack={getActivePackages}
                            />
                          ) : (
                            <CancelFranchiseCard
                              data={item}
                              onCallBack={getActivePackages}
                            />
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
                <Box sx={{ my: 4, mt: 12 }}>
                  <Typography
                    sx={{
                      fontFamily: "Urbanist-Bold",
                      fontSize: "22px",
                      mb: 2,
                    }}
                  >
                    Payment Method On File
                  </Typography>
                  {paymentMethods?.map((paymentMethod) => {
                    return (
                      <Grid
                        container
                        sx={{ alignItems: "center", my: 3 }}
                        spacing={2}
                        key={paymentMethod.id}
                      >
                        <Grid item xs={5}>
                          {startCase(paymentMethod?.cardBrand)} ending in{" "}
                          {paymentMethod?.cardLast4} expiring{" "}
                          {paymentMethod?.cardExpMonth <= 9
                            ? "0" + paymentMethod?.cardExpMonth
                            : paymentMethod?.cardExpMonth}
                          /{paymentMethod?.cardExpYear}
                        </Grid>
                        <Grid item xs={3}>
                          <PrimaryButton
                            text={"Remove"}
                            elevation={3}
                            size="large"
                            fullWidth
                            sx={{
                              color: Theme.color.white,
                              backgroundColor: Theme.bg.redBtn,
                              fontFamily: "Urbanist-Bold",
                              "&.MuiButton-root": {
                                "&:hover": {
                                  backgroundColor: Theme.bg.redBtn,
                                  borderColor: Theme.bg.redBtn,
                                },
                              },
                              borderRadius: "8px",
                            }}
                            onClick={() =>
                              removePaymentMethod(paymentMethod.id)
                            }
                            loading={rpmLoading === paymentMethod.id}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <PrimaryButton
                            text={"Default Payment Method"}
                            elevation={3}
                            size="large"
                            fullWidth
                            sx={{
                              color: Theme.color.basic,
                              backgroundColor: Theme.bg.yellow,
                              fontFamily: "Urbanist-Bold",
                              "&.MuiButton-root": {
                                "&:hover": {
                                  backgroundColor: Theme.bg.yellow,
                                  borderColor: Theme.bg.yellow,
                                },
                              },

                              borderRadius: "8px",
                            }}
                            disabled={
                              user?.defaultPaymentMethod === paymentMethod.id
                            }
                            onClick={() =>
                              changeDefaultPaymentMethod(paymentMethod.id)
                            }
                            loading={defaultLoading === paymentMethod.id}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Button
                    variant="text"
                    sx={{
                      color: "#2D9CFF",
                      fontSize: "22px",
                      fontFamily: "Urbanist-Bold",
                      my: 2,
                    }}
                    onClick={onNewCard}
                  >
                    Add New Card
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default BillingComp;
