import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Theme } from "../../Theme";
import "../../App.css";
import { FiCheckCircle } from "react-icons/fi";
import Tick_IMG from "../../Assets/Image/Tick.png";
import Discount_IMG from "../../Assets/Image/Discount.png";
import PrimaryButton from "../Buttons/PrimaryButton";
const CampaignCard = ({ data, onClose }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  console.log("CampaignCard:", onClose);

  return (
    <Grid
      item
      sx={{
        borderRadius: isSmallScreen ? "8px" : "30px",
        backgroundColor: Theme.color.basic,
        py: { xs: 2, sm: 2, md: 3, lg: 3, xl: 3 },
        px: { xs: 6, sm: 6, md: 4, lg: 8 },
      }}
      xs={12}
      sm={12}
      md={3}
    >
      <Box>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "22px",
            color: Theme.color.white,
            fontFamily: "Urbanist-Semibold",
            //   mt:!data.discount? 5:null
          }}
        >
          {data.plan}
        </Typography>
        {Boolean(data.price) ? (
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "44px",
              fontFamily: "Urbanist-Bold",
              color: Theme.color.yellow,
              // my: 2,
            }}
          >
            ${data.price}
          </Typography>
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "44px",
              fontFamily: "Urbanist-Bold",
              color: Theme.color.yellow,
              // my: 2,
            }}
          >
            Packages
          </Typography>
        )}
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "22px",
            color: Theme.color.white,
            fontFamily: "Urbanist-Semibold",
            //   mt:!data.discount? 5:null
          }}
        >
          {data.package}
        </Typography>
      </Box>
      <Divider
        sx={{
          bgcolor: "#3B3B3B",
          width: "100px",
          margin: "0 auto",
          mb: data.plan ? 0 : 8,
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4, my: 3 }}>
        {data?.items?.map((v, i) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 4,
                borderLeft: "3px solid #FFD831",
                borderRight: "3px solid #FFD831",
                borderRadius: "3px",
                px: 2,
              }}
              key={i}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#fff",
                  fontFamily: "Urbanist-Medium",
                }}
              >
                {v.item}
              </Typography>
            </Box>
          );
        })}
      </Box>
      {data.button ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <PrimaryButton
            text={"Buy Now"}
            onClick={() => onClose({ data })}
            sx={{
              color: Theme.color.basic,
              backgroundColor: Theme.bg.yellow,
              "&.MuiButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.yellow,
                  borderColor: Theme.bg.yellow,
                },
              },
              width: "70%",
              py: 1.5,
              fontFamily: "Urbanist-Bold",
              borderRadius: "10px",
              fontSize: "16px",
              // px:
            }}
          />
        </Box>
      ) : null}
    </Grid>
  );
};

export default CampaignCard;
