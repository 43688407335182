import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Contact_IMG from "../../Assets/Image/contact.png";
import Email_IMG from "../../Assets/Image/email.png";
import Website_IMG from "../../Assets/Image/website.png";
import Dish_IMG from "../../Assets/Image/Dish.png";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import "../../App.css";
import GoogleMap from "../../Components/GoogleMap/GoogleMap";

const ViewProfile = () => {
  return (
    <Box sx={{ p: 1 }}>
      <Box>
        <Grid container spacing={3} sx={{ py: 2 }}>
          <Grid item xs={12} sm={12} md={3}>
            <Paper
              sx={{
                px: 3,
                boxShadow: "0px 3px 6px #0000000F",
                height: "100%",
                borderRadius: "16px",
              }}
              elevation={0}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 1,
                  py: 4,
                }}
              >
                <img src={Dish_IMG} alt="" />
                <Typography
                  sx={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}
                >
                  Amjad Dhaba
                </Typography>
                <Typography
                  sx={{ fontFamily: "Urbanist-Regular", color: "#919191" }}
                >
                  @Amjad Dhaba
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: 1, py: 2 }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <img src={Contact_IMG} alt="" />
                  <Typography
                    sx={{ fontFamily: "Urbanist-Regular", fontSize: "18px" }}
                  >
                    +92 335 385 4999
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <img src={Email_IMG} alt="" />
                  <Typography
                    sx={{ fontFamily: "Urbanist-Regular", fontSize: "18px" }}
                  >
                    amjadali@gmail.com
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <img src={Website_IMG} alt="" />
                  <Typography
                    sx={{ fontFamily: "Urbanist-Regular", fontSize: "18px" }}
                  >
                    https://anda.com
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Paper
              sx={{
                px: 3,
                py: 2,
                boxShadow: "0px 3px 6px #0000000F",
                height: "100%",
                borderRadius: "16px",
                display:"flex",
                flexDirection:"column",
                gap:2
              }}
              elevation={0}
            >
              <Grid container>
                <Grid item xs={12}>
                  <SimpleInput
                    label="For Catering"
                    fullWidth
                    simple
                    multiline
                    minRows={3}
                    placeholder={"Food catering"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6}>
                  <SimpleInput
                    label="Website"
                    fullWidth
                    simple
                    placeholder={"Food catering"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <SimpleInput
                    label="Facebook Link"
                    fullWidth
                    simple
                    placeholder={"Food catering"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6}>
                  <SimpleInput
                    label="Instagram Link"
                    fullWidth
                    simple
                    placeholder={"Food catering"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <SimpleInput
                    label="Twitter Link"
                    fullWidth
                    simple
                    placeholder={"Food catering"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6}>
                  <SimpleInput
                    label="Website"
                    fullWidth
                    simple
                    placeholder={"Food catering"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <SimpleInput
                    label="Facebook Link"
                    fullWidth
                    simple
                    placeholder={"Food catering"}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ my: 1, mb: 4 }}>
        <Paper
          sx={{
            p: 2,
            boxShadow: "0px 3px 6px #0000000F",
            borderRadius: "24px",
          }}
          elevation={0}
        >
          <Typography sx={{ fontFamily: "Urbanist-Bold", fontSize: "32px" }}>
            Location
          </Typography>
          <GoogleMap height={"350px"} />
        </Paper>
      </Box>
    </Box>
  );
};

export default ViewProfile;
