import React, { useState } from "react";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const RequireAuth = ({ children }) => {
  let location = useLocation();
  const user = useSelector(state => state.userReducer.user);
  const [auth] = useState(null);
  const navigate = useNavigate();
  console.log(user, "USER_LOGIN");
  if (!user) {
    return <Navigate to="/sign-in" replace />;
  }
  return children;
};

export default RequireAuth;
