import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Grid, InputLabel, Typography } from "@mui/material";
import SimpleSelect from "../Inputs/SimpleSelect";
import { Theme } from "../../Theme";
import "../../App.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function FilterMenu({
  handleChange = () => {},
  filterType = "",
  filterStatus = "",
  filterFranchise = "",
}) {
  const partnerFranchises = useSelector(
    (state) => state.partnerFranchisesReducer.partnerFranchises
  );
  const [selectedFranchise, setSelectedFranchise] = useState(filterFranchise);
  const [selectedStatus, setSelectedStatus] = useState(filterStatus);
  const [selectedType, setSelectedType] = useState(filterType);

  const handleReset = () => {
    setSelectedStatus("");
    setSelectedFranchise("");
    setSelectedType("");
  };

  useEffect(() => {
    handleChange(selectedFranchise, "franchise");
  }, [selectedFranchise]);

  useEffect(() => {
    handleChange(selectedStatus, "status");
  }, [selectedStatus]);

  useEffect(() => {
    handleChange(selectedType, "type");
  }, [selectedType]);

  return (
    <Box sx={{ px: 1, py: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontFamily: "Urbanist-Bold" }}>Filter</Typography>
        <Button variant="text" onClick={handleReset}>
          RESET
        </Button>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputLabel>Status</InputLabel>
            <SimpleSelect
              size="small"
              placeholder={"All"}
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e?.[0])}
              options={[
                "Claimed All",
                "Awaiting",
                "Dying Up",
                "Active",
                "Draft",
              ]?.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Type</InputLabel>
            <SimpleSelect
              size="small"
              placeholder={"All"}
              value={selectedType}
              onChange={(e) => setSelectedType(e?.[0])}
              options={["Finch", "GoldFinch"]?.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputLabel>Franchise</InputLabel>
            <SimpleSelect
              size="small"
              placeholder={"All"}
              value={selectedFranchise}
              onChange={(e) => setSelectedFranchise(e?.[0])}
              options={partnerFranchises?.map((v) => {
                return {
                  label: v.name,
                  value: v.franchiseId,
                };
              })}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
