import React, { useEffect, useState } from "react";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import "../../App.css";
import { Theme } from "../../Theme";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useAuth from "../../ApiHooks/useAuth";
import SimpleInput from "../../Components/Inputs/SimpleInput";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { forgotPassword, loading, errors } = useAuth();
  const user = useSelector((state) => state.userReducer.user);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (user && user !== null) {
      navigate("/");
    }
  }, []);

  const handleForgotPassword = () => {
    forgotPassword(email);
    console.log("password rest");
  };

  return (
    <Box sx={{ px: 4, py: 2 }}>
      {/* <Box> */}
      <Link to={"/"}>
        <Paper
          sx={{
            width: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "10px 10px 30px #00000029",
            borderRadius: "15px",
            py: 0.5,
          }}
          // onClick={() => setSwapform(true)}
        >
          <IconButton>
            <ArrowBackIosNewIcon sx={{ color: Theme.color.basic }} />
          </IconButton>
        </Paper>
      </Link>
      {/* </Box> */}
      <Grid
        container
        direction={"column"}
        className=" h-[90vh] "
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Grid item x={12}>
          <Typography
            sx={{
              fontFamily: "Urbanist-Bold",
              mb: "40px",
              fontSize: "30px",
              textAlign: "center",
            }}
          >
            Forgot password
          </Typography>
        </Grid>
        <Grid item x={12}>
          <SimpleInput
            label="Email"
            fullWidth
            simple
            placeholder={"Email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={Boolean(errors?.email)}
            helperText={errors?.email}
          />
        </Grid>
        <Grid item x={12}>
          <PrimaryButton
            text={"SEND OTP"}
            onClick={handleForgotPassword}
            // fullWidth
            sx={{
              color: Theme.color.basic,
              backgroundColor: Theme.bg.yellow,
              borderRadius: "8px",
              my: 6,
              width: "400px",
              fontSize: "18px",
              "&.MuiButton-root": {
                "&:hover": {
                  backgroundColor: Theme.bg.yellow,
                  borderColor: Theme.bg.yellow,
                  boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                },
              },
              boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
              fontFamily: "Urbanist-Bold",
            }}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
