import { FormControl, FormHelperText } from "@mui/material";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";

const DateTimePickerComp = ({
  label,
  value,
  onChange = () => {},
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl
        fullWidth
        sx={{
          width: "100%",
          bgcolor: "#F8FAFB",
          borderRadius: "6px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "6px",
            borderColor: props.error ? "red" : null,
          },
        }}
        {...props}
      >
        <DateTimePicker
          ampm={false}
          label={label}
          disablePast
          value={value}
          onChange={onChange}
          sx={{
            width: "100%",
            bgcolor: "#F8FAFB",
          }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          {...props}
        />
        {props?.helperText && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </LocalizationProvider>
  );
};

export default DateTimePickerComp;
