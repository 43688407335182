import {
  ADD_USER,
  ADD_IS_BANNED,
  ADD_PARTNER_DATA,
  ADD_PRIVACY,
} from "./../types";

const initialState = {
  user: null,
  isBanned: null,
  partner: null,
  privacy: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER:
      return { ...state, user: action.payload };
    case ADD_IS_BANNED:
      return { ...state, isBanned: action.payload };
    case ADD_PARTNER_DATA:
      return { ...state, partner: action.payload };
    case ADD_PRIVACY:
      return { ...state, privacy: action.payload };
    default:
      return state;
  }
};
