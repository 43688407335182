import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import "../../App.css";
import { Theme } from "../../Theme";
import { Box, IconButton, Paper, TextField, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, useNavigate } from "react-router-dom";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import BasicModal from "../../Components/Modal";
import ChangePassComp from "../../Components/Modal/ChangePassComp";
import { useSelector } from "react-redux";
// import Modals from "../../Components/Modals/Modals";

const ResetPassword = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate()
  const user = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    let isAuth = JSON.parse(localStorage.getItem("user"));
    if (user && user !== null) {
      navigate("/");
    }
  }, []);
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleResetPassword = () => {
    setOpen(true);
  };

  const [otp, setOtp] = useState("");

  const inputStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    boxShadow: "10px 20px 30px #00000029",
  };

  return (
    <Box sx={{ px: 4, py: 2 }}>
      {/* <Box> */}
      <Link to={"/"}>
        <Paper
          sx={{
            width: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "10px 10px 30px #00000029",
            borderRadius: "15px",
            py: 0.5,
          }}
          // onClick={() => setSwapform(true)}
        >
          <IconButton>
            <ArrowBackIosNewIcon sx={{ color: Theme.color.basic }} />
          </IconButton>
        </Paper>
      </Link>
      {/* </Box> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
          gap: 3,
          // px: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            // width: "600px",
            gap: 3,
            px: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "25px", sm: "30px" },
              textAlign: { xs: "left", sm: "left", md: "center" },
              fontFamily: "Urbanist-Bold",
              mb: 4,
              width: { xs: "280px", sm: "450px", md: "550px" },
            }}
          >
            Create New Password
          </Typography>
          <Box
            sx={{
              width: { xs: "280px", sm: "450px", md: "550px" },
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <TextField
              variant="outlined"
              placeholder="New Password"
              fullWidth
              sx={{
                bgcolor: "#F8FAFB",
                borderRadius: "6px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "6px",
                  borderColor: "#DDE6EE",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "6px",
                  borderColor: "#DDE6EE",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "6px",
                  borderColor: "#DDE6EE",
                },
              }}
            />
            <TextField
              variant="outlined"
              placeholder="Confirm Password"
              fullWidth
              sx={{
                bgcolor: "#F8FAFB",
                borderRadius: "6px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "6px",
                  borderColor: "#DDE6EE",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "6px",
                  borderColor: "#DDE6EE",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "6px",
                  borderColor: "#DDE6EE",
                },
              }}
            />
          </Box>
          <Box sx={{ width: { xs: "200px", sm: "350px", md: "400px" } }}>
            <PrimaryButton
              text={"Update Password"}
              onClick={handleResetPassword}
              fullWidth
              sx={{
                // minWidth: "200px",
                color: Theme.color.basic,
                backgroundColor: Theme.bg.yellow,
                borderRadius: "8px",
                py: { xs: 1, sm: 2 },
                my: 4,
                // width: "400px",
                fontSize: "18px",
                "&.MuiButton-root": {
                  "&:hover": {
                    backgroundColor: Theme.bg.yellow,
                    borderColor: Theme.bg.yellow,
                    boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                  },
                },
                boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                fontFamily: "Poppins-Medium",
              }}
            />
          </Box>
        </Box>
      </Box>
      <BasicModal
        open={open}
        handleClose={() => setOpen(false)}
        data={<ChangePassComp />}
      />
    </Box>
  );
};

export default ResetPassword;
