import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import Mcdonald_IMG from "../../Assets/Image/Mcdonald.png";
import DoneIcon from "@mui/icons-material/Done";
import { RiDeleteBinLine } from "react-icons/ri";
import { Theme } from "../../Theme";
const ImageUploader = ({
  file,
  fileInputRef,
  handleFileInputClick,
  handleFileChange,
}) => {
  return (
    <Box
      sx={{
        border: "2px dashed #DDE6EE",
        borderRadius: "5px",
        display: "flex",
        px: 2,
        py: 1,
        gap: 2,
        cursor: "pointer",
      }}
    >
      {Boolean(file) && (
        <Box onClick={handleFileInputClick}>
          <img
            src={file?.size ? URL.createObjectURL(file) : file}
            width={150}
            height={150}
            alt=""
          />
        </Box>
      )}
      <Box sx={{ mt: 2 }} onClick={handleFileInputClick}>
        <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
          Upload Logo
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#949494", my: 1 }}>
          (Preferred aspect ration is 145 X 145 and size should be lower than
          2MB)
        </Typography>

        {Boolean(file?.size) && (
          <Box
            sx={{
              backgroundColor: Theme.bg.bg,
              borderRadius: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              px: 2,
            }}
          >
            <>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <DoneIcon />
                {Boolean(file?.size) && (
                  <>
                    <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                      {file?.name}
                    </Typography>
                    <Typography
                      sx={{
                        backgroundColor: "#E1E7EB",
                        fontSize: "14px",
                        borderRadius: "13px",
                        p: 0.5,
                        fontWeight: 600,
                      }}
                    >
                      {(file?.size / 1024 / 1024).toFixed(2)}MB
                    </Typography>
                  </>
                )}
              </Box>
              <IconButton
                aria-label="delete"
                onClick={() => handleFileChange(null)}
              >
                <RiDeleteBinLine style={{ color: Theme.color.basic }} />
              </IconButton>
            </>
          </Box>
        )}
      </Box>

      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </Box>
  );
};

export default ImageUploader;
