import { useMediaQuery, Drawer, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Header from "./Header";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    width: "60%",
    flexShrink: 0,
  },
  "& .MuiDrawer-paper": {
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  },
}));

const SideBar = ({ open, title, onClose, form }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  return (
    <StyledDrawer
      variant={isSmallScreen ? "temporary" : "persistent"}
      open={open}
      onClose={onClose}
      anchor="right"
      sx={{
        // overflowX: "hidden",
        "& .MuiPaper-root": { overflowY: "auto", overflowX: "none" },
      }}
    >
      <Header title={title} onClose={onClose} />
      <Box sx={{ backgroundColor: "#fff" }}>{form}</Box>
    </StyledDrawer>
  );
};

export default SideBar;
