import moment from "moment";

const dayjs = require("dayjs");

function createDateWithTime(timeString = "") {
  console.log(timeString, "timeString:");
  if (timeString) {
    var [hours, minutes] = timeString.split(":").map(Number);

    // Validate hours and minutes
    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      hours < 0 ||
      hours > 23 ||
      minutes < 0 ||
      minutes > 59
    ) {
      return null; // Invalid time string
    }

    // Use dayjs to create a date with time
    var dateWithTime = dayjs()
      .set("hour", hours)
      .set("minute", minutes - 1);
    return dateWithTime.isValid() ? moment(new Date(dateWithTime)) : null;
  } else {
    return null;
  }
}
export default createDateWithTime;
