import { Box, Typography } from "@mui/material";
import React from "react";
import Lottie from "../Lottie/Lottie";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";
import { RiDeleteBinLine } from "react-icons/ri";
import CancelSubs_IMG from "../../Assets/Image/CancelSubs.png";

const CancelSubsComp = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {/* <Lottie /> */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src={CancelSubs_IMG} alt="" />
      </Box>
      <Typography
        sx={{
          fontSize: "32px",
          color: Theme.color.darkRed,
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        Cancel Subscription
      </Typography>
      <Typography
        sx={{ fontSize: "20px", color: "#8F8F8F", textAlign: "center" }}
      >
        Are you sure you want to cancel this subscription?
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <PrimaryButton
          text={"Cancel"}
          elevation={3}
          size="large"
          //   fullWidth
          sx={{
            width: "100px",
            color: Theme.color.white,
            backgroundColor: "#636363",
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: "#636363",
                borderColor: "#636363",
              },
            },
          }}
        />
        <PrimaryButton
          text={"Ok"}
          elevation={3}
          size="large"
          // fullWidth
          sx={{
            width: "100px",
            color: Theme.color.white,
            backgroundColor: Theme.bg.redBtn,
            "&.MuiButton-root": {
              "&:hover": {
                backgroundColor: Theme.bg.redBtn,
                borderColor: Theme.bg.redBtn,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default CancelSubsComp;
