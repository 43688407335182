import React, { useEffect, useState } from "react";
import ToggleInputs from "../../Components/Inputs/ToggleInput";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import Underline from "../../Components/Buttons/Underline";
import Logo from "../../Assets/Image/LogoBig.png";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import { Box, Divider, Grid, Typography } from "@mui/material";
import BANNER_IMG from "../../Assets/Image/Banner1.png";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import {
  DASHBOARD_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
} from "../../Constants/Paths";
import { Theme } from "../../Theme";
import "../../App.css";
import useAuth from "../../ApiHooks/useAuth";
import { useSelector } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loading, errors, login } = useAuth();
  const user = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    let isAuth = JSON.parse(localStorage.getItem("user"));
    if (user && user !== null) {
      navigate("/");
    }
  }, []);

  const handleSignIn = () => {
    try {
      const reqObj = {
        email,
        password,
      };
      login(reqObj);
      // navigate(DASHBOARD_PATH, { replace: false });
    } catch (err) {
      console.log(err);
    }
  };

  console.log(errors);
  return (
    <Box>
      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            display: { xs: "none", lg: "block" },
          }}
        >
          <img
            src={BANNER_IMG}
            alt="Banner Image"
            style={{ width: "100%", height: "100vh" }}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ px: 2 }}>
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ margin: "5px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img src={Logo} className="w-[128px] max-w-full my-10" />
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: {xs:"flex-start",sm:"center"},
                    alignItems: {
                      xs: "flex-start",
                      sm: "flex-start",
                      md: "center",
                    },
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Urbanist-Bold",
                      fontSize: "32px",
                      mb: 5,
                      // lineHeight:1.5
                    }}
                  >
                    Sign in to your account
                  </Typography>
                  <Typography
                    sx={{
                      mb: 4,
                      textAlign: "left",
                      color: "#8A8A8A",
                    }}
                  >
                    Don't have an account?{""}
                    <Link
                      to={SIGN_UP_PATH}
                      style={{
                        color: "#1EA0FF",
                        fontFamily: "Urbanist-Medium",
                      }}
                    >
                      Sign Up
                    </Link>
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <SimpleInput
                  label="Email"
                  fullWidth
                  simple
                  placeholder={"Email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={Boolean(errors?.email)}
                  helperText={errors?.email}
                />
                <ToggleInputs
                  label="Password"
                  simple
                  placeholder={"Password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={Boolean(errors?.password)}
                  helperText={errors?.password}
                />

                <Link
                  to="/forgot-password"
                  style={{
                    fontFamily: "Urbanist-Bold",
                    my: 5,
                    color: "#1EA0FF",
                  }}
                >
                  Forgot Password?
                </Link>
                <PrimaryButton
                  text={"SIGN IN"}
                  onClick={handleSignIn}
                  loading={loading}
                  sx={{
                    color: Theme.color.basic,
                    backgroundColor: Theme.bg.yellow,
                    borderRadius: "8px",
                    "&.MuiButton-root": {
                      "&:hover": {
                        backgroundColor: Theme.bg.yellow,
                        borderColor: Theme.bg.yellow,
                        boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                      },
                    },
                    fontFamily: "Urbanist-Bold",
                    boxShadow: "10px 20px 30px rgba(255, 216, 49, 0.3)",
                  }}
                />
              </Box>
              <Divider sx={{ mt: 4 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
