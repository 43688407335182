import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

export default function SimpleSelect({
  value,
  onChange = () => {},
  label,
  options,
  fullWidth,
  size,
  placeholder,
  style,
  ...props
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl
        fullWidth={fullWidth}
        size={size}
        sx={{
          width: "100%",
          bgcolor: "#F8FAFB",
          borderRadius: "6px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "6px",
            borderColor: props.error ? "red" : null,
          },
        }}
        {...props}
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
          placeholder
          sx={style}
          {...props}
        >
          {options?.map((option, i) => {
            return (
              <MenuItem key={option?.value} value={option?.value}>
                {option?.label}
              </MenuItem>
            );
          })}
        </Select>
        {props?.helperText && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
