import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../Constants/Firebase";
import useFetch from "../Utils/useFetch";
import {
  FORGOT_PASSWORD_API,
  SIGNIN_API,
  SIGNUP_API,
  VERIFY_CODE_API,
  VERIFY_OTP_API,
} from "../Constants/APIs";
import { ADD_USER } from "../Redux/types";
import { useNavigate } from "react-router-dom";
import {
  DASHBOARD_PATH,
  SIGN_IN_PATH,
  VERIFY_OTP_PATH,
} from "../Constants/Paths";
import { toast } from "react-toastify";

const useAuth = () => {
  const { request } = useFetch();
  const user = useSelector((state) => state.userReducer.user);
  console.log(user, "user for login");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const login = async (data) => {
    try {
      const errorsObj = {};
      const subdomain = window.location.host.split(".")[0];

      if (!data.email) {
        errorsObj.email = "Please Enter a valid email.";
      }
      if (!data.password) {
        errorsObj.password = "Password is required.";
      }

      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }

      setErrors({});
      setLoading(true);
      const loginReq = await auth.signInWithEmailAndPassword(
        data.email,
        data.password
      );
      const userUid = loginReq?.user?.uid;
      const req = await request(SIGNIN_API, "POST", {
        uid: userUid,
        domain: subdomain,
      });

      dispatch({
        type: ADD_USER,
        payload: req.data.data,
      });
      navigate(DASHBOARD_PATH);
      toast.success("User successfully Login");
    } catch (e) {
      console.log(e);
      if (e.code === "auth/wrong-password") {
        toast.error(
          "The password is invalid or the user does not have a password."
        );
      } else if (e.code === "auth/user-not-found") {
        toast.error(
          "There is no user record corresponding to this identifier. The user may have been deleted."
        );
      } else {
        toast.error(
          e?.error?.message || "Something went wrong, please try again"
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const signup = async (data) => {
    try {
      setLoading(true);
      const loginReq = await auth.createUserWithEmailAndPassword(
        data.email,
        data.password
      );
      const userUid = loginReq?.user?.uid;
      const req = await request(SIGNUP_API, "POST", {
        ...data,
        uid: userUid,
        balance: 3,
        isProfileCompleted: false,
        role: "merchant",
        status: "Waiting For Approval",
        logoUrl: null,
      });

      console.log(req, "SIGNED");
      navigate(SIGN_IN_PATH);
      toast.success("Account Created, Pending For Approval");
    } catch (e) {
      console.log(e.message, "e.message");
      if (e.code === "auth/email-already-in-use") {
        toast.error("The email address is already in use by another account.");
      }
    } finally {
      setLoading(false);
    }
  };

  const forgotPassword = async (email) => {
    try {
      let errorsObj = {};
      if (!email) {
        errorsObj.email = "Please Enter a valid email.";
      }
      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }

      setErrors({});
      setLoading(true);
      const req = await request(FORGOT_PASSWORD_API, "POST", {
        email,
      });
      navigate(VERIFY_OTP_PATH + "?email=" + email);
      console.log(req, FORGOT_PASSWORD_API);
      toast.success("OTP sent to your email address");
    } catch (err) {
      toast.error("Something went wrong, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = async (body, onCallback = () => null) => {
    try {
      setLoading(true);
      const req = await request(VERIFY_OTP_API, "POST", body);
      console.log(req, VERIFY_OTP_API);
      onCallback();
    } catch (err) {
      console.log(err);
      toast.error(
        err?.error?.message || "Something went wrong, please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (body, callback = () => null) => {
    try {
      let errorsObj = {};
      if (!body?.password) {
        errorsObj.password = "Please enter a valid password";
      }
      if (body?.confirmPassword !== body?.password) {
        errorsObj.confirmPassword = "Passwords do not match";
      }
      if (Object.keys(errorsObj).length) {
        setErrors(errorsObj);
        return;
      }

      setErrors({});
      setLoading(true);
      const req = await request(VERIFY_CODE_API, "POST", body);
      callback();
      console.log(req, VERIFY_CODE_API);
    } catch (err) {
      console.log(err);
      toast.error(
        err?.error?.message || "Something went wrong, please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    login,
    loading,
    errors,
    signup,
    forgotPassword,
    verifyOtp,
    resetPassword,
  };
};

export default useAuth;
