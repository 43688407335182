import { ADD_PARTNER_FRANCHISES } from "../types";

const initialState = {
  partnerFranchises: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PARTNER_FRANCHISES:
      return { ...state, partnerFranchises: action.payload };
    default:
      return state;
  }
};
