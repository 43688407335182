import { Slider } from "@mui/material";
import { withStyles } from "@mui/styles";
const SliderInput = withStyles({
  root: {
    color: "#52af77",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#292929",
    border: "2px solid currentColor",
    // marginTop: -8,
    // marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,

    backgroundColor: "#FFD831",
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: "#FFD831",
  },
})(Slider);
export default SliderInput;
