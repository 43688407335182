export const IS_DEV = false;
let DEV_API_URL = "http://127.0.0.1:5001/goldfinch-c2012/us-central1/";
export const API_URL = IS_DEV
  ? DEV_API_URL
  : "https://us-central1-goldfinch-c2012.cloudfunctions.net/";

export const GOOGLE_KEY = "AIzaSyBMOLWUkXkZIuz3iNZ6rMkznD4zqoHBW60";

export const SIGNUP_API = API_URL + "signup"; // POST METHOD
export const FORGOT_PASSWORD_API = API_URL + "forgotPassword"; // POST METHOD
export const VERIFY_OTP_API = API_URL + "verifyOtp"; // POST METHOD
export const VERIFY_CODE_API = API_URL + "verifyCode"; // POST METHOD
export const SIGNIN_API = API_URL + "signin"; // POST METHOD
export const EDIT_PROFILE_API = API_URL + "editProfile"; // POST METHOD
export const GET_ALL_PARTNER_FINCHES_API = API_URL + "getPartnerFinches"; // GET METHOD
export const CREATE_GOLDFINCH_API = API_URL + "createGoldFinch"; // GET METHOD
export const EDIT_GOLDFINCH_API = API_URL + "editGoldFinch"; // GET METHOD
export const CREATE_FINCH_API = API_URL + "createFinch"; // GET METHOD
export const EDIT_FINCH_API = API_URL + "editFinch"; // GET METHOD
export const DELETE_FINCH_API = API_URL + "deletePartnerFinch"; // GET METHOD
export const CHANGE_FINCH_STATUS_API = API_URL + "changeFinchStatus"; // GET METHOD

export const GET_PARTNER_DATA_API = API_URL + "getPartnerData"; // GET METHOD
export const GET_ALL_PARTNER_FRANCHISES_API = API_URL + "getPartnerFranchises"; // GET METHOD

export const EDIT_FRANCHISE_API = API_URL + "editFranchise"; // EDIT METHOD
export const ADD_FRANCHISE_API = API_URL + "addFranchise"; // EDIT METHOD
export const EDIT_FRANCHISE_STATUS_API = API_URL + "editFranchiseStatus"; // EDIT METHOD
export const GET_PARTNER_ANALYTICS_API = API_URL + "getPartnerAnalytics"; // EDIT METHOD
export const GET_CATEGORIES_API = API_URL + "getCategories"; // GET METHOD

export const GET_PAYMENT_METHOD_API = API_URL + "getPaymentMethods"; // GET METHOD
export const GET_PACKAGES_API = API_URL + "getPackages"; // GET METHOD
export const CREATE_CARD_API = API_URL + "createCard"; // POST METHOD
export const CREATE_SUBSCRIPTION = API_URL + "createSubscription"; // POST METHOD
export const GET_CUSTOMER_SUBSCRIPTION = API_URL + "getCustomerSubscription";
export const REMOVE_PAYMENT_METHOD_API = API_URL + "removePaymentMethod";
export const CHANGE_DEFAULT_PAYMENT_METHOD_API =
  API_URL + "changeDefaultPaymentMethod";
export const TOGGLE_SUBSCRIPTION_API = API_URL + "toggleSubscription";
export const CREATE_DRAFT_FINCH_API = API_URL + "createDraftFinch";
export const EDIT_DRAFT_FINCH_API = API_URL + "editDraftFinch";
export const ACTIVATE_DRAFT_API = API_URL + "activateDraft";
export const GET_PACKAGE_DETAILS_API = API_URL + "getPackageDetails";
export const GET_PRIVACY_POLICY_API = API_URL + "getPrivacyPolicy";
