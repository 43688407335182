import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import { Theme } from "../../Theme";
import usePayment from "../../ApiHooks/usePayment";

const CancelFranchiseCard = ({ data, onCallBack }) => {
  const { toggleSubscription, loading } = usePayment();

  return (
    <PrimaryButton
      text={data?.cancelAtPeriodEnd ? "Reactivate" : "Cancel"}
      onClick={() =>
        toggleSubscription(
          data?.subscriptionId,
          data?.packageType,
          data?.cancelAtPeriodEnd,
          onCallBack
        )
      }
      loading={loading === data?.subscriptionId}
      disabled={loading === data?.subscriptionId || data?.isFree}
      elevation={3}
      sx={{
        // width: "100px",
        color: Theme.color.white,
        backgroundColor: data?.cancelAtPeriodEnd
          ? Theme.bg.greenBtn
          : Theme.bg.redBtn,
        "&.MuiButton-root": {
          "&:hover": {
            backgroundColor: data?.cancelAtPeriodEnd
              ? Theme.bg.greenBtn
              : Theme.bg.redBtn,
            borderColor: Theme.bg.redBtn,
          },
        },
        borderRadius: "8px",
        px: 4,
        py: 0.5,
        fontFamily: "Urbanist-Bold",
      }}
      fullWidth
    />
  );
};

export default CancelFranchiseCard;
